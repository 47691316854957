import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import { IconButton, Box, TextField, InputAdornment, Stack, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, Typography } from '@material-ui/core';
import ClientJobListModal from './ClientJobListModal';
import { FetchClientJobActivities2, getClientUDFieldsbyClientId } from '../../../api/ClientApi';
import { Close, Close as CloseIcon } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    height: "60%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    overflow: 'scroll'
};



const AddClientJobModal = ({ show, handleClose, onAddJob, selectedJobDetails, onJobEdit, deleteImageFromBackend }) => {

    let history = useHistory();
    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? 'black';
    let clientId = localStorage.getItem("clientId");
    let clientEmployeeId = localStorage.getItem("clientEmployeeId");

    const [selectedJobId, setSelectedJobId] = useState(0)
    const [jobName, setJobName] = useState("");

    const [jobActivityList, setJobActivityList] = useState([]);
    const [selectedActivityId, setSelectedActivityId] = useState(0)
    const [selectedActivityName, setSelectedActivityName] = useState("");

    const [showJobListModal, setShowJobListModal] = useState(false);

    const [selectedTime, setSelectedTime] = useState("");
    const [mileage, setMileage] = useState("");
    const [mileageUnit, setMileageUnit] = useState("km");
    const [notes, setNotes] = useState("");
    const [image, setImage] = useState([]);


    const [clientUDFields, setClientUDFields] = useState([]);


    const [editingJobId, setEditingJobId] = useState(0);
    const [timesheetJobEditId, setTimesheetJobEditId] = useState(0);
    const [timesheetEmpEditId, setTimesheetEmpEditId] = useState(0);
    const [timesheetHourEditId, setTimesheetHourEditId] = useState(0);
    const [timesheetActEditId, setTimesheetActEditId] = useState(0);

    const [deleteImageIds, setDeleteImageIds] = useState([]);

    const labelArray = [
        {
            key: 1,
            value: 'km',
        },
        {
            key: 2,
            value: 'mi',
        },
    ]

    useEffect(() => {
        if (selectedJobDetails) {
            setJobName(selectedJobDetails?.timesheetJob?.JobName);
            setSelectedJobId(selectedJobDetails?.timesheetJob?.ClientJobId);
            setSelectedActivityName(selectedJobDetails?.timesheetActivity?.JobActivityName);
            setSelectedActivityId(selectedJobDetails?.timesheetActivity?.ClientActivityId);
            setSelectedTime(selectedJobDetails?.timesheetHours?.Hours);
            setMileage(selectedJobDetails?.timesheetEmp?.Mileage)
            setMileageUnit(selectedJobDetails?.timesheetEmp?.MileageUnit === "" ? "km" : selectedJobDetails?.timesheetEmp?.MileageUnit);
            setNotes(selectedJobDetails?.timesheetJob?.Notes);
            setClientUDFields(selectedJobDetails?.timesheetJob?.UDFieldsList);
            setImage(selectedJobDetails?.timesheetJob?.PictureList);
            setEditingJobId(selectedJobDetails?.id);
            setTimesheetJobEditId(selectedJobDetails?.timesheetJob?.Id);
            setTimesheetEmpEditId(selectedJobDetails?.timesheetEmp?.Id);
            setTimesheetHourEditId(selectedJobDetails?.timesheetHours?.Id);
            setTimesheetActEditId(selectedJobDetails?.timesheetActivity?.Id);
        }
    }, [selectedJobDetails])

    const fetchClientJobActivity = async (clientJobId) => {
        try {
            const obj = {
                PageNr: 1,
                // NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: Number(localStorage?.userId),
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [
                    {
                        clientJobId
                    }
                ]
            }
            const res = await FetchClientJobActivities2(obj);
            let resData = res?.data ?? [];
            const data = []
            resData.forEach((item) => {
                const activityItem = {
                    value: `${item.labourActivityCode} - ${item.labourActivityName}`,
                    label: `${item.labourActivityCode} - ${item.labourActivityName}`,
                    id: item.id
                }
                data.push(activityItem)
            })
            setJobActivityList(data);
        } catch (err) {
            setJobActivityList([]);
            console.log("ERROR WHILE FETCHING JOB ACTIVITY", err)
        }
    }

    const fetchClientUDFields = async (clientJobId) => {
        try {
            const obj = {
                clientId: Number(clientId),
                appId: localStorage?.selectedAppId ?? 0,
                entityRecordId: clientJobId,
            };
            const res = await getClientUDFieldsbyClientId(obj);
            let data = res ?? [];
            if (data?.length > 0) {
                setClientUDFields(data.map((ele, index) => ({ id: ele.id, label: ele.fieldLabel, value: ele.fieldDefaultValue === "false" ? false : true })));
            }
        } catch (err) {
            console.log(" ERROR WHILE FETCHING CLIENT UD FIELDS! ", err);
        }
    };

    const [lastKey, setLastKey] = useState('');

    const handleTimeChange = (e) => {
        const validText = e.target.value?.replace(/[^0-9.]/g, '')?.trim();
        const parts = validText.split('.');
        if (parts.length > 2) {
            return;
        }
        let formattedTime = validText;
        if (formattedTime.length === 2 && parts.length === 1 && lastKey !== "Backspace") {
            formattedTime = formattedTime + "."
        }
        if (parts.length === 2 && parts[1].length > 2) {
            formattedTime = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        setSelectedTime(formattedTime);
    };

    const handleBlurHour = () => {
        let formattedValue = selectedTime;

        // Check for empty input
        if (!formattedValue || formattedValue === '.') {
            formattedValue = '00.00'; // Default if empty or only a decimal point
        } else {
            const parts = formattedValue.split('.');

            // If starts with a decimal point
            if (formattedValue.startsWith('.')) {
                formattedValue = `00${formattedValue}`; // e.g., ".12" becomes "00.12"
            } else if (parts.length === 1) {
                // If only whole number digits
                if (parts[0].length === 1) {
                    formattedValue = `0${parts[0]}.00`; // e.g., "2" becomes "02.00"
                } else {
                    formattedValue = `${parts[0].padStart(2, '0')}.00`; // Ensure at least two digits for whole number
                }
            } else if (parts.length === 2) {
                // If there's a decimal part, ensure two decimal places
                formattedValue = `${parts[0].padStart(2, '0')}.${parts[1].padEnd(2, '0')}`;
            }
        }

        setSelectedTime(formattedValue);
    };


    const handleKeyDown = (e) => {
        setLastKey(e.key);
    }

    const handleMileageChange = (e) => {
        let value = e?.target?.value;
        const sanitizedText = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
        let formattedMileage = '';
        if (sanitizedText.includes('.')) {
            const [integer, decimal] = sanitizedText.split('.');
            formattedMileage = `${integer.slice(0, 3)}.${decimal.slice(0, 1)}`;
        } else if (sanitizedText.length <= 3) {
            formattedMileage = sanitizedText;
        } else {
            formattedMileage = `${sanitizedText.slice(0, 3)}.${sanitizedText.slice(3, 4)}`;
        }
        setMileage(formattedMileage);
    };

    const handleBlurMileage = () => {
        if (mileage === '') return;
        let [integer, decimal] = (mileage + '.').split('.');
        integer = integer.padStart(1, '0').slice(0, 3);
        decimal = decimal.padEnd(1, '0').slice(0, 1);
        setMileage(`${integer}.${decimal}`);
    };

    const isAddJobBtnDisable = () => {
        return jobName?.trim() === "" || selectedTime?.trim() == "" || (jobActivityList.length > 0 && selectedActivityName?.trim() === "");
    }

    const clearImage = (img, index) => {
        let array = [...image];
        array.splice(index, 1);
        setImage(array);
        if (img?.id) {
            setDeleteImageIds((preValue) => [...preValue, img?.id])
        }
    };

    const onImagechange = (e) => {
        const files = e.target.files;
        let array = [];
        for (let i = 0; i < files.length; i++) {
            array.push(files[i]);
        }
        setImage((preValue) => ([...preValue, ...array]));

    };

    const checkObject = (obj) => {
        try {
            if (obj) {
                if (typeof obj === "object") {
                    if (obj?.fileUrl) {
                        return obj?.fileUrl;
                    }
                    return URL.createObjectURL(obj);
                } else {
                    return obj?.fileUrl;
                }
            } else {
                return "";
            }
        } catch (error) {
            return "";
        }
    };

    const handleCheckboxChange = (index) => {
        let cloneClientUDFields = [...clientUDFields];
        cloneClientUDFields[index].value = !cloneClientUDFields[index].value;
        setClientUDFields(cloneClientUDFields);
    }


    const onAddJobClick = () => {
        let timesheetJob = {
            Id: timesheetJobEditId ?? 0,
            ClientJobId: selectedJobId,
            JobName: jobName,
            Notes: notes,
            UDFields: clientUDFields,
            UDFieldsList: clientUDFields,
            PictureList: image,
            Deleted: false,
        };
        let timesheetEmp = {
            Id: timesheetEmpEditId ?? 0,
            ClientJobId: selectedJobId,
            ClientEmployeeId: clientEmployeeId,
            Mileage: mileage,
            MileageUnit: mileageUnit,
            UDFields: "",
            Deleted: false,

        };
        let timesheetHours = {
            Id: timesheetHourEditId ?? 0,
            ClientJobId: selectedJobId,
            ClientEmployeeId: clientEmployeeId,
            ClientJobActivityId: selectedActivityId,
            Hours: selectedTime,
            Deleted: false,
        };
        let timesheetActivity = {
            Id: timesheetActEditId ?? 0,
            ClientJobId: selectedJobId,
            ClientActivityId: selectedActivityId,
            JobActivityName: selectedActivityName,
            Qty: 0,
            QtyUnit: "",
            Notes: "",
            UDFields: "",
            Deleted: false,
        };
        let timesheetObj = {
            id: editingJobId != 0 ? editingJobId : Math.random().toString(),
            timesheetJob,
            timesheetEmp,
            timesheetHours,
            timesheetActivity
        };
        if (deleteImageIds?.length > 0) {
            deleteImageFromBackend(deleteImageIds);
        }
        if (selectedJobDetails) {
            return onJobEdit(timesheetObj)
        } else {
            return onAddJob(timesheetObj)
        }
    }



    if (!show) {
        return null;
    }

    return (
        <>
            {
                !showJobListModal ?
                    (
                        <Modal
                            open={show}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Stack direction={'row'}>
                                    <Stack
                                        direction={'row'}
                                        onClick={() => {
                                            setShowJobListModal(true);
                                        }}
                                    >
                                        <IconButton>
                                            <SearchIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                        <Typography>
                                            {jobName !== '' ? jobName : `Search for a Job`}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                {
                                    jobActivityList?.length > 0 &&
                                    (
                                        <Stack>
                                            <Typography>
                                                Activity
                                            </Typography>
                                            <Select
                                                label="Select Activity"
                                                id="client_job_activity"
                                                value={selectedActivityId}
                                                onChange={(e) => {
                                                    const selectedItem = jobActivityList.find(item => item.id === e.target.value);
                                                    setSelectedActivityId(e.target.value ?? 0);
                                                    setSelectedActivityName(selectedItem?.label ?? "")
                                                }}
                                            >
                                                {
                                                    jobActivityList?.map((item, index) => (
                                                        <MenuItem value={item?.id} key={index}>{item?.label}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Stack>
                                    )
                                }

                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack>
                                        <TextField
                                            placeholder={`0.00\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`}
                                            onChange={handleTimeChange}
                                            value={selectedTime}
                                            onBlur={handleBlurHour}
                                            label="Hours"
                                            inputProps={{ maxLength: 5 }}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Stack>
                                    <Stack>
                                        <TextField
                                            placeholder='0.0 (optional)'
                                            onChange={handleMileageChange}
                                            onBlur={handleBlurMileage}
                                            value={mileage}
                                            inputProps={{ maxLength: 5 }}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Select
                                            value={mileageUnit}
                                            onChange={(e) => {
                                                setMileageUnit(e?.target?.value)
                                            }}
                                        >
                                            {
                                                labelArray?.map((item, index) => (
                                                    <MenuItem value={item?.value} key={index}>{item?.value}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </Stack>
                                </Stack>

                                {
                                    clientUDFields?.length > 0 ?
                                        (
                                            <Stack>
                                                {
                                                    clientUDFields?.map((item, index) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={item?.value}
                                                                    onChange={() => { handleCheckboxChange(index) }}
                                                                    name={item?.label}
                                                                />
                                                            }
                                                            label={item?.label}
                                                            key={index}
                                                        />
                                                    ))
                                                }

                                            </Stack>
                                        )
                                        :
                                        (
                                            <>

                                            </>
                                        )
                                }


                                <Stack>
                                    <Typography>Notes</Typography>
                                    <TextField
                                        fullWidth
                                        minRows={4}
                                        multiline
                                        label="Notes"
                                        placeholder='Enter additional details (optional)'
                                        type="text"
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                </Stack>

                                <Stack direction={'row'}>
                                    <div
                                        style={{ marginTop: "13px", textAlign: "center" }}
                                    >
                                        <input
                                            type="file"
                                            multiple={true}
                                            style={{ display: "none" }}
                                            id="add-job-image"
                                            onChange={(e) => onImagechange(e)}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                            }}
                                        >
                                            <div
                                                onClick={() => {
                                                    document.getElementById("add-job-image").click();
                                                }}
                                                style={{
                                                    height: "100px",
                                                    width: "100px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px dashed #ccc",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                }}
                                            >
                                                <AddIcon />
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    overflow: "auto",
                                                    maxWidth: "450px",
                                                }}
                                            >
                                                {image &&
                                                    image.map((img, index) => (
                                                        <div>
                                                            <img src={checkObject(img)} />
                                                            <IconButton
                                                                style={{
                                                                    top: "-110px",
                                                                    right: "-80px",
                                                                    backgroundColor: "white",
                                                                    color: "red",
                                                                    height: "15px",
                                                                    width: "15px",
                                                                    display: "flex",
                                                                    padding: "10px",
                                                                    justifyContent: "center",
                                                                    alignitems: "center",
                                                                    zIndex: 1,
                                                                }}
                                                                onClick={() => clearImage(img, index)}
                                                                size="small"
                                                            >
                                                                <CloseIcon
                                                                    fontSize="small"
                                                                    style={{ fontSize: 16 }}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </Stack>

                                <Stack>
                                    <Button disabled={isAddJobBtnDisable()} onClick={() => {
                                        onAddJobClick()
                                    }}>
                                        {selectedJobDetails ? "Update Job" : "Add Job"}
                                    </Button>
                                </Stack>
                            </Box>
                        </Modal>
                    )
                    :
                    (
                        <>
                            <ClientJobListModal
                                show={showJobListModal}
                                handleClose={() => { setShowJobListModal(false) }}
                                onJobSelect={(item) => {
                                    setSelectedJobId(item?.id ?? 0);
                                    setJobName(item?.value ?? "");
                                    setShowJobListModal(false);
                                    fetchClientJobActivity(item?.id);
                                    fetchClientUDFields(item?.id);
                                }}
                                selectedJobId={selectedJobId}
                            />
                        </>
                    )
            }

        </>
    )
}
export default AddClientJobModal