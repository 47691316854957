import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getPostComments, postSettings } from "../../api/PostsApi";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  PushPin as PushPinIcon,
  MoreHoriz as MoreHorizIcon,
  BusinessCenter as BusinessCenterIcon,
  Room as RoomIcon,
  Build as BuildIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { Button, Divider, Menu, MenuItem } from "@mui/material";

import BarChartIcon from "@mui/icons-material/BarChart";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import MessageIcon from '@mui/icons-material/Message';
import CommentIcon from "@mui/icons-material/Comment";

import { Carousel } from "react-responsive-carousel";
import PictureViewer from "../PictureViewer";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import {
  BIGrey,
  BIRed,
  BILightYellow,
  BIWhite,
  BIBlue,
} from "../../assets/buildidColors";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PromptModal from "../PromptModal";
import { useEffect } from "react";
import moment from "moment";

import AlertModal from "../AlertModal";
import { InView, useInView } from "react-intersection-observer";
import { getPosts } from "../../api/PostsApi";
import PostCommentModal from "./PostCommentModal";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { fetchSites } from "../../api/SitesApi";

/*
Component to render a Post card, with comments section and comment input
Props (*required):
	*post	- JSON object from API for a single post
	pinned	- boolean, sets yellow background and pin icon

Usage:
	<Post post={}/> 
*/
const Post = (props) => {
  // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  var isAndroid = /Android/i.test(navigator.userAgent);
  var isIphone = /iPhone/i.test(navigator.userAgent);

  // "..." menu state - if anchor exists, display menu
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);

  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);

  const [comments, setComments] = useState([]);
  const [mobileAppRedirect, setMobileAppRedirect] = useState(false);
  const [optionTypeName, setOptionTypeName] = useState("");
  const [noOfComments, setNoOfComments] = useState(
    props?.post?.nrComments ?? 0
  );

  const [postImpressionModal, setPostImpressionModal] = useState(false);
  const [postViewsModal, setPostViewsModal] = useState(false);

  const history = useHistory();
  const [pageNr, setPageNr] = useState(1);
  const [newCommentModalOpen, setNewCommentModalOpen] = useState(false);
  const [siteInfoModalOpen, setSiteInfoModalOpen] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);
  const [initialViewMore, setInitialViewMore] = useState(true);
  const [displayedTradeCount, setDisplayedTradeCount] = useState(230);
  const [modalSource, setModalSource] = useState("");
  const nrOfRecPerPage = 25;

  const [siteInfo, setSiteInfo] = useState({});

  const getSiteInfo = async () => {
    try {
      let siteRes = await fetchSites({
        SearchList: [{ Id: props?.post?.id }],
      });
      setSiteInfo(siteRes?.data[0] ?? {});
    } catch (error) {
      console.log("Error while getting site info :: ", error);
    }
  };
  const { ref } = useInView({
    /* Optional options */
    threshold: 0.75,
    delay: 500,
  });

  //update comments after one has been posted + on load
  const updateComments = useCallback(async () => {
    let pageNr = 1;
    let nrOfRecPerPage = 25;
    try {
      setLoadingComment(true);
      const postComments = await getPostComments(
        props?.post?.id,
        pageNr,
        nrOfRecPerPage,
        props?.post?.appId
      );
      setComments(postComments);
      setPageNr(1);
      setLoadingComment(false);
    } catch (error) {
      console.log("ERROR ::: ", error);
      setLoadingComment(false);
    }
  }, [props]);

  async function fetchMoreComments() {
    try {
      const postComments = await getPostComments(
        props?.post?.id,
        pageNr + 1,
        nrOfRecPerPage,
        props?.post?.appId
      );
      setComments(comments.concat(postComments));
      setPageNr(pageNr + 1);
    } catch (e) {
      console.log("ERROR ::: ", e);
    }
  }

  const getViewsOnCommentPressed = async (postId) => {
    let currDate = new Date();
    let formattedDate = moment(currDate).format("YYYY-MM-DD HH:mm:ss.SSS");
    let viewedPostModifiedObj = {
      id: postId,
      ViewLastDate: formattedDate,
      NumberOfViews: 1,
      UserId: localStorage?.userId,
    };
    if (props?.setVisitedPosts) props?.setVisitedPosts(viewedPostModifiedObj);
  };

  const handleReport = (value) => {
    setMoreMenuAnchorEl(false);
    props?.setPostId(value?.id);
    props?.setRepostPostName(
      value?.appRecordName === "Ads" || value?.formatTypeId == 2
        ? "Ads"
        : value?.appRecordName
    );
    props?.setReportModalOpen(true);
  };

  const handleHidePost = async (id) => {
    setMoreMenuAnchorEl(false);
    try {
      const hidePostObj = JSON.stringify({
        UserId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            PostId: Number(id),
            UserId: localStorage?.userId,
            IsHidden: true,
            IsMute: true,
          },
        ],
      });
      const hidePostRes = await postSettings(hidePostObj);
      if (hidePostRes.message === "OK") {
        props?.updatePosts();
      }
    } catch (err) {
      console.log("HIDE POST ERROR ", err);
    }
  };

  const handleMutePost = async (post) => {
    setMoreMenuAnchorEl(false);
    try {
      const mutePostObj = JSON.stringify({
        UserId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            PostId: Number(post?.id),
            UserId: localStorage?.userId,
            IsHidden: false,
            IsMute: !post?.isMute,
          },
        ],
      });
      const mutePostRes = await postSettings(mutePostObj);
      if (mutePostRes.message === "OK") {
        props?.updatePosts();
      }
    } catch (err) {
      console.log("MUTE POST ERROR ", err);
    }
  };

  function handleMobileAppClick() {
    if (isAndroid) {
      const url = `intent://?id=${props?.post?.appRecordId}&type=${optionTypeName}#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
      window.location.replace(url);
    } else if (isIphone) {
      window.location.replace(
        `mybuilderid://?id=${props?.post?.appRecordId}&type=${optionTypeName}`
      );
    }
  }

  function handleWebAppClick() {
    if (props?.post?.appId === 2)
      history.push(`/sites/site/${props?.post?.appRecordId}`);
    else if (props?.post?.appId === 4)
      history.push(`/product/${props?.post?.appRecordId}`);
    else if (props?.post?.appId === 7)
      history.push(`/trade/${props?.post?.appRecordId}`);
    else if (props?.post?.appId === 24)
      history.push(`/projects/project/${props?.post?.appRecordId}`);
    else setMobileAppRedirect(false);
  }

  const getInfoComments = async () => {
    try {
      const infoComments = await getPosts(19, 0, 1, 20);
      setComments(infoComments);
    } catch (error) {
      console.log("Get Project PostsError:", error.message ?? error);
    }
  };

  //handles comments on or off
  let handleCommentToggler = () => {
    setNewCommentModalOpen(true);
    setModalSource(""); // Set source to "comment"
    updateComments();

    if (!newCommentModalOpen) {
      getViewsOnCommentPressed(props?.post?.id);
    }
  };

  let handleInfoToggler = () => {
    setModalSource("info");
    getInfoComments();
    setNewCommentModalOpen(true);
  };

  // let handleSiteInfo = () => {
  //   if (props?.post?.icon == "Map Pin") {
  //     setSiteInfoModalOpen(true);
  //     getSiteInfo();
  //   }
  // };

  const handleSiteInfo = () => {
    if (props?.post?.icon == "Map Pin" && props?.post?.formatTypeId == 5) {
      history.push({
        pathname: "/new-map",
        state: {
          from: "dashboard-profile-post",
          appRecordId: props?.post?.appRecordId,
        },
      });
    }
  };

  function getPostIcon(postIcon) {
    switch (postIcon) {
      case "Briefcase":
        return <BusinessCenterIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      case "Map Pin":
        return <RoomIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      case "Wrench":
        return <BuildIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      case "Pencil":
        return <EditIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      default:
        return;
    }
  }

  useEffect(() => {
    switch (props?.post?.appId) {
      case 2:
        setOptionTypeName("Sites");
        break;
      case 4:
        setOptionTypeName("Products");
        break;
      case 7:
        setOptionTypeName("Trades");
        break;
      default:
        setOptionTypeName("");
        break;
    }
  }, []);

  function handleAdClick(clickFrom) {
    switch (clickFrom) {
      case "MySites1":
        history.push("/sites");
        break;
      case "Tickets":
        history.push("/tickets");
        break;
      case "MySites":
        history.push("/sites");
        break;
      case "Sites":
        history.push("/sites");
        break;
      case "Quiz":
        history.push("/rewards/earn-builderbucks/");
        break;
      case "Expenses":
        history.push("/expenses");
        break;
      case "RewardStore":
        history.push("/rewards/store/");
        break;
      case "https://mybuilderid.com/":
        window.open(clickFrom, "_blank");
        break;
      default:
        if (clickFrom) {
          const url =
            clickFrom.startsWith("http://") || clickFrom.startsWith("https://")
              ? clickFrom
              : `https://${clickFrom}`;
          window.open(url, "_blank");
        }
        break;
    }
  }

  const handleChange = (inView, entry) => {
    if (entry?.isIntersecting) {
      const obj = {
        id: entry?.target?.childNodes[0].id,
        ImpressionLastDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        NumberOfImpression: 1,
        UserId: localStorage?.userId,
      };
      if (props?.setVisitedPosts) props?.setVisitedPosts(obj);
    }
  };

  // const getMyPostDate = (date) => {
  // 	if (!moment.isMoment(date)) date = moment(date);
  // 	if (date.isSame(moment(), 'day')) {
  // 		return moment.parseZone(date).local().format('h:mma');
  // 	} else if (date.isSame(moment().subtract(1, 'd'), 'day')) {
  // 		return 'Yesterday';
  // 	} else if (date.isSame(moment(), 'week')) {
  // 		return moment.parseZone(date).local().format('dddd');
  // 	} else {
  // 		return moment.parseZone(date).local().format('MMMM DD, YYYY');
  // 	}
  // }

  const getMyPostDate = (date) => {
    return moment.parseZone(date).local().format("MMMM DD, YYYY");
  };

  // const postDate = props?.post?.age ? props?.post?.age?.includes('min') || props?.post?.age?.includes('hours') || props?.post?.age?.includes('days') || props?.post?.age?.includes('hour') || props?.post?.age?.includes('day') ? props?.post?.age : getMyPostDate(moment(props?.post?.age)) : '';
  // const postDate = props?.post?.age ? props?.post?.age : getMyPostDate(moment(props?.post?.age));
  const postDate = getMyPostDate(props?.post?.postedDate);

  const handleShowMoreTrades = () => {
    setInitialViewMore(false);
    setDisplayedTradeCount(props?.post?.message.length);
  };

  const handleShowLessTrades = () => {
    setInitialViewMore(true);
    setDisplayedTradeCount(230);
  };

  const onPostUserPressed = (params) => {
    if (Number(props?.post?.postedByUserId) !== Number(localStorage?.userId)) {
      history.push(`/user-dashboard-profile/${params}`);
    } else {
      history.push(`/profile`);
    }
  };

  const messageParts = props?.post?.message?.split(/\n/);

  return (
    <>
      <PromptModal
        isOpen={mobileAppRedirect}
        onClose={() => setMobileAppRedirect(false)}
        title="Open link in App"
        desc="Please choose your option."
        redButtonText="Web"
        blueButtonText="Open App"
        onBlueButtonClick={() => {
          handleMobileAppClick();
        }}
        onRedButtonClick={() => handleWebAppClick()}
      />
      <PostCommentModal
        isOpen={newCommentModalOpen}
        onClose={() => {
          setNewCommentModalOpen(false);
          setModalSource("");
          setPageNr(1);
        }}
        comments={comments}
        post={props?.post}
        updateComments={updateComments}
        getNoOfComments={() => {
          setNoOfComments((preValue) => preValue + 1);
        }}
        loading={props?.loading ? props?.loading : loadingComment}
        fetchMoreComments={fetchMoreComments}
        setPostId={(value) => props?.setPostId(value)}
        setReportModalOpen={(value) => props?.setReportModalOpen(value)}
        source={modalSource}
        updatePosts={props?.updatePosts}
      />
      <InView
        as="div"
        onChange={(inView, entry) => handleChange(inView, entry)}
        style={{ marginTop: "8px" }}
      >
        <Card
          id={props?.post?.id}
          ref={ref}
          className={
            props?.from == "projectDetailPage" ||
            props?.from == "productDetail" ||
            props?.from == "tradeDetail"
              ? ""
              : // : "dashboard-inlineMargin-post"
                ""
          }
          style={{
            // Post Card background is light yellow if pinned
            backgroundColor: props?.pinned ? BILightYellow : BIWhite,
            // marginTop:
            //   props?.from == "projectDetailPage" ||
            //   props?.from == "productDetail" ||
            //   props?.from == "tradeDetail" ||
            //   !props?.pinned
            //     ? "5px"
            //     : "0px",
            paddingInline: 0,
            borderRadius: "10px",
          }}
        >
          <div className="postBody">
            <CardHeader
              style={{ cursor: "pointer" }}
              onClick={() => (props?.pinned ? handleInfoToggler() : "")}
              avatar={
                props?.post?.appRecordName === "Ads" ||
                props?.post?.formatTypeId == 2 ? null : (
                  <Avatar
                    alt="Profile Picture"
                    style={{
                      width: props?.post?.appId === 19 ? "30px" : "",
                    }}
                    src={
                      props?.post?.appId === 19
                        ? mapMarkerYellow
                        : props?.post?.postedByPictureUrl
                    }
                  />
                )
              }
              title={
                props?.post?.appRecordName === "Ads" ||
                props?.post?.formatTypeId == 2 ? (
                  <span style={{ fontSize: "15px", color: "GrayText" }}>
                    <b>{props?.post?.label}</b>
                  </span>
                ) : (
                  <>
                    <span
                      className={
                        props?.post?.appId === 19 ||
                        props?.from == "projectDetailPage" ||
                        props?.from == "productDetail" ||
                        props?.from == "tradeDetail" ||
                        (props?.post?.appId == 25 &&
                          props?.post?.postedByFirstName?.trim() == "Past" &&
                          props?.post?.postedByLastName?.trim() == "Builder")
                          ? ""
                          : "link"
                      }
                      onClick={() => {
                        if (props?.post?.appId === 2) {
                          if (isAndroid || isIphone) setMobileAppRedirect(true);
                          // else {
                          //   if (
                          //     Number(props?.post?.postedByUserId) !==
                          //     Number(localStorage?.userId)
                          //   ) {
                          //     history.push(
                          //       `/sites/site/${props?.post?.appRecordId}`
                          //     );
                          //   } else {
                          //     history.push(`/profile`);
                          //   }
                          // }
                          return;
                        }

                        if (props?.post?.appId === 4) {
                          if (isAndroid || isIphone) setMobileAppRedirect(true);
                          else {
                            if (
                              Number(props?.post?.postedByUserId) ===
                                Number(localStorage?.userId) &&
                              props?.from == "productDetail"
                            ) {
                              history.push(`/profile`);
                            } else {
                              history.push(
                                `/product/${props?.post?.appRecordId}`
                              );
                            }
                          }
                          return;
                        }

                        if (props?.post?.appId === 7) {
                          if (isAndroid || isIphone) setMobileAppRedirect(true);
                          else {
                            console.log("props?.post---", props?.post);
                            if (
                              Number(props?.post?.postedByUserId) ===
                                Number(localStorage?.userId) &&
                              props?.from == "tradeDetail"
                            ) {
                              history.push(`/profile`);
                            } else {
                              history.push(
                                `/trade/${props?.post?.appRecordId}`
                              );
                            }
                          }
                          return;
                        }

                        if (props?.post?.appId === 15) {
                          if (isAndroid || isIphone) setMobileAppRedirect(true);
                        }

                        if (props?.post?.appId === 19) {
                          if (isAndroid || isIphone) setMobileAppRedirect(true);
                        }

                        if (props?.post?.appId === 24) {
                          if (isAndroid || isIphone) {
                            setMobileAppRedirect(true);
                          } else {
                            if (
                              Number(props?.post?.postedByUserId) ===
                                Number(localStorage?.userId) &&
                              props?.from == "projectDetailPage" &&
                              props?.post?.appRecordName != ""
                            ) {
                              history.push(`/profile`);
                            } else {
                              history.push(
                                `/projects/project/${props?.post?.appRecordId}`
                              );
                            }
                          }
                        }
                        if (
                          props?.post?.appId == 25 &&
                          props?.from == "Dashboard"
                        ) {
                          onPostUserPressed(props?.post?.postedByUserId);
                        } else if (
                          props?.from == "projectDetailPage" ||
                          props?.from == "productDetail" ||
                          props?.from == "tradeDetail"
                        ) {
                          onPostUserPressed(props?.post?.postedByUserId);
                        }
                      }}
                    >
                      <b>
                        {/* {props?.post?.appRecordName != ""
                          ? props?.post?.appRecordName
                          : `${props?.post?.postedByFirstName} ${props?.post?.postedByLastName}`} */}

                        {props.from == "Dashboard" ? (
                          props.post?.appId == 19 ? (
                            "BuildID Info"
                          ) : props.post?.appId == 25 ? (
                            props.post.postedByFirstName?.trim() +
                            " " +
                            props.post.postedByLastName?.trim()
                          ) : (
                            props.post?.appRecordName
                          )
                        ) : (
                          <>
                            {props.post.postedByUserId !=
                              Number(localStorage.userId) &&
                            props.post?.addToProfile
                              ? (
                                  props.post.postedByFirstName?.trim() +
                                  " " +
                                  props.post.postedByLastName?.trim()
                                ).substring(0, 16 - 3) + "..."
                              : props.post.postedByFirstName?.trim() +
                                " " +
                                props.post.postedByLastName?.trim()}
                            {props.post.postedByUserId !=
                              Number(localStorage.userId) &&
                              props.post?.addToProfile && (
                                <span> | Worked On</span>
                              )}
                          </>
                        )}
                      </b>
                    </span>

                    {props?.pinned ? (
                      <PushPinIcon
                        sx={{
                          fontSize: "14px",
                          color: BIGrey,
                          marginLeft: "4px",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )
              }
              subheader={
                props?.post?.appRecordName === "Ads" ||
                props?.post?.formatTypeId == 2 ? (
                  <>
                    {props?.post?.message !== "<<<picture>>>" ? (
                      <span style={{ fontSize: "0.8rem" }} variant="body2">
                        {props.post?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                ) : props.from == "Dashboard" ? (
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <span
                      className={
                        (props.from == "Dashboard" &&
                          props?.post?.appId === 19) ||
                        (props?.post?.postedByFirstName?.trim() == "Past" &&
                          props?.post?.postedByLastName?.trim() == "Builder")
                          ? ""
                          : "link"
                      }
                      onClick={() => {
                        if (
                          Number(props?.post?.postedByUserId) !==
                          Number(localStorage?.userId)
                        ) {
                          if (
                            props?.post?.postedByFirstName?.trim() == "Past" &&
                            props?.post?.postedByLastName?.trim() == "Builder"
                          ) {
                            return;
                          } else {
                            history.push(
                              `/user-dashboard-profile/${props?.post?.postedByUserId}`
                            );
                          }
                        } else {
                          history.push(`/profile`);
                        }
                      }}
                    >
                      <span style={{ fontSize: "0.8rem" }} variant="body2">
                        {props.from == "Dashboard" && props.post?.appId == 19
                          ? props.post?.appRecordName
                          : props.post?.appId == 25
                          ? props.post?.tradeName ?? ""
                          : props.post?.postedByFirstName?.trim() +
                            " " +
                            props.post?.postedByLastName?.trim()}{" "}
                        {props.post?.tradeName != "" &&
                        props.post?.tradeName != null &&
                        props.post?.appId != 25
                          ? `(${props.post?.tradeName})`
                          : ""}
                      </span>
                    </span>
                    <span
                      className={
                        (props.from == "Dashboard" &&
                          props?.post?.appId === 19) ||
                        (props?.post?.postedByFirstName?.trim() == "Past" &&
                          props?.post?.postedByLastName?.trim() == "Builder")
                          ? ""
                          : "link"
                      }
                    >
                      <ul style={{ marginLeft: "25px" }}>
                        <li>
                          {" "}
                          <Typography
                            style={{ fontSize: "0.8rem" }}
                            variant="body2"
                          >
                            {" "}
                            {postDate}
                            {props.post.postedByUserId != props.userId &&
                              props.post?.addToProfile && (
                                <span> | Worked On</span>
                              )}
                          </Typography>
                        </li>
                      </ul>
                    </span>
                  </div>
                ) : props?.post?.appRecordName !== "Ads" &&
                  props.post?.tradeName != "" &&
                  props.post?.tradeName != null ? (
                  <span>{props.post?.tradeName}</span>
                ) : null
              }
              action={
                // Don't show "..." button for BuildID Info group posts (these have a
                // hardcoded appId of 19)
                props?.post?.appId !== 19 ? (
                  <>
                    <IconButton
                      aria-label="settings"
                      onClick={(event) =>
                        setMoreMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={moreMenuAnchorEl}
                      open={Boolean(moreMenuAnchorEl)}
                      onClose={() => setMoreMenuAnchorEl(null)}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      {Number(props?.post?.postedByUserId) ===
                        Number(localStorage?.userId) && (
                        <MenuItem
                          onClick={() => handleMutePost(props?.post)}
                          style={{ color: BIBlue }}
                          dense
                        >
                          {props?.post?.isMute
                            ? "Turn on notifications for this Post"
                            : "Turn off notifications for this Post"}
                        </MenuItem>
                      )}
                      {props?.post?.appRecordName === "Ads" ||
                      props?.post?.formatTypeId == 2 ? null : (
                        <MenuItem
                          onClick={() => handleHidePost(props?.post?.id)}
                          style={{ color: BIBlue }}
                          dense
                        >
                          Hide Post
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => handleReport(props?.post)}
                        style={{ color: BIRed }}
                        dense
                      >
                        Report Post
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <></>
                )
              }
            />
            {/* card body description */}
            <CardContent
              className={
                props?.post?.appRecordName === "Ads"
                  ? "postAdImage"
                  : "cardBody"
              }
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingInline: "0px",
              }}
            >
              {props?.post?.appRecordName === "Ads" ||
              props?.post?.formatTypeId == 2 ? (
                <>
                  {props?.post?.fileUrl !== "" && (
                    <img
                      src={props?.post?.fileUrl}
                      alt="AdPicture"
                      onClick={() => handleAdClick(props?.post?.redirectUrl)}
                      style={{
                        height: "300px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => (props?.pinned ? handleInfoToggler() : "")}
                >
                  {props?.post?.icon !== "" ||
                  props?.post?.formatTypeId !== 2 ? (
                    <Typography align="center">
                      {" "}
                      {getPostIcon(props?.post?.icon)}{" "}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {props?.post?.appRecordName !== "Ads" && (
                    <Typography
                      align={
                        props?.post?.icon === "" || props?.post?.icon === null
                          ? "left"
                          : "center"
                      }
                      style={{
                        padding: "0px 24px",
                        fontFamily: "G-Roboto-n4, Arial",
                        fontSize: "14px",
                        color: "#4f4f4f",
                        lineHeight: "1.4",
                        letterSpacing: 0,
                      }}
                    >
                      {messageParts?.length > 2 ? (
                        <>
                          {props?.post?.message !== "<<<picture>>>"
                            ? messageParts?.map((part, index) => (
                                <span key={index}>
                                  {part?.slice(0, displayedTradeCount)}
                                  {index < messageParts?.length - 1 && <br />}
                                </span>
                              ))
                            : ""}
                        </>
                      ) : (
                        <>
                          {props?.post?.message !== "<<<picture>>>" ? (
                            <span onClick={() => handleSiteInfo()}>
                              {props?.post?.message?.slice(
                                0,
                                displayedTradeCount
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {props?.post?.message?.length > 230 && (
                        <>
                          {initialViewMore ? (
                            <>
                              <span> ... </span>
                              <Button
                                style={{ padding: "0px" }}
                                color="inherit"
                                size="small"
                                variant="text"
                                onClick={handleShowMoreTrades}
                              >
                                see more
                              </Button>
                            </>
                          ) : (
                            <Button
                              style={{ padding: "0px" }}
                              color="inherit"
                              size="small"
                              variant="text"
                              onClick={handleShowLessTrades}
                            >
                              see less
                            </Button>
                          )}
                        </>
                      )}
                    </Typography>
                  )}
                  {props?.post?.appRecordName === "Ads" ||
                  props?.post?.formatTypeId == 2 ? (
                    <>
                      {props?.post?.fileUrl !== "" && (
                        <img
                          src={props?.post?.fileUrl}
                          alt="AdPicture"
                          onClick={() =>
                            handleAdClick(props?.post?.redirectUrl)
                          }
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </>
                  ) : null}
                  {props?.post?.pictureList ? (
                    <PictureViewer
                      isOpen={pictureViewerOpen}
                      onClose={() => setPictureViewerOpen(false)}
                      pictures={props?.post?.pictureList?.map((pic) => ({
                        src: pic?.pictureUrl,
                        alt: "",
                      }))}
                      viewerIndex={pictureViewIndex}
                    />
                  ) : (
                    ""
                  )}

                  {
                    // Post photos
                    props?.post?.pictureList ? (
                      <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                        <Carousel
                          showThumbs={false}
                          showIndicators={
                            props?.post?.pictureList?.length > 1 ? true : false
                          }
                        >
                          {props?.post?.pictureList?.map((picture, index) => (
                            <div
                              key={picture?.id}
                              style={{
                                cursor: "pointer",
                                height: "330px",
                                display: "block",
                              }}
                              onClick={() => {
                                setPictureViewerOpen(true);
                                setPictureViewIndex(index);
                              }}
                            >
                              <img
                                src={picture?.pictureUrl}
                                alt=""
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </div>
              )}
              {props?.post?.appRecordName === "Ads" ||
              props?.post?.formatTypeId == 2 ? null : (
                <>
                  <Divider />
                  {props?.post?.appRecordName !== "Ads" && (
                    <CardActions
                      disableSpacing
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      {!props?.pinned &&
                      props?.post?.icon === "" &&
                      props?.post?.formatTypeId !== 0 ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => setPostImpressionModal(true)}
                          >
                            <BarChartIcon />
                          </IconButton>
                          <Typography variant="subtitle1">
                            {props?.post?.nrOfUsersImpressionsPerPost}
                          </Typography>
                        </div>
                      ) : (
                        <></>
                      )}
                      {!props?.pinned &&
                      props?.post?.icon === "" &&
                      props?.post?.formatTypeId !== 0 ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton onClick={() => setPostViewsModal(true)}>
                            <RemoveRedEyeIcon />
                          </IconButton>
                          <Typography variant="subtitle1">
                            {props?.post?.nrOfUsersViewedPost}
                          </Typography>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={() => handleCommentToggler()}>
                          <CommentIcon />
                        </IconButton>
                        <Typography variant="subtitle1">
                          {noOfComments}
                        </Typography>
                      </div>
                      {props?.post?.isMute && (
                        <IconButton disabled>
                          <NotificationsOffIcon />
                        </IconButton>
                      )}
                    </CardActions>
                  )}
                </>
              )}
            </CardContent>
          </div>
          {postImpressionModal && (
            <AlertModal
              isOpen={postImpressionModal}
              onClose={() => setPostImpressionModal(false)}
              title={"Impressions"}
              body={
                <>
                  This post was seen on BuildId{" "}
                  <span style={{ color: BIBlue }}>
                    <b>{props?.post?.nrOfUsersImpressionsPerPost}</b>
                  </span>{" "}
                  times.
                </>
              }
            />
          )}

          {postViewsModal && (
            <AlertModal
              isOpen={postViewsModal}
              onClose={() => setPostViewsModal(false)}
              title={"Views"}
              body={
                <>
                  <span style={{ color: BIBlue }}>
                    <b>{props?.post?.nrOfUsersViewedPost}</b>
                  </span>{" "}
                  BuildID users opened this post?.
                </>
              }
            />
          )}
        </Card>
      </InView>
    </>
  );
};

export default Post;
