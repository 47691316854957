import React, { useEffect, useState } from "react";
import {
  Modal,
  IconButton,
  ListItemText,
  Select,
  InputLabel,
  Box,
  Avatar,
  ListItemButton,
  Card,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import {
  fetchUserTrades,
  fetchUserWorkHistory,
  fetchUserWorkHistory2,
  fetchUsersSiteProjects,
} from "../../api/UserApi";
import { fetchJobPosts, saveJobPosts } from "../../api/SitesApi";
import Loader from "../../components/Loader";
import { BIBlue } from "../../assets/buildidColors";
import defaultProjectProfileThumbnail from "../../assets/images/project-profile-placeholder-thumbnail.png";

import moment from "moment";
import { Typography } from "@material-ui/core";
function removeDuplicates(originalArray, prop) {
  let newArray = [];
  let lookupObject = {};
  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }
  for (let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
{
  /* 3.9 added */
}

const ProjectListPopup = ({ onSelect, isOpen, onClose, projects }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="modal-center timesheet-modal">
        <div className="modal-card">
          <div
            className="modal-card-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h2>Choose a Project</h2>
            </div>
            <div className="modal-card-header-buttons">
              <IconButton
                color="primary"
                onClick={async () => {
                  onClose();
                }}
              >
                <CloseIcon style={{ color: BIBlue }} />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              width: 650,
              padding: 16,
              height: 400,
              overflow: "scroll",
            }}
          >
            {projects.length === 0 ? (
              <h5>
                Projects shown will only include sites at the company you work
                for
              </h5>
            ) : (
              projects.map((project) => (
                <div key={project.id}>
                  <div style={{ margin: "5px" }}>
                    <h4>{project.companyName}</h4>
                  </div>
                  <div>
                    <div>
                      <h3>
                        <Card
                          className="expCardBgc"
                          style={{ display: "flex", padding: "5px" }}
                        >
                          <ListItemButton
                            onClick={() =>
                              onSelect({
                                siteData: project,
                                projectData: project,
                              })
                            }
                          >
                            <Avatar
                              variant="square"
                              style={{
                                marginRight: "15px",
                                cursor: "pointer",
                                width: "80px",
                                height: "55px",
                                borderRadius: "5px",
                              }}
                              src={defaultProjectProfileThumbnail}
                            />
                            <div>
                              <h6>{project?.projectName}</h6>
                              <Typography key={project.id} variant="subtitle2">
                                {project?.siteName}
                              </Typography>
                              <Typography key={project.id} variant="subtitle2">
                                {`${project?.address}, ${project?.city}, ${project?.province}, ${project?.postalCode}, ${project?.country}`}
                              </Typography>
                            </div>
                          </ListItemButton>
                        </Card>
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
{
  /* 3.9 added */
}

function NewJobPostingModal(props) {
  const [tradeId, setTradeId] = useState(0);
  const [trades, setTrades] = useState([]);
  const [selectedTrade, setSelectedTrade] = useState("");
  const [selectedJobDurationType, setSelectedJobDurationType] =
    useState("Week(s)");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);

  const [isChooseSiteModal, setIsChooseSiteModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email.trim());
  };
  const [showDuplicateRecordMessage, setShowDuplicateRecordMessage] =
    useState(false);
  const isPublishDisabled = !selectedProject || !selectedTrade || !contactEmail;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxWidth: ITEM_HEIGHT + ITEM_PADDING_TOP,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const getTrades = async () => {
    const tradeResponse = await fetchUserTrades({
      PageNr: 1,
      FullSearch: "",
      SearchList: [],
      IncludeRecordNr: false,
      TypeOfObjectReturned: "NameOnly",
      UserId: localStorage.userId,
      FetchAllowedRecordsOnly: true,
      // SortList: [
      //   {
      //     FieldName: "tradeId",
      //     Direction: "ASC",
      //   },
      // ],
    });

    setTrades(tradeResponse);
    // checkDuplicateRecords();
  };
  const checkDuplicateRecords = async (selectedProject, selectedTrade) => {
    try {
      const fetchObj = {
        PageNr: 1,
        // NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ createdBy: Number(localStorage.userId) }],
      };

      const result = await fetchJobPosts(fetchObj);
      console.log("result ===>", result);
      const isDuplicate = result.some(
        (record) =>
          record.siteId === selectedProject?.siteId &&
          record.projectId === selectedProject?.projectId &&
          record.companyId === selectedProject?.companyId &&
          record.tradeId === selectedTrade
      );
      console.log(
        "isDuplicate ::",
        isDuplicate,
        selectedProject,
        selectedTrade
      );

      setShowDuplicateRecordMessage(isDuplicate);
    } catch (error) {
      console.log("FETCH_JOBS" + error);
    }
  };

  const handleTradeChange = (event) => {
    const value = event.target.value;
    const newArr = trades.filter((item) => item.tradeId === value);
    // console.log("value =====>", value, newArr);
    setTradeId(value);
    setSelectedTrade(value);
    checkDuplicateRecords(selectedProject, value);
  };
  const handleJobDurationType = (value) => {
    setSelectedJobDurationType(value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleDuration = (event) => {
    setDuration(event.target.value);
  };
  const handleContactEmail = (event) => {
    const email = event.target.value;
    setContactEmail(email);
    const isValid = validateEmail(email);
    setIsValidEmail(isValid);
    setIsEmailValid(isValid);
  };
  console.log("selectedProjetcs::---", selectedProject);
  const publishJob = async () => {
    props.setIsLoading(true);
    const obj = {
      UserId: localStorage.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          SiteId: selectedProject?.projectData?.siteId,
          ProjectId: selectedProject?.projectData?.userSiteProjectId,
          TradeId: tradeId,
          CompanyId: selectedProject?.projectData?.companyId,
          JobDuration: duration || 0,
          JobDurationType: selectedJobDurationType,
          Email: contactEmail,
          ModifiedBy: Number(localStorage.userId),
          description: description,
        },
      ],
    };

    try {
      if (!isEmailValid) {
        console.log("Email is not valid. Job post not saved.");
        return;
      }

      await saveJobPosts(obj);

      setSelectedTrade("");
      setSelectedJobDurationType("Week(s)");
      setDescription("");
      setDuration("");
      setContactEmail("");
      props.onClose();

      const updatedActiveJobs = await props.getData();
      props.setActiveJobs(updatedActiveJobs);
    } catch (error) {
      console.error("Error saving job post:", error);
    } finally {
      props.setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserSite();
    getTrades();
  }, []);

  const fetchUserSite = async () => {
    props.setIsLoading(true);
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 20,
        UserId: Number(localStorage.userId),
        SearchList: [{ UserId: Number(localStorage.userId) }],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };
      let userProjects = await fetchUserWorkHistory2(workHistoryObj);
      userProjects = removeDuplicates(
        userProjects?.userExperienceList,
        "projectId"
      );
      const finalCompSites = userProjects.filter(
        (comp) => comp.companyId !== 0 && comp.projectName !== ""
      );

      console.log("Final project list : ", finalCompSites);
      setProjects(finalCompSites ?? []);
      props.setIsLoading(false);
    } catch (e) {
      console.log("ERROR : [fetchUserSite] : ", e);
      props.setIsLoading(false);
    }
  };
  console.log("projects:::::", projects);
  const handleProjectSelection = async (props) => {
    const selectedProjectDetails = {
      companyName: props.siteData.companyName,
      siteName: props.siteData.siteName,
      address: props.siteData?.address,
      city: props.siteData?.city,
      province: props.siteData?.province,
      postalCode: props.siteData?.postalCode,
      country: props.siteData?.country,
      mainPictureUrl: props.siteData?.mainPictureUrl,
      isChooseSiteModal: false,
      siteId: props.siteData.siteId,
      companyId: props.siteData.companyId,
      projectId: props.projectData.id,
      projectData: props.projectData,
    };

    await setSelectedProject(selectedProjectDetails);

    setCompanyName(selectedProjectDetails?.companyName);
    setIsChooseSiteModal(false);
    checkDuplicateRecords(selectedProjectDetails, tradeId);
  };

  const onChooseSitePressed = () => {
    try {
      setIsChooseSiteModal(true);
      fetchUserSite();
    } catch (error) {
      console.log("fetchUsersWorkHistoryError:", error);
    }
  };
  const onCloseSeletedProject = () => {
    setSelectedProject(null);
    setCompanyName("");
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={async () => {
          props.onClose();
        }}
      >
        <div className="modal-center timesheet-modal" style={{ height: "83%" }}>
          <div className="modal-card" style={{ height: "85%" }}>
            <div
              className="modal-card-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h2>New Job Posting</h2>
              </div>
              <div className="modal-card-header-buttons">
                <IconButton
                  color="primary"
                  onClick={async () => {
                    onCloseSeletedProject();
                    props.onClose();
                  }}
                >
                  <CloseIcon style={{ color: BIBlue }} />
                </IconButton>
              </div>
            </div>
            <div
              className="modal-card-body"
              style={{
                padding: "10px 15px 0px 0px",
                overflowY: "auto",
                maxHeight: "70vh",
              }}
            >
              <p>
                You can make a Job Posting for your Company at a Site you work
                at.{" "}
              </p>
              {/* 3.9 added */}
              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <ProjectListPopup
                    isOpen={isChooseSiteModal}
                    onClose={() => setIsChooseSiteModal(false)}
                    onSelect={handleProjectSelection}
                    projects={projects}
                  />
                  {companyName == "" ? (
                    <Button
                      variant="outlined"
                      onClick={() => onChooseSitePressed()}
                    >
                      CHOOSE PROJECT
                    </Button>
                  ) : null}
                  {companyName != "" ? (
                    <div>
                      {selectedProject && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h3> {selectedProject.companyName}</h3>
                            <CloseIcon
                              style={{ color: BIBlue }}
                              onClick={() => {
                                onCloseSeletedProject();
                              }}
                            />
                          </div>
                          <Box
                            style={{ display: "flex", padding: "5px" }}
                            gap={2}
                          >
                            <Avatar
                              variant="square"
                              style={{
                                marginRight: "15px",
                                cursor: "pointer",
                                width: "100px",
                                height: "70px",
                                borderRadius: "5px",
                              }}
                              src={
                                selectedProject?.mainPictureUrl ||
                                defaultProjectProfileThumbnail
                              }
                            />
                            <div>
                              <p>
                                {selectedProject.projectData.projectName} <br />
                                {selectedProject.siteName}
                                <br />
                                {selectedProject.address},{" "}
                                {selectedProject?.city},{" "}
                                {selectedProject?.province},{" "}
                                {selectedProject?.postalCode},{" "}
                                {selectedProject?.country}
                              </p>
                            </div>
                          </Box>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* 3.9 added */}

              {/* <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}>
                  <FormControl>
                    <InputLabel id="mutiple-select-label">
                      Choose Site
                    </InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      variant="outlined"
                      value={selected}
                      label="Choose Site"
                      onChange={handleChange}
                      MenuProps={MenuProps}
                      style={{ width: "100%" }}>
                      {options.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.siteName}>
                            <ListItemText
                              primary={option.siteName}
                              secondary={option.address}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <FormControl>
                    <InputLabel id="trade-select-label">
                      Choose Trade
                    </InputLabel>
                    <Select
                      labelId="trade-select-label"
                      variant="outlined"
                      label="Choose Trade"
                      value={selectedTrade}
                      onChange={handleTradeChange}
                      MenuProps={MenuProps}
                      style={{ width: "100%" }}
                    >
                      {trades.map((trade) => (
                        <MenuItem key={trade.id} value={trade.tradeId}>
                          <ListItemText primary={trade.tradeName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="input-container" style={{ display: "flex" }}>
                <div>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Duration "
                    error={duration === "0"}
                    style={{
                      width: "140%",
                      // borderColor: duration == 0 ? "red" : "black",
                    }}
                    value={duration}
                    onChange={handleDuration}
                  />
                </div>
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <FormControl fullWidth>
                    <Select
                      placeholder="Week(s)"
                      variant="outlined"
                      value={selectedJobDurationType}
                      style={{ width: "170%", marginLeft: "90px" }}
                      onChange={(event) => {
                        handleJobDurationType(event.target.value);
                      }}
                    >
                      <MenuItem value="Day(s)">Day(s)</MenuItem>
                      <MenuItem value="Week(s)">Week(s)</MenuItem>
                      <MenuItem value="Month(s)">Month(s)</MenuItem>
                      <MenuItem value="Year(s)">Year(s)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <TextField
                    type="text"
                    variant="outlined"
                    multiline
                    label="Description (Optional)"
                    rows={4}
                    value={description}
                    onChange={handleDescription}
                  />
                </div>
              </div>
              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <TextField
                    type="email"
                    variant="outlined"
                    label="Contact Email (eg. HR, General Contractor, etc.)"
                    value={contactEmail}
                    onChange={handleContactEmail}
                    error={!isValidEmail && contactEmail !== ""}
                    helperText={
                      !isValidEmail && contactEmail !== ""
                        ? "Please enter a valid email address"
                        : ""
                    }
                  />
                </div>
              </div>

              <p>
                Email is Only used for applications to this Job Posting, and
                will not be shared with others.
              </p>
              {!showDuplicateRecordMessage ? (
                <>
                  <p style={{ textAlign: "center" }}>
                    Job Postings are active for 2 weeks
                  </p>
                  <div
                    className="modal-card-footer"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {props.isLoading ? (
                      <Loader color="primary" />
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          color: "white",
                          background: isPublishDisabled ? "lightgrey" : BIBlue,
                        }}
                        disabled={isPublishDisabled}
                        onClick={publishJob}
                      >
                        Publish
                      </Button>
                    )}
                  </div>
                </>
              ) : null}
              {showDuplicateRecordMessage ? (
                <div
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#FFEEBF",
                    paddingVertical: 15,
                    paddingHorizontal: 10,
                    marginTop: 40,
                    borderRadius: 5,
                    display: "flex",
                    padding: "10px",
                  }}
                >
                  <PriorityHighRoundedIcon
                    name="error"
                    style={{ color: "#6F4E37" }}
                    size={20}
                  />
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        color: "#6F4E37",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    >
                      Someone from {projects?.companyName} has already added a
                      post for a {projects?.selectedTradeName} at{" "}
                      {projects?.siteName}. {"\n\n"} You can not create a
                      duplicate Job Posting.
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NewJobPostingModal;
