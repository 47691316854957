import { restGet, restPost, restDelete } from "./RestClient";
import { headers, form_headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Accept": "application/json",
// 	"Content-Type": "multipart/form-data",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

export async function fetchTickets(args) {
  const res = await restPost("tickets/FetchTickets/", args, headers);
  return res.data;
}

export async function saveTicket(data) {
  const res = await restPost("tickets/SaveTicket2/", data, form_headers);
  return res.data;
}

export async function unshareTicketsWithUser1(data) {
  const res = await restPost("tickets/UnshareTicketsWithUser1/", data, headers);
  return res;
}

export async function sendTickets(args) {
  const res = await restPost("tickets/SendTickets/", args, headers);
  return res.data;
}

export async function deleteTicket(args) {
  const res = await restPost("tickets/deletetickets/", args, headers);
  return res;
}

export async function deleteTicketPictures(data) {
  const res = await restPost("tickets/DeleteTicketPictures/", data, headers);
  return res;
}

// Gets ticket type options for Dropdown in ticket edit modal
export async function fetchTicketTypes(args) {
  const res = await restPost("tickets/FetchTicketTypes/", args, headers);
  return res.data;
}

// Ticket Sharing
export async function getTicketsSharedWithUser(userId) {
  const res = await restGet(
    "tickets/GetTicketsSharedWithUser/" + userId,
    headers
  );
  return res;
}

export async function getUsersSharedWith(userId) {
  const res = await restGet("tickets/GetUsersSharedWith2/" + userId, headers);
  return res;
}

export async function shareTicketsWithOthers(data) {
  const res = await restPost("tickets/ShareTicketsWithOthers/", data, headers);
  return res.data;
}

export async function saveTicketTypes(data) {
  const res = await restPost("tickets/SaveTicketTypes/", data, headers);
  return res.data;
}

export async function unshareTicketsWithUser(userEmail) {
  const res = await restGet(
    "tickets/UnshareTicketsWithUser/" + userEmail,
    headers
  );
  return res;
}

export async function searchUsersMembers(
  currentUserId = localStorage.userId,
  searchUserId = 0,
  searchStr = ""
) {
  const res = await restGet(
    `users/searchUsersMembers/${currentUserId}/${searchUserId}/${searchStr}`,
    headers
  );
  return res;
}

export async function NewUnshareTicketsWithUser(userEmail, unsharedOwnerEmail) {
  const res = await restGet(
    `tickets/UnshareTicketsWithUser/${userEmail}/${unsharedOwnerEmail}`,
    headers
  );
  return res.data;
}
