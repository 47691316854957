import {
  Chip,
  IconButton,
  ListItemButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BIBrown,
  BIGreen,
  BIGrey,
  BILightGreen,
  BILightGrey,
  BILightOrange,
  BILightRed,
  BIRed,
} from "../../../assets/buildidColors";
import moment from "moment/moment";
import {
  fetchClientSafetyObservations,
  fetchClientSiteActivityActions,
  fetchClientSiteActivityNotes,
} from "../../../api/ClientApi";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import Tab from "@mui/material/Tab";
import SiteActivityReportModal from "./SiteActivityReportAddModal";
import { AddSafetyObservationModal } from "../clientSafetyObservations/AddSafetyObservationModal";
import AddSARActionsModal from "./AddSARActionsModal";
import AddSARNotes from "./AddSARNotes";
import { ViewObservationModal } from "../clientSafetyObservations/ViewObservationModal";
import { Close } from "@material-ui/icons";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PictureViewer from "../../../components/PictureViewer";

function SiteActivityViewModal(props) {
  const { open, onClose, data, clientJobsData, reLoadData } = props;
  const [editModal, setEditModel] = useState(false);
  const [dueDate, setDueDate] = useState(0);
  const [openAddSafetyObs, setOpenAddSafetyObs] = useState(false);
  const [value, setValue] = React.useState("1");
  const [notesData, setNotesData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [actionModal, setActionModal] = useState(false);
  const [observationData, setSafetyObservationData] = useState([]);
  const [addNotesMoadal, setNotesAddModal] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [observationLoading, setObservationLoading] = useState(false);

  useEffect(() => {
    let todaydate = moment();
    let compareDate = moment(data?.dateCreated);
    let diff = todaydate.diff(compareDate, "hours");
    setDueDate(diff);

    fetchClientNotes();
    fetchActions();
    getObservation();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchClientNotes = async () => {
    try {
      setNotesLoading(true);
      let payload = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.UserId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ SARId: data?.id }],
      };
      const res = await fetchClientSiteActivityNotes(payload);
      setNotesData(res.data);
      setNotesLoading(false);
    } catch (error) {
      console.log("ERROR ::", error);
      setNotesLoading(false);
    }
  };

  const getObservation = async () => {
    try {
      setObservationLoading(true);

      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SortList: [
          {
            FieldName: "Id",
            Direction: "DESC",
          },
        ],
        SearchList: [{ SARId: data?.id }],
      };
      const safetyObservationsRes = await fetchClientSafetyObservations(obj);
      setSafetyObservationData(safetyObservationsRes.data);
      setObservationLoading(false);
    } catch (err) {
      console.log("CLIENT SAFETY OBSERVATION ERROR ", err);
      setObservationLoading(false);
    }
  };

  const fetchActions = async () => {
    try {
      setActionLoading(true);
      let payload = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.UserId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ SARId: data?.id }],
      };
      const res = await fetchClientSiteActivityActions(payload);
      setActivityData(res.data);
      setActionLoading(false);
    } catch (error) {
      console.log("ERROR ::", error);
      setActionLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              width: "700px",
            }}
            className="modal-card"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                {dueDate <= 48 ? (
                  <IconButton
                    style={{
                      height: "35px",
                      width: "35px",
                    }}
                    onClick={() => {
                      setEditModel(true);
                    }}
                  >
                    <EditIcon
                      style={{
                        color: localStorage?.clientAccentColor,
                      }}
                    />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
              <IconButton
                color="primary"
                onClick={() => onClose()}
                style={{ padding: "0px" }}
              >
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography
                  style={{
                    color: BIGrey,
                  }}
                >
                  Job
                </Typography>
                <Typography>
                  {`${clientJobsData?.jobCode} - ${clientJobsData?.jobName}`}
                </Typography>
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                }}
              >
                <div>
                  <Typography
                    style={{
                      color: BIGrey,
                    }}
                  >
                    Date
                  </Typography>
                  <Typography>
                    {moment(data?.date).format("MMMM DD, YYYY")}
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      color: BIGrey,
                    }}
                  >
                    Time
                  </Typography>

                  <Typography>{moment(data?.time).format("LT")}</Typography>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {data?.weather}
                </Typography>
              </div>
            </div>
            <div>
              <TabContext
                // sx={{
                //   width: "100%",
                // }}
                value={value}
              >
                <TabList variant="fullWidth" onChange={handleChange}>
                  <Tab label={`Notes (${data?.nrOfSARNotes})`} value="1" />

                  <Tab
                    label={`Observation (${data?.nrOfObservations})`}
                    value="2"
                  />
                  <Tab label={`Actions (${data?.nrOfSARActions})`} value="3" />
                </TabList>
                <TabPanel value="1">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      maxHeight: "300px",
                      padding: "5px",
                      overflow: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      {dueDate <= 48 ? (
                        <Typography
                          onClick={() => setNotesAddModal(true)}
                          style={{
                            color: localStorage.clientAccentColor,
                            cursor: "pointer",
                          }}
                        >
                          Add Notes
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                    {notesData.length == 0 && !notesLoading && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: BIGrey,
                          }}
                        >
                          This Report does not have any notes.
                        </Typography>
                      </div>
                    )}
                    {notesLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {notesData?.map((item) => {
                          return <Notes data={item} />;
                        })}
                      </>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "5px",
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      {dueDate <= 48 ? (
                        <Typography
                          onClick={() => setOpenAddSafetyObs(true)}
                          style={{
                            color: localStorage.clientAccentColor,
                            cursor: "pointer",
                          }}
                        >
                          Add Observation
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>

                    {observationData.length == 0 && !observationLoading && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: BIGrey,
                          }}
                        >
                          This Report does not have any Observation.
                        </Typography>
                      </div>
                    )}
                    {observationLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {observationData.map((item) => (
                          <Observation data={item} />
                        ))}
                      </>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "5px",
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      {dueDate <= 48 ? (
                        <Typography
                          onClick={() => {
                            setActionModal(true);
                          }}
                          style={{
                            color: localStorage.clientAccentColor,
                            cursor: "pointer",
                          }}
                        >
                          Add Action
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                    {activityData.length == 0 && !actionLoading && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: BIGrey,
                          }}
                        >
                          This Report does not have any Actions.
                        </Typography>
                      </div>
                    )}

                    {actionLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {activityData.map((item) => (
                          <Action data={item} />
                        ))}
                      </>
                    )}
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <AddSafetyObservationModal
            isOpen={openAddSafetyObs}
            onClose={() => setOpenAddSafetyObs(false)}
            from={"SAR"}
            data={data}
            reLoadData={() => getObservation()}
            clientJobsData={clientJobsData}
          />
          <SiteActivityReportModal
            reLoadData={() => reLoadData()}
            open={editModal}
            onClose={() => setEditModel(false)}
            data={clientJobsData}
            clientJobsData={data}
            edit={true}
          />
          <AddSARNotes
            reLoadData={() => fetchClientNotes()}
            open={addNotesMoadal}
            onClose={() => setNotesAddModal(false)}
            data={clientJobsData}
            clientJobsData={data}
            edit={true}
          />
          <AddSARActionsModal
            reLoadData={() => fetchActions()}
            open={actionModal}
            data={data}
            onClose={() => setActionModal(false)}
          />
        </div>
      </Modal>
    </>
  );
}

export default SiteActivityViewModal;

const Notes = ({ data }) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ListItemButton
        onClick={() => setOpen(true)}
        style={{
          borderRadius: "10px",
          border: "1px solid grey",
          backgroundColor: "White",
        }}
      >
        <div
          style={{
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              marginBottom: "10px",
            }}
          >
            {data?.description}
          </Typography>
          <span
            style={{
              color: BIGrey,
              backgroundColor: "lightgray",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            {data?.noteTypeName}
          </span>
        </div>
      </ListItemButton>

      <ModalNotesiew open={open} onClose={onClose} data={data} />
    </>
  );
};
const Observation = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItemButton
        onClick={() => setOpen(true)}
        style={{
          borderRadius: "10px",
          border: "1px solid grey",
          backgroundColor: "White",
        }}
      >
        <div
          style={{
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              marginBottom: "10px",
            }}
          >
            {data?.description}
          </Typography>

          {data.udFieldsList.map((item) => {
            if (item.value == "true") {
              return (
                <span
                  style={{
                    color: BIGrey,
                    backgroundColor: "lightgray",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {item.label}
                </span>
              );
            }
          })}
        </div>
      </ListItemButton>
      <ViewObservationModal
        isOpen={open}
        from="SAR"
        onClose={() => setOpen(false)}
        individualSafetyObservation={data}
      />
    </>
  );
};
const Action = ({ data }) => {
  const history = useHistory();
  return (
    <>
      <ListItemButton
        onClick={() => {
          history.push(`/client-actions-details/${data?.id}`);
        }}
        style={{
          borderRadius: "10px",
          border: "1px solid grey",
          backgroundColor: "White",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <PersonIcon
                style={{
                  color: localStorage?.clientAccentColor,
                }}
              />
              <Typography
                style={{
                  marginBottom: "10px",
                }}
              >
                {data?.employeeName}
              </Typography>
            </div>
            <Typography
              style={{
                color: "grey",
              }}
            >{`Due ${moment(data?.dueDate).format(
              " MMMM DD, YYYY"
            )}`}</Typography>
          </div>
          <Typography
            style={{
              marginBottom: "10px",
            }}
          >
            {data?.title}
          </Typography>
          <Typography
            style={{
              color: "grey",
            }}
          >
            {data?.description}
          </Typography>
          <div
            style={{
              padding: "5px",
              borderRadius: "10px",
            }}
          >
            <Chip
              label={data?.status}
              style={
                data?.status === "In progress"
                  ? { backgroundColor: BILightGrey, color: BIGrey }
                  : data?.status === "Over Due"
                  ? { backgroundColor: BILightRed, color: BIRed }
                  : data?.status === "Not Started"
                  ? { backgroundColor: BILightGrey, color: BIGrey }
                  : data?.status === "On Hold"
                  ? { backgroundColor: BILightOrange, color: BIBrown }
                  : { backgroundColor: BILightGreen, color: BIGreen }
              }
            />
          </div>
        </div>
      </ListItemButton>
    </>
  );
};

const ModalNotesiew = ({ open, onClose, data }) => {
  const [openPictureViewer, setOpenPictureViewer] = useState(false);
  const [pictureIndex, setPictureIndex] = useState(0);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-center">
        <div
          style={{
            padding: "20px",
            width: "500px",
          }}
          className="modal-card"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              color="primary"
              onClick={() => onClose()}
              style={{ padding: "0px" }}
            >
              <Close />
            </IconButton>
          </div>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <Typography>{`${data?.description}`}</Typography>
          </div>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <span
              style={{
                color: BIGrey,
                backgroundColor: "lightgray",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {data?.noteTypeName}
            </span>

            <div
              style={{
                marginTop: "10px",
                maxWidth: "100%",
                display: "flex",
                gap: "10px",
                overflowX: "auto",
              }}
            >
              {data?.pictureList.map((item, INDEX) => {
                return (
                  <img
                    onClick={() => {
                      setOpenPictureViewer(true);
                      setPictureIndex(INDEX);
                    }}
                    src={item?.fileUrl}
                    alt="img"
                    style={{
                      borderRadius: "10px",
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                );
              })}
              <Typography> {data?.weather}</Typography>
            </div>
          </div>
        </div>
        <PictureViewer
          isOpen={openPictureViewer}
          onClose={() => setOpenPictureViewer(false)}
          pictures={data?.pictureList.map((item) => ({
            src: item.fileUrl,
            alt: "",
          }))}
          viewerIndex={pictureIndex}
        />
      </div>
    </Modal>
  );
};
