import React, { useEffect, useState } from "react";
import { ClientHeader } from "../ClientHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Button,
  IconButton,
  ListItemButton,
  fabClasses,
} from "@mui/material";
import moment from "moment/moment";
import { BIBlack, BIGrey } from "../../../assets/buildidColors";
import SiteActivityReportModal from "./SiteActivityReportAddModal";
import {
  fetchClientEmployeesJobs,
  fetchClientSAR2,
} from "../../../api/ClientApi";
import { Hidden } from "@material-ui/core";
import SiteActivityViewModal from "./SiteActivityViewModal";
import Loader from "../../../components/Loader";

function SiteActivityReports() {
  const [clientJobs, setClientJobs] = useState([]);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [isLoadingSAR, setIsLoadingSAR] = useState(false);

  useEffect(() => {
    fetchJobs();
  }, [clientSearchTerm]);
  const fetchJobs = async () => {
    setIsLoadingSAR(true);
    try {
      const payload = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: clientSearchTerm || "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            ClientEmployeeId: localStorage?.clientEmployeeId,
            ClientId: localStorage.clientId,
          },
        ],
      };

      let res = await fetchClientEmployeesJobs(payload);

      const payload1 = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: localStorage.UserId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            // ClientEmployeeJobId: clientJobsData?.id,
          },
        ],
        // SortList: [{ FieldName: "date", Direction: "DESC" }],
      };
      let secondArr = await fetchClientSAR2(payload1);

      // Step 1: Sort the second array by combining `date` and `time`
      const sortedSecondArray = secondArr.data.sort((a, b) => {
        const dateTimeA = new Date(
          `${a.date.split("T")[0]}T${
            new Date(a.time).toISOString().split("T")[1]
          }`
        );
        const dateTimeB = new Date(
          `${b.date.split("T")[0]}T${
            new Date(b.time).toISOString().split("T")[1]
          }`
        );

        return dateTimeB - dateTimeA; // Sort by descending date and time
      });

      // Step 2: Sort the first array based on the sorted second array
      const sortedFirstArray = res.data.sort((a, b) => {
        const jobA = sortedSecondArray.find(
          (job) => job.clientEmployeeJobId === a.id
        );
        const jobB = sortedSecondArray.find(
          (job) => job.clientEmployeeJobId === b.id
        );

        if (jobA && jobB) {
          const dateTimeA = new Date(
            `${jobA.date.split("T")[0]}T${
              new Date(jobA.time).toISOString().split("T")[1]
            }`
          );
          const dateTimeB = new Date(
            `${jobB.date.split("T")[0]}T${
              new Date(jobB.time).toISOString().split("T")[1]
            }`
          );
          return dateTimeB - dateTimeA; // Sort by the combined date and time
        }
        return 0;
      });

      setClientJobs(sortedFirstArray);
      setIsLoadingSAR(false);
    } catch (err) {
      console.log("ERROR SAR : ", err);
      setIsLoadingSAR(false);
    }
  };
  return (
    <>
      <ClientHeader
        screenName="Site Activity Report"
        setClientSearchTerm={setClientSearchTerm}
        clientSearchTerm={clientSearchTerm}
      />
      <div
        style={{
          paddingTop: "20px",
          minHeight: "100vh",
        }}
        className="page"
      >
        {isLoadingSAR ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Loader />
          </div>
        ) : clientJobs.length > 0 ? (
          <>
            {clientJobs?.map((item) => (
              <AccordianComponent clientJobsData={item} fetchJobs={fetchJobs} />
            ))}{" "}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Typography variant="p">
              When Thermal Systems adds you to a Job, they will appear here.
            </Typography>
          </div>
        )}
      </div>
    </>
  );
}

export default SiteActivityReports;

const AccordianComponent = ({ clientJobsData, fetchJobs }) => {
  const [addModal, setAddModal] = useState(false);
  const [seemore, setSeemore] = useState(3);
  const [siteActivityData, setSiteActivityData] = useState([]);

  useEffect(() => {
    fetchSAR();
  }, []);

  const fetchSAR = async () => {
    try {
      const payload = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: localStorage.UserId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            ClientEmployeeJobId: clientJobsData?.id,
          },
        ],
        SortList: [{ FieldName: "date", Direction: "DESC" }],
      };
      let res = await fetchClientSAR2(payload);
      setSiteActivityData(res?.data);
    } catch (error) {
      console.log("ERROR ::", error);
    }
  };

  useEffect(() => {
    console.log("clientjobdata : ", clientJobsData);
  }, [clientJobsData]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div
          style={{
            width: " 100%",
            display: "flex",
            justifyContent: "space-between",
            marginRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <LocationOnIcon
              style={{
                color: localStorage.clientAccentColor,
              }}
            />
            <Typography>
              {`${clientJobsData.jobCode} - ${clientJobsData.jobName}`}
            </Typography>
          </div>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setAddModal(true);
            }}
            style={{
              height: "25px",
              width: "25px",
            }}
          >
            <AddIcon
              style={{
                color: localStorage.clientAccentColor,
              }}
            />
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {siteActivityData.length == 0 ? (
            <Typography
              textAlign={"center"}
              style={{
                color: BIGrey,
              }}
            >
              No report available
            </Typography>
          ) : (
            <>
              {siteActivityData.slice(0, seemore).map((item) => {
                return (
                  <AccordianDetails
                    reLoadData={() => fetchSAR()}
                    clientJobData={clientJobsData}
                    data={item}
                  />
                );
              })}
              {siteActivityData.length > 3 && (
                <Button
                  style={{
                    backgroundColor: "lightgray",
                    boxShadow: "none",
                    color: "black",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    seemore == 3
                      ? setSeemore(siteActivityData.length)
                      : setSeemore(3)
                  }
                  fullWidth
                  variant="contained"
                >
                  {seemore == 3
                    ? `See More ${siteActivityData.length - 3}`
                    : "See Less"}
                </Button>
              )}
            </>
          )}
        </div>
      </AccordionDetails>
      <div>
        <SiteActivityReportModal
          reLoadData={() => fetchJobs()}
          data={clientJobsData}
          open={addModal}
          // fetchJobs={fetchJobs}
          onClose={() => setAddModal((prev) => !prev)}
        />
      </div>
    </Accordion>
  );
};

const AccordianDetails = ({ data, clientJobData, reLoadData }) => {
  const [viewModel, setViewModal] = useState(false);
  return (
    <div>
      <ListItemButton
        onClick={() => {
          setViewModal(true);
        }}
        style={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          padding: "10px 15px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography>{moment(data.date).format("MMMM DD, YYYY")}</Typography>
        <Typography
          style={{
            color: BIGrey,
          }}
        >
          {moment(data.time).format("hh:mm a")}
        </Typography>
      </ListItemButton>
      <SiteActivityViewModal
        reLoadData={() => reLoadData()}
        clientJobsData={clientJobData}
        open={viewModel}
        data={data}
        onClose={() => setViewModal(false)}
      />
    </div>
  );
};
