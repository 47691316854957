import React, { useEffect, useState } from 'react'
import { ClientHeader } from '../ClientHeader'
import { Avatar, Chip, Stack, Tab, Tabs, Typography } from '@mui/material'
import { getClientCommunicatorDetails } from '../../../api/ClientApi';
import { BIGrey } from '../../../assets/buildidColors';
import moment from 'moment';
import Loader from '../../../components/Loader';
import AvtarImage from "../../../assets/images/AvatarImage.jpeg";
import CommunicatorModal from './CommunicatorModal';


export const ClientCommunicator = () => {

    const [activeTab, setActiveTab] = useState("All");
    const [searchTerm, setSearchTerm] = useState("");
    const [communicatorList, setCommunicatorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedCommunicatorDetails, setSelectedCommunicatorDetails] = useState(null);

    const tabsArray = ["All", "To Acknowledge", "Acknowledged"];

    useEffect(() => {
        fetchClientCommunicator(1, searchTerm);
    }, [searchTerm])

    const fetchClientCommunicator = async (activeTab, searchTerm) => {
        try {
            await getClientCommunicatorDetails(
                localStorage?.clientEmployeeId, activeTab, searchTerm
            ).then((res) => {
                let data = res ?? [];
                setCommunicatorList(data);
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                console.log("Error while getting client communicator list :: ", error);
            })
        } catch (error) {
            setLoading(false);
            console.log("Error while getting client communicator list :: ", error);
        }
        setLoading(false);
    }

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
        setLoading(true);
        let type = newTab === "All" ? 1 : newTab === "To Acknowledge" ? 3 : 2;
        fetchClientCommunicator(type, searchTerm);
    };



    return (
        <>
            <ClientHeader screenName={"Communicator"} setClientSearchTerm={setSearchTerm} />
            <div className="page">
                <div className="page-content">
                    <div className="flex-container-vertical" style={{ flex: 2 }}>
                        <div style={{ margin: "15px" }}>
                            <div
                                className="flex-container-horizontal"
                                style={{ justifyContent: "space-between" }}>
                                <Tabs
                                    sx={{
                                        ".Mui-selected": {
                                            color: localStorage?.clientAccentColor,
                                        }
                                    }}
                                    TabIndicatorProps={{ sx: { backgroundColor: localStorage?.clientAccentColor } }}
                                    value={activeTab}
                                    onChange={handleTabChange}>
                                    {tabsArray.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            value={tab}
                                            label={tab}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                                marginRight: "20px",
                                            }}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </div>
                    </div>

                    <div className='communicator_list_continer'>

                        {
                            !loading ? communicatorList?.map((item, index) => (
                                <div className='clc_item' key={index} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedCommunicatorDetails(item);
                                        setShowModal(true);
                                    }}
                                >
                                    <Stack>
                                        <Stack direction={'row'} sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }} spacing={1}>
                                            <Avatar alt="" src={item?.profilePictureUrl != '' ? item?.profilePictureUrl : AvtarImage} />

                                            <Stack direction={'row'} spacing={2}
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                <Typography style={{ fontWeight: item.acknowledged == 0 ? '600' : '400' }}>Mihai Velicu</Typography>
                                                <Typography style={{ color: item.acknowledged == 0 ? 'black' : BIGrey, fontWeight: item.acknowledged == 0 ? '500' : '400' }}>
                                                    {moment(item.date).format("MMMM D, YYYY")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction={'row'} spacing={1}
                                            sx={{
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Chip label={item?.clientCommunicatorCategory ?? ""} />
                                            <Typography style={{ fontWeight: item.acknowledged == 0 ? '700' : '400' }}>{` ${item.subject} `}</Typography>
                                        </Stack>
                                        <Typography className='clc_body_ellipsis'>
                                            {item.body}
                                        </Typography>
                                    </Stack>
                                </div>
                            ))
                                :
                                (
                                    <>
                                        <Loader />
                                    </>
                                )
                        }
                    </div>


                </div>
            </div>

            {
                showModal && (
                    <CommunicatorModal
                        open={showModal}
                        handleClose={() => {
                            setSelectedCommunicatorDetails(null);
                            setShowModal(false);
                        }}
                        selectedCommunicatorDetails={selectedCommunicatorDetails}
                        fetchClientCommunicator={() => fetchClientCommunicator(activeTab === "All" ? 1 : activeTab === "To Acknowledge" ? 3 : 2, searchTerm)}
                    />
                )
            }

        </>
    )
}
