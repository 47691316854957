import React, { useState, useEffect, useRef } from 'react'
import Modal from '@mui/material/Modal';
import { IconButton, Box, TextField, InputAdornment, Stack, Card, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { fetchClientJobs } from '../../../api/ClientApi';
import { Close as CloseIcon } from "@material-ui/icons";

const ClientJobListModal = ({ show, handleClose, selectedJobId, onJobSelect }) => {


    const clientId = localStorage.getItem("clientId");
    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? 'black';

    const debounceTimeout = useRef(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [jobList, setJobList] = useState([]);


    useEffect(() => {
        getJobs(searchTerm);
    }, []);

    const getJobs = async (searchTerm) => {
        try {
            const clientJobObj = {
                PageNr: 1,
                FullSearch: searchTerm,
                UserId: Number(localStorage?.userId),
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [
                    {
                        ClientId: Number(clientId),
                    },
                ],
            };
            const res = await fetchClientJobs(clientJobObj);
            const resData = res?.data ?? []
            console.log("res ===>", resData)

            const options = [];

            resData.forEach((item) => {
                const option = {
                    value: `${item.jobCode} - ${item.name}`,
                    label: `${item.jobCode} - ${item.name}`,
                    id: item.id,
                    jobCode: item.jobCode,
                    jobName: item.name,
                };
                options.push(option);
            });
            setJobList(options);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("ERROR WHILE FETCHING JOB IN SAFETY OBSERVATION ", err);
        }
    };


    const getJobListItemBorderColor = (item) => {
        return ` 1px ${selectedJobId === item?.id ? clientAccentColor : "grey"} solid`
    }

    const onJobItemClick = (item) => {
        onJobSelect(item);
        handleClose();
    }

    if (!show) {
        return null;
    }

    return (
        <>

            <Modal
                open={show}
                onClose={handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Jobs</Typography>
                            <TextField
                                value={""}
                                placeholder={"Search for a Job"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px", maxHeight: "450px", overflow: "scroll" }}>
                            {
                                !loading
                                    ?
                                    (
                                        <>
                                            {
                                                jobList?.length > 0 ?
                                                    (jobList?.map((item, index) => (
                                                        <div key={index}
                                                            style={{ border: getJobListItemBorderColor(item), margin: '10px', padding: '10px', cursor: "pointer" }}
                                                            onClick={() => { onJobItemClick(item) }}
                                                        >
                                                            <Typography>
                                                                {item.value ?? ""}
                                                            </Typography>
                                                        </div>
                                                    )))
                                                    :
                                                    (
                                                        <>
                                                            No Job Found
                                                        </>
                                                    )
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            Loading....
                                        </>
                                    )
                            }
                        </Card>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ClientJobListModal