import { Button, IconButton, InputAdornment, LinearProgress, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import Employeeslist from "./Employeeslist";
import ClientJobListModal from "./ClientJobListModal";
import GeneralModal from "./GeneralModal";
import ActivitiesModal from "./ActivitiesModal";
import ClientJobActivities from "./ClientJobActivities";
import Reviews from "./Reviews.jsx"

export default function CrewModal(props) {

    const [showJobListModal, setShowJobListModal] = useState(false);
    const [showEmployeelist, setShowemployeelist] = useState(false);
    const [showJobActivities, SetShowjobactivities] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(0);
    const [jobName, setJobName] = useState("");
    const [selectedJobActivities, setSelectJobActivities] = useState([]);
    const [currentStep, setCurrentstep] = useState(1);

    const [emplist, setEmplist] = useState([]);
    const [selectedEmp,setSelectedemp] = useState([]);

    const onClose = () => {

    }

    const onNextflow = () => {
        console.log("current step", currentStep + 1)
        setCurrentstep(currentStep + 1)
    }

    return (
        <>
            <Modal
                open={props.openCrew}
                onClose={props.onClose}>
                <div className="modal-center">
                    {console.log("current step",currentStep)}
                    <div className="modal-card crew-modal" style={{ width: "600px",height: currentStep === 2 || currentStep === 3 && "650px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack textAlign={"center"} gap={1} marginBottom={2}>
                            <Typography fontSize={"17px"} fontWeight={600}>Step 1 of 3</Typography>
                            <LinearProgress variant="determinate" value={20} />
                        </Stack>
                        {currentStep === 1 && (<GeneralModal showEmployeelist={showEmployeelist} setSelectedemp={setSelectedemp} selectedEmp={selectedEmp} jobName={jobName} setShowJobListModal={setShowJobListModal} setShowemployeelist={setShowemployeelist} />)}
                        {currentStep === 2 && (<ActivitiesModal selectedJobActivities={selectedJobActivities} SetShowjobactivities={SetShowjobactivities} />)}
                        {currentStep === 3 && (<Reviews />)}
                        <Button fullWidth style={{marginTop:"10px"}} color="primary" disabled={false} variant="contained" onClick={onNextflow}>Next</Button>
                    </div>
                </div>
            </Modal>
            <ClientJobListModal
                show={showJobListModal}
                handleClose={() => { setShowJobListModal(false) }}
                onJobSelect={(item) => {
                    setSelectedJobId(item?.id ?? 0);
                    setJobName(item?.value ?? "");
                    setShowJobListModal(false);
                }}
                selectedJobId={selectedJobId}
            />
            <Employeeslist setEmplist={setEmplist} setSelectedemp={setSelectedemp} emplist={emplist} showEmployeelist={showEmployeelist} handleClose={() => { setShowemployeelist(false) }} />
            <ClientJobActivities
                setSelectJobActivities={setSelectJobActivities}
                selectedJobActivities={selectedJobActivities}
                selectedJobId={selectedJobId}
                showJobActivities={showJobActivities}
                handleClose={() => { SetShowjobactivities(false) }} 
             />
        </>
    )

}