import React, { useState, useEffect, useRef } from 'react'
import {
    Dialog, DialogTitle, DialogContent,
    Button, Select, IconButton, MenuItem, FormControl,
    Stack, InputLabel, ListItemButton, ListItemText, DialogActions, ImageList, ImageListItem,
    Divider, TextField, Typography, InputAdornment, Checkbox, Avatar, ListItem, CircularProgress
} from '@mui/material'
import { Close as CloseIcon, } from "@material-ui/icons";
import { deleteUserListingPictures, saveUserListings, saveUserListingPictures } from '../../api/BuySellApi';
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg"
import { BIBlue, BIGrey, BIWhite } from '../../assets/buildidColors';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import "../../assets/css/buysell.css"
import { fetchUserWorkHistory } from '../../api/UserApi';
import PictureViewer from '../../components/PictureViewer';


const AddListing = (props) => {

    const [pictures, setPictures] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [forTrade, setForTrade] = useState(false);
    const [selectedSiteDetails, setSelectedSiteDetails] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
    const [pictureViewIndex, setPictureViewIndex] = useState(0);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (props?.editListItemDetails) {
            setTitle(props?.editListItemDetails?.title ?? "");
            setPrice(props?.editListItemDetails?.price ?? "");
            setForTrade(props?.editListItemDetails?.forTrade ?? false);
            setDescription(props?.editListItemDetails?.description ?? "");
            setPictures(props?.editListItemDetails?.pictureList ?? []);
        }
    }, [props?.editListItemDetails])

    useEffect(() => {
        getUserSites()
    }, [])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxWidth: ITEM_HEIGHT + ITEM_PADDING_TOP,
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: "auto",
            },
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        variant: "menu",
    };

    const removeDuplicates = (originalArray, prop) => {
        let newArray = [];
        let lookupObject = {};
        for (let i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    const getUserSites = async () => {
        setLoading(true)
        try {
            const workHistoryObj = {
                PageNr: 1,
                FullSearch: "",
                IncludeRecordNr: false,
                NrOfRecPerPage: 0,
                SearchList: [{ UserId: Number(localStorage.userId) }]
            }
            let userSites = await fetchUserWorkHistory(workHistoryObj);
            userSites = removeDuplicates(userSites, 'siteId');
            const finalCompSites = [...userSites];
            // FOR EXISTING DATA IS PRESENT THEN --->
            if (props.editListItemDetails) {
                const finalSiteData = userSites.filter((x) => x.siteId == props?.editListItemDetails?.siteId);
                setSelectedSiteDetails(finalSiteData?.[0] ?? null);
            }
            setExperienceData(finalCompSites ?? []);
            setLoading(false)
        } catch (e) {
            console.log("ERROR : [--getUserSites--] :", e);
            setLoading(false)
        }
    }

    const onClose = () => {
        setTitle("");
        setPictures([]);
        setExperienceData([]);
        setTitle("");
        setPrice('');
        setDescription("");
        setForTrade(false);
        setSelectedSiteDetails(null);
        setDeletedImages([]);
        setBtnLoading(false);
        return props?.onClose();
    }

    const handleFileSelect = (event) => {
        const files = event.target.files;
        let allFilesValid = true;
        if (files?.length > 0) {
            for (let i = 0; i < files.length; i++) {
                if (!files[i].type.startsWith('image/')) {
                    allFilesValid = false;
                    break;
                }
            }
        }
        if (!allFilesValid) return alert('Please select valid image files (JPEG, PNG, GIF, etc.).');
        let images = [];
        for (let i = 0; i < files.length; i++) {
            images.push(files[i]);
        }
        setPictures([...pictures, ...images]);
        event.target.value = null;
    }

    const closeCurrentimage = (item, i) => {
        if (item?.id) {
            setDeletedImages([...deletedImages, item])
        }
        let updatePicturesList = pictures.filter((item, index) => index !== i);
        setPictures(updatePicturesList);
    }


    const onPriceChange = (val) => {
        try {
            if (val.startsWith('0') && val.length > 1) {
                val = val.replace(/^0+/, '0');
            }
            const numericValue = val.replace(/[^0-9]/g, '');
            setPrice(numericValue)
        } catch (error) {
            console.log("ERROR::", error);
        }
    }

    const handleCheckboxChange = () => {
        setPrice("")
        setForTrade(!forTrade);
    }

    const onSiteCliked = (item) => {
        setSelectedSiteDetails(item)
    }

    const isPublishBtnDisable = () => {
        return title.trim() === "" || selectedSiteDetails === null || (price === '' && !forTrade)
    }




    const onPublish = async () => {
        try {
            setBtnLoading(true);
            if (deletedImages.length !== 0) {
                deletedImages.map(async (item) => {
                    const obj = {
                        UserId: Number(localStorage.userId),
                        ReturnRecordId: false,
                        ReturnRecordError: true,
                        SoftDelete: true,
                        DeleteList: [
                            {
                                Id: item.id,
                                ModifiedBy: Number(localStorage.userId)
                            }
                        ]
                    };
                    await deleteUserListingPictures(obj);
                })
            }

            const listingObject = {
                Id: props?.editListItemDetails?.id ?? 0,
                UserId: Number(localStorage.userId),
                Title: title,
                Price: forTrade ? 0 : price,
                ForTrade: forTrade,
                Description: description,
                SiteId: selectedSiteDetails?.siteId,
                ModifiedBy: Number(localStorage.userId)
            }
            const res = await saveUserListings(listingObject);

            const newPictures = pictures.filter((pic) => !pic.id);
            const promises = newPictures.map(async (pic) => {
                const formData = new FormData();
                formData.append('Id', 0);
                formData.append('BuySellUserItemId', res);
                formData.append('Name', pic);
                formData.append('Main', true);
                formData.append('ModifiedBy', Number(localStorage.userId));
                return saveUserListingPictures(formData);
            });

            // Wait for all images to save
            await Promise.all(promises);
            props?.getUpdatedData();
            setBtnLoading(false);
            onClose();
        } catch (err) {
            setBtnLoading(false);
            console.log("Error :: ", err)
        }

    }


    const onImagePreview = (index) => {
        setPictureViewerOpen(true);
        setPictureViewIndex(index);
    }


    return (
        <>
            <>
                <Dialog
                    open={props?.open}
                    onClose={onClose}
                    fullWidth
                    maxWidth="sm"
                    scroll={"paper"}
                >
                    <DialogTitle>
                        <div className="modal-card-header-buttons" style={{ alignItems: 'center' }}>
                            <h2> {props?.editListItemDetails ? "Edit" : "Add"} Listing</h2>
                            <IconButton
                                color="primary"
                                onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: 0 }} >
                        <Stack>
                            <div className="main">
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileSelect}
                                    accept="image/*"
                                    multiple
                                />
                                {pictures.length > 0 ? (
                                    <ImageList sx={{ width: "auto", height: "auto", gap: '15px !important' }} cols={5} rowHeight={164} className="buysell-pic-list" >
                                        {
                                            pictures.map((item, index) => (
                                                <ImageListItem className="buysell-pic-item" key={index}
                                                    onClick={() => {
                                                        onImagePreview(index)
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <img
                                                        src={item?.id ? item?.name : URL.createObjectURL(item)}
                                                        loading="lazy"
                                                        className="buysell-img"
                                                    />
                                                    <div className="buysell-close-icon" onClick={(e) => {
                                                        e.stopPropagation();
                                                        closeCurrentimage(item, index)
                                                    }}>
                                                        <CloseIcon className="buysell-icon-cl" />
                                                    </div>
                                                </ImageListItem>
                                            ))
                                        }
                                        <ImageListItem className="buysell-pic-item">
                                            <div className="buysell-add-picture buysell-addpic-inimagelist" onClick={() => { fileInputRef.current.click() }}>
                                                <InsertPhotoIcon style={{ height: "18px" }} />
                                                <span className="addpicture-text">ADD PICTURES</span>
                                            </div>
                                        </ImageListItem>
                                    </ImageList>
                                ) : (
                                    <div className="buysell-add-picture" onClick={() => { fileInputRef.current.click() }}>
                                        <InsertPhotoIcon /><span className="addpicture-text">ADD PICTURES</span>
                                    </div>
                                )}
                            </div>
                            <TextField
                                size="small"
                                type="text"
                                variant="outlined"
                                label="Title"
                                fullWidth
                                style={{ marginBottom: "14px" }}
                                value={title}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setTitle(value.charAt(0).toUpperCase() + value.slice(1))
                                }}
                            />
                            <TextField
                                size="small"
                                type="text"
                                disabled={forTrade ? true : false}
                                variant="outlined"
                                label="Price"
                                fullWidth
                                value={`${price}`}
                                onChange={(e) => { onPriceChange(e.target.value) }}
                                InputProps={{
                                    startAdornment: !forTrade && price?.toString()?.trim().length !== 0 && <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            <Stack direction={'row'} alignItems={"center"}>
                                <Checkbox checked={forTrade} onChange={handleCheckboxChange} name="Something_Else" />
                                <Typography>
                                    For Trade
                                </Typography>
                            </Stack>
                            <TextField
                                size="small"
                                type="text"
                                variant="outlined"
                                label="Description (optional)"
                                fullWidth
                                style={{ margin: "10px 0px 14px" }}
                                value={description}
                                multiline
                                rows={4}
                                onChange={(e) => { setDescription(e.target.value) }}
                            />
                            <Divider />
                            <Stack>
                                <Typography style={{ margin: '10px 0px' }}>
                                    Site
                                </Typography>

                                {
                                    selectedSiteDetails
                                        ?
                                        <>
                                            <ListItem
                                                sx={{ width: '100%', bgcolor: BIWhite, borderRadius: '5px', height: '84px' }}
                                                secondaryAction={
                                                    <>
                                                        <IconButton
                                                            onClick={() => { setSelectedSiteDetails(null) }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                                disablePadding>
                                                <ListItemButton>
                                                    <Avatar
                                                        variant="rounded"
                                                        style={{ marginRight: "10px", width: '52px', height: '52px' }}
                                                        src={selectedSiteDetails?.mainPictureUrl !== "" ? selectedSiteDetails?.mainPictureUrl : defaultProjectImage}>
                                                    </Avatar>
                                                    <ListItemText
                                                        style={{ marginLeft: '10px' }}
                                                        primary={selectedSiteDetails?.siteName ?? ""}
                                                        secondary={
                                                            <>
                                                                {selectedSiteDetails?.address.trim() + ", "}{selectedSiteDetails?.city.trim() + ", "}{selectedSiteDetails?.province.trim() + ", "}{selectedSiteDetails?.postalCode.trim() + ", "}{selectedSiteDetails?.country.trim()}
                                                            </>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </>
                                        :
                                        <FormControl>
                                            <InputLabel id="mutiple-select-label">
                                                Choose Site
                                            </InputLabel>
                                            <Select
                                                labelId="mutiple-select-label"
                                                variant="outlined"
                                                value={""}
                                                label="Choose Site"
                                                onChange={() => { }}
                                                MenuProps={MenuProps}
                                                style={{ width: "100%" }}>
                                                {
                                                    loading ? (
                                                        <MenuItem value={""}>
                                                            <ListItemText
                                                                primary={"Loading Data"}
                                                                secondary={(
                                                                    <>
                                                                        Please wait..........
                                                                    </>
                                                                )}
                                                            />
                                                        </MenuItem>
                                                    ) :
                                                        experienceData?.length > 0 ?
                                                            (

                                                                experienceData.map((option) => {
                                                                    return (
                                                                        <MenuItem key={option.id} value={option.siteName} onClick={() => { onSiteCliked(option) }}>
                                                                            <img src={option?.mainPictureUrl !== "" ? option?.mainPictureUrl : defaultProjectImage} style={{ width: 40, height: 40, marginRight: 10 }} />
                                                                            <ListItemText
                                                                                primary={option.siteName}
                                                                                secondary={(
                                                                                    <>
                                                                                        {option?.address.trim() + ", "}{option?.city.trim() + ", "}{option?.province.trim() + ", "}{option?.postalCode.trim() + ", "}{option?.country}
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </MenuItem>
                                                                    );
                                                                })

                                                            )
                                                            :
                                                            (
                                                                <MenuItem value={""}>
                                                                    <ListItemText
                                                                        primary={"No Sites"}
                                                                        secondary={(
                                                                            <>
                                                                                You have not yet added a Site to your Profile yet.
                                                                            </>
                                                                        )}
                                                                    />
                                                                </MenuItem>
                                                            )
                                                }

                                            </Select>
                                        </FormControl>
                                }
                            </Stack>
                        </Stack>
                        <Divider />
                    </DialogContent>
                    <DialogActions>
                        {
                            !btnLoading
                                ?
                                <Button onClick={onPublish}
                                    disabled={isPublishBtnDisable()}
                                    style={{ backgroundColor: isPublishBtnDisable() ? BIGrey : BIBlue, color: 'white', width: '90%', margin: 'auto' }}>
                                    PUBLISH
                                </Button>
                                :
                                <Button
                                    style={{ backgroundColor: BIBlue, color: 'white', width: '90%', margin: 'auto' }}>
                                    <CircularProgress style={{ color: 'white', height: '26px', width: '26px' }} />
                                </Button>
                        }

                    </DialogActions>
                </Dialog>
            </>
            {
                pictureViewerOpen &&
                <PictureViewer
                    isOpen={pictureViewerOpen}
                    onClose={() => setPictureViewerOpen(false)}
                    pictures={pictures.map(pic => (
                        {
                            src: pic?.id ? pic?.name : URL.createObjectURL(pic),
                            alt: ""
                        }
                    ))}
                    viewerIndex={pictureViewIndex}
                />
            }
        </>
    )
}

export default AddListing