import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import {
  SiteIssues,
  fetchSites,
  getAddress,
  siteDetailsByType,
} from "../../api/SitesApi";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import mapMarkerBlue from "../../assets/images/map-marker-blue.png";
import { BIBlack, BIBlue } from "../../assets/buildidColors";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import TabOptions from "./TabOptions";
import SiteCard from "./SiteCard";
import Chooseasite from "../sites/Chooseasite";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AddProjectModal from "../../components/AddProjectModal";
import AddSiteModal from "./AddSiteModal";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FabOptions from "./FabOptions";
import Chooselocation from "./Chooselocation";
import unnamed from "../../assets/images/unnamed.png";
import ReportModal from "../../components/ReportModal";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import AddSiteNameModal from "./AddSiteNameModal";
import NearMeIcon from "@mui/icons-material/NearMe";
import Loader from "../../components/Loader";

const clusterOptions = {
  styles: [
    {
      textColor: BIBlack,
      textSize: "13",
      height: 56,
      width: 56,
      // Icon image file needs to be hosted somewhere
      // This is the default m2.png from Google Maps
      url: "https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/images/m2.png",
    },
  ],
};

const MapScreen = (props) => {
  const mapRef = useRef(null);
  const appLocation = useLocation();
  const history = useHistory();
  const [map, setMap] = useState();
  const [siteList, setSiteList] = useState([]);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [currentLocationMap, setCurrentLocationMap] = useState({
    lat: 0,
    lng: 0,
  });
  const [activeTab, setActiveTab] = useState("");
  const [showDropMarker, setShowDropMarker] = useState(false);
  const [activeSiteFilter, setActiveSiteFilter] = useState(false);
  const [showSiteCardModal, setShowSiteCardModal] = useState(false);
  const [selectedSiteDetails, setSelectedSiteDetails] = useState(null);
  const [siteListOfSameLocation, setSiteListOfSameLocation] = useState([]);
  const [showSiteOfSameLocation, setShowSiteOfSameLocation] = useState(false);
  const [showChooseOption, setShowChooseOption] = useState(false);
  const [addProjectObj, setAddProjectObj] = useState({});
  const [showAddOption, setShowAddOption] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [showSiteIssuesList, setShowSiteIssuesList] = useState(false);
  const [noLocationSiteList, setNoLocationSiteList] = useState([]);
  const [showFabOptions, setShowFabOptions] = useState(false);
  const [changeSiteFlag, setChangeSiteFlag] = useState(false);
  const [siteDetailsForAddProject, setSiteDetailsForAddProject] = useState({
    id: 0,
    name: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    latitude: 0,
    longitude: 0,
  });
  const [showChooselocation, setShowchooselocation] = useState(false);
  const [changeLocation, setChangeLocation] = useState(false);
  const [addNewSiteLocation, setAddNewSiteLocation] = useState(false);
  const [chooseLocation, setChooselocation] = useState({ lat: 0, lng: 0 });
  const [companyData, setCompanyData] = useState({});
  const [manufacturerData, setManufacturerData] = useState({});
  const [productData, setProductData] = useState({});
  const [selectedMapType, setSelectedMapType] = useState("default");
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
  const [siteInfo, setSiteInfo] = useState({});
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [mapTypeId, setMapTypeId] = useState(0);
  const [showAddNameModal, setShowAddNameModal] = useState(false);
  const [showCurrentDropMarker, setShowCurrentDropMarker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [siteAddressData, setSiteAddressData] = useState({});
  const mapTypes = [
    { id: 0, label: "Default" },
    { id: 1, label: "Hybrid" },
    { id: 2, label: "Satellite" },
    { id: 3, label: "Terrain" },
  ];
  const getSiteInfo = async () => {
    try {
      let siteRes = await fetchSites({
        SearchList: [
          {
            Id:
              appLocation?.state?.from == "dashboard-profile-post" &&
              showChooseOption == false
                ? appLocation?.state?.appRecordId
                : selectedSiteDetails?.id,
          },
        ],
      });
      setSiteInfo(siteRes?.data[0] ?? {});
      getSitesofCurrentlocation(
        location?.lat,
        location?.lng,
        activeTab,
        activeSiteFilter
      );
    } catch (error) {
      console.log("Error while getting site info :: ", error);
    }
  };
  //  end

  useEffect(() => {
    if (typeof map !== "undefined") {
      if (
        appLocation?.state?.from == "dashboard-profile-post" &&
        showChooseOption == false
      ) {
        getLocationForDashboard();
      } else {
        getLocation();
      }
    }
  }, [map]);

  useEffect(() => {
    if (appLocation?.state?.from === "experience") {
      // setShowChooseOption(true);
      setAddProjectObj(appLocation?.state?.data ?? {});
      setCompanyData(appLocation?.state?.projectSelectCompany ?? {});
    } else if (appLocation?.state?.from === "companyProfile") {
      setAddProjectObj(appLocation?.state?.data ?? {});
      setCompanyData(appLocation?.state?.selectedCompany ?? {});
    } else if (appLocation?.state?.from === "manufactureProfile") {
      setAddProjectObj(appLocation?.state?.data ?? {});
      setManufacturerData(appLocation?.state?.selectedManufacturer ?? {});
    } else if (appLocation?.state?.from === "productProfile") {
      setAddProjectObj(appLocation?.state?.data ?? {});
      setProductData(appLocation?.state?.selectedProduct ?? {});
    } else if (appLocation?.state?.from === "quiz_site") {
      setShowChooseOption(true);
    } else if (props?.from == "signUpPage") {
      setShowChooseOption(true);
    } else if (appLocation?.state?.from == "dashboard-profile-post") {
      setShowChooseOption(false);
      setSelectedSiteDetails(appLocation?.state?.appRecordId);
      setShowSiteCardModal(true);
      // setShowAddOption(false);
      // setShowDropMarker(false);
    }
  }, [appLocation.state]);

  useEffect(() => {
    getNoLocationSiteList();
  }, []);

  const getNoLocationSiteList = async () => {
    const userId = localStorage?.userId;
    try {
      const noLocationSitesRes = await SiteIssues(userId);
      setNoLocationSiteList(noLocationSitesRes ?? []);
    } catch (error) {
      console.log("Error while getting no location site :: ", error);
    }
  };

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          errorShown = false; // Reset the flag on success
          resolve(currentLocation(position));
        }, showError);
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const currentLocation = (position) => {
    var lat = position?.coords?.latitude;
    var lng = position?.coords?.longitude;
    return {
      lat: lat,
      lng: lng,
    };
  };

  const getLocation = async () => {
    let getValue = await getCurrentLocation();
    setLocation({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    setCurrentLocationMap({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    setChooselocation({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    getSitesofCurrentlocation(
      getValue?.lat,
      getValue?.lng,
      activeTab,
      activeSiteFilter
    );
    setShowCurrentDropMarker(true);
    setSearchTerm("");
    setShowDropMarker(false);
  };

  const getLocationForDashboard = async () => {
    let getValue = await getCurrentLocation();
    if (
      appLocation?.state?.from == "dashboard-profile-post" &&
      showChooseOption == false
    ) {
      var siteRes = await fetchSites({
        SearchList: [
          {
            Id: appLocation?.state?.appRecordId,
          },
        ],
      });
    }

    setLocation({
      lat: siteRes?.data[0]?.latitude,
      lng: siteRes?.data[0]?.longitude,
    });
    setCurrentLocationMap({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    setChooselocation({
      lat: siteRes?.data[0]?.latitude,
      lng: siteRes?.data[0]?.longitude,
    });
    if (
      siteRes?.data[0]?.lat != undefined &&
      siteRes?.data[0]?.lng != undefined
    ) {
      getSitesofCurrentlocation(
        siteRes?.data[0]?.lat,
        siteRes?.data[0]?.lng,
        activeTab,
        activeSiteFilter
      );
    }
    setShowCurrentDropMarker(true);
    setSearchTerm("");
    setShowDropMarker(false);
  };

  let errorShown = false;

  const showError = (error) => {
    if (!errorShown) {
      errorShown = true;
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Please allow location from your browser");
          getCurrentLocation();
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
        default:
          break;
      }
    }
  };

  const getSitesofCurrentlocation = async (lat, lng, tab, isActive) => {
    try {
      if (lat != undefined && lng != undefined) {
        setLoading(true);
        let addressData = await getAddress({ latitude: lat, longitude: lng });
        let addressObj = addressData.objAddress;
        await getSiteHandler(addressObj, tab, isActive);
      }
    } catch (error) {
      console.log("Error while getting address :: ", error);
      setLoading(false);
    }
  };

  async function getSiteHandler(addressObj, tab, isActive) {
    try {
      const type =
        tab == ""
          ? 0
          : tab == "WorkedSites"
          ? 1
          : tab == "FollowSites"
          ? 2
          : tab == "UnnamedSites"
          ? 3
          : 0;
      const newPayload = {
        Active: isActive ? 1 : 0, // 0 all, 1 active
        Latitude: addressObj?.latitude,
        Longitude: addressObj?.longitude,
        VP_Latitude_NE: map?.getBounds()?.getNorthEast()?.lat(),
        VP_Latitude_SW: map?.getBounds()?.getSouthWest()?.lat(),
        VP_Longitude_NE: map?.getBounds()?.getNorthEast()?.lng(),
        VP_Longitude_SW: map?.getBounds()?.getSouthWest()?.lng(),
        City: "",
        Province: "",
        Country: "",
        Address: "",
        PostalCode: "",
        UserId: localStorage?.userId,
        Filters: type, //0 -- No Filter, 1 -- My Sites, 2--Following site , 3-- Unnamed Site
      };

      const mapRes = await siteDetailsByType(newPayload);
      const mapResData = mapRes ?? [];
      const withoutUnanmedSitesData =
        mapResData?.filter((item) => item?.name?.trim() !== "") ?? [];
      setSiteList(
        props?.from == "signUpPage" ? withoutUnanmedSitesData : mapResData
      );
      setLoading(false);
    } catch (error) {
      console.log("Error while getting site list :: ", error);
      setLoading(false);
    }
  }

  const handleMapDrag = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter(); // Get the center of the map
      const lat = center.lat();
      const lng = center.lng();
      getSitesofCurrentlocation(lat, lng, activeTab, activeSiteFilter);
    }
    // setShowCurrentDropMarker(false);
  };

  const handleMapClick = async (e) => {
    if (
      (appLocation?.state?.from === "experience" ||
        appLocation?.state?.from === "companyProfile" ||
        appLocation?.state?.from === "manufactureProfile" ||
        appLocation?.state?.from === "productProfile" ||
        appLocation?.state?.from === "quiz") &&
      !showChooseOption
    ) {
      setShowDropMarker(false);
      setShowSiteCardModal(false);
      setShowAddOption(false);
      await setSelectedSiteDetails(null);
    } else if (appLocation?.state?.from === "dashboard-profile-post") {
      setShowDropMarker(false);
    } else {
      await setSelectedSiteDetails(null);
      setShowDropMarker(true);
      setChooselocation({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });

      let getLatlng = {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      };
      let addressData = await getAddress(getLatlng);
      setSiteAddressData(addressData?.objAddress);
      setShowSiteCardModal(true);
      setShowAddOption(true);
    }
  };

  const onSiteClick = async (site) => {
    if (!site) return null;
    setShowSiteCardModal(false);
    setSelectedSiteDetails(null);
    setSelectedSiteDetails(site);
    setShowSiteCardModal(true);
    setShowAddOption(false);
    setShowDropMarker(false);
  };

  const handleClusterClick = (cluster) => {
    const clusterMarkers = cluster.getMarkers();
    const map = mapRef.current;
    if (map) {
      const zoomLevel = map.getZoom();
      if (zoomLevel >= 22) {
        // Filter out sites that have the same coordinates
        const clusterSites = siteList.filter((site) =>
          clusterMarkers.some(
            (clusterMarker) =>
              site.latitude === clusterMarker.getPosition().lat() &&
              site.longitude === clusterMarker.getPosition().lng()
          )
        );
        if (clusterSites?.length === 0) return;
        if (clusterSites?.length === 1) {
          return onSiteClick(clusterSites?.[0]);
        }
        setSiteListOfSameLocation(clusterSites);
        setShowSiteOfSameLocation(true);
      }
    }
  };

  const onSiteFilterClick = (value) => {
    setActiveSiteFilter((preValue) => !preValue);
    getSitesofCurrentlocation(location?.lat, location?.lng, activeTab, value);
  };

  const onFilterClick = (value) => {
    setActiveTab(value);
    getSitesofCurrentlocation(
      location?.lat,
      location?.lng,
      value,
      activeSiteFilter
    );
  };

  const handleSearch = (place) => {
    let lat = Number(place.geometry.location.lat());
    let lng = Number(place.geometry.location.lng());
    setLocation({ lat, lng });
    setShowDropMarker(true);
    setShowCurrentDropMarker(false);
    map.fitBounds(place.geometry.viewport);
    getSitesofCurrentlocation(lat, lng, activeTab, activeSiteFilter);
  };

  const onChangeSite = (obj) => {
    setChangeSiteFlag(true);
    setShowChooseOption(true);
    setAddProjectObj(obj);
    setShowSiteCardModal(false);
    setSelectedSiteDetails(null);
  };

  const onAddProjectClick = () => {
    setShowAddOption(false);
    setShowDropMarker(false);
    setShowSiteCardModal(false);
    setSiteDetailsForAddProject({
      id: 0,
      name: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      latitude: chooseLocation?.lat,
      longitude: chooseLocation?.lng,
    });
    return setShowAddProjectModal(true);
  };

  const onProjectSave = (callSitesApi) => {
    if (callSitesApi) {
      getSitesofCurrentlocation(
        location?.lat,
        location?.lng,
        activeTab,
        activeSiteFilter
      );
    }
  };

  const onAddNewSite = () => {
    setShowSiteCardModal(false);
    setShowAddOption(false);
    setShowAddSiteModal(true);
  };

  const closeProfileProject = () => {
    history.replace({ ...appLocation, state: null, search: "" });
  };

  const onImageSelectedForAddProject = async (images) => {
    let prevFormValues = {
      projectName: "",
      selectedType: 0,
      website: "",
      description: "",
      isActiveProject: false,
      isAddToMyProfile: false,
      pictures: images,
    };
    let obj = {
      previousSiteSelected: {},
      prevFormValues,
    };
    await setSiteDetailsForAddProject({
      id: 0,
      name: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      latitude: 0,
      longitude: 0,
    });
    await setAddProjectObj(obj);
    await setShowAddProjectModal(true);
    await setShowFabOptions(false);
  };

  const onAddProjectForProfile = async () => {
    await setSiteDetailsForAddProject({
      id: 0,
      name: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      latitude: currentLocationMap?.lat,
      longitude: currentLocationMap?.lng,
    });
    setShowSiteCardModal(false);
    await setShowAddProjectModal(true);
    await setShowFabOptions(false);
  };

  const onSiteAdd = () => {
    setShowAddSiteModal(false);
    setShowDropMarker(false);
    getNoLocationSiteList();
    getSitesofCurrentlocation(
      location?.lat,
      location?.lng,
      activeTab,
      activeSiteFilter
    );
  };

  const createCircleIcon = (color, radius) => ({
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 0.4,
    strokeColor: "Blue",
    strokeOpacity: 20,
    strokeWeight: 40,
    scale: radius,
  });

  const onNearClick = () => {
    let getValue = {
      lat: currentLocationMap?.lat,
      lng: currentLocationMap?.lng,
    };
    setLocation({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    setCurrentLocationMap({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    setChooselocation({
      lat: getValue?.lat,
      lng: getValue?.lng,
    });
    getSitesofCurrentlocation(
      getValue?.lat,
      getValue?.lng,
      activeTab,
      activeSiteFilter
    );
    setShowCurrentDropMarker(true);
    setSearchTerm("");
    setShowDropMarker(false);
  };

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          height: props?.from == "signUpPage" ? "80vh" : "93vh",
        }}
        mapContainerClassName="site-map-container"
        center={location}
        onDragEnd={handleMapDrag}
        onZoomChanged={handleMapDrag}
        zoom={showCurrentDropMarker ? 15 : 0}
        onLoad={(map) => {
          mapRef.current = map;
          setMap(map);
        }}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
        }}
        ref={mapRef}
        onClick={(e) => handleMapClick(e)}
        mapTypeId={
          mapTypeId == 0
            ? "roadmap"
            : mapTypeId == 1
            ? "hybrid"
            : mapTypeId == 2
            ? "satellite"
            : mapTypeId == 3
            ? "terrain"
            : ""
        }>
        {/* Markers and Clusters for sites */}

        {loading ? (
          <div style={{ position: "absolute", left: "48%", top: "45%" }}>
            <Loader />
          </div>
        ) : (
          <>
            {appLocation?.state?.from == "dashboard-profile-post" &&
            showChooseOption == false ? (
              <Marker
                position={{
                  lat: siteInfo?.latitude,
                  lng: siteInfo?.longitude,
                }}
                icon={{
                  url: mapMarkerYellow,
                  scaledSize: new window.google.maps.Size(35, 40),
                }}
              />
            ) : (
              <>
                <MarkerClusterer
                  options={clusterOptions}
                  onClick={handleClusterClick}>
                  {(clusterer) => {
                    return siteList.map((site) => (
                      <Marker
                        key={site?.id}
                        position={{
                          lat: site?.latitude,
                          lng: site?.longitude,
                        }}
                        clusterer={clusterer}
                        onClick={() => onSiteClick(site)}
                        icon={{
                          url:
                            activeTab === "FollowSites" ||
                            activeTab === "WorkedSites"
                              ? mapMarkerBlue
                              : activeTab === "UnnamedSites"
                              ? unnamed
                              : site?.isMySites
                              ? mapMarkerBlue
                              : site?.name == ""
                              ? unnamed
                              : mapMarkerYellow,
                          scaledSize:
                            // Selected map marker is slightly larger
                            selectedSiteDetails?.id === site?.id
                              ? new window.google.maps.Size(50, 60)
                              : new window.google.maps.Size(30, 40),
                        }}
                      />
                    ));
                  }}
                </MarkerClusterer>

                {showCurrentDropMarker ? (
                  <Marker
                    position={{
                      lat: changeSiteFlag
                        ? currentLocationMap?.lat
                        : location?.lat,
                      lng: changeSiteFlag
                        ? currentLocationMap?.lng
                        : location?.lng,
                    }}
                    icon={createCircleIcon("#4285F4", 5)}
                  />
                ) : null}

                {showDropMarker && (
                  <Marker
                    position={{
                      lat: chooseLocation?.lat,
                      lng: chooseLocation?.lng,
                    }}
                    icon={{
                      url: mapMarkerBlue,
                      scaledSize: new window.google.maps.Size(35, 40),
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {appLocation?.state?.from == "dashboard-profile-post" ? (
          <></>
        ) : (
          <span className="activeSitesFlag">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: BIBlue }}
                  checked={activeSiteFilter}
                  onChange={(e) => {
                    onSiteFilterClick(e.target.checked);
                  }}
                  name="activeSiteFilter"
                />
              }
              label="Only show Active Sites"
            />
          </span>
        )}
        {appLocation?.state?.from === "experience" ||
        appLocation?.state?.from === "companyProfile" ||
        appLocation?.state?.from === "manufactureProfile" ||
        appLocation?.state?.from === "productProfile" ||
        appLocation?.state?.from === "quiz" ? (
          <div className="chooseSites">
            <Typography variant="h6">Choose Projects</Typography>
          </div>
        ) : (
          <></>
        )}

        {changeSiteFlag || appLocation?.state?.from === "quiz_site" ? (
          <div className="chooseSites">
            <Typography variant="h6">Choose Site</Typography>
          </div>
        ) : (
          <></>
        )}

        <IconButton
          className="add-iconbtn1"
          color="primary"
          onClick={(event) => {
            setMoreMenuAnchorEl(event.currentTarget);
          }}>
          <FilterNoneIcon sx={{ fontSize: "25px" }} />
        </IconButton>
        {(changeSiteFlag && appLocation?.state?.from == "quiz_site") ||
        appLocation?.state?.from == "dashboard-profile-post" ? (
          <></>
        ) : (
          <>
            <IconButton
              className="add-nearbtn"
              color="primary"
              onClick={onNearClick}>
              <NearMeIcon sx={{ fontSize: "28px" }} />
            </IconButton>

            {/* {props?.from == "signUpPage" ? null : ( */}
            <IconButton
              className="add-iconbtn"
              color="primary"
              onClick={() => {
                setShowFabOptions(true);
                setShowSiteCardModal(false);
              }}>
              <AddIcon sx={{ fontSize: "28px" }} />
            </IconButton>
            {/* )} */}
          </>
        )}

        <Menu
          anchorEl={moreMenuAnchorEl}
          open={Boolean(moreMenuAnchorEl)}
          onClose={(event) => {
            setMoreMenuAnchorEl(false);
          }}>
          {mapTypes.map((mapType) => (
            <MenuItem
              key={mapType.id}
              onClick={() => {
                setMapTypeId(mapType.id);
                setSelectedMapType(mapType.label.toLowerCase());
                setMoreMenuAnchorEl(null);
              }}
              style={{
                backgroundColor:
                  selectedMapType === mapType.label.toLowerCase()
                    ? "#083DB8"
                    : "inherit",
                color:
                  selectedMapType === mapType.label.toLowerCase()
                    ? "#fff"
                    : "#083DB8",
              }}>
              {mapType.label}
            </MenuItem>
          ))}
        </Menu>

        <TabOptions
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          activeTab={activeTab}
          onFilterClick={onFilterClick}
          handleSearch={handleSearch}
          siteList={siteList}
          noLocationSiteList={noLocationSiteList ?? []}
          setShowSiteCardModal={setShowSiteCardModal}
          setSelectedSiteDetails={setSelectedSiteDetails}
          getNoLocationSiteList={getNoLocationSiteList}
          setShowAddProjectModal={setShowAddProjectModal}
          setReportModalOpenSearch={setReportModalOpenSearch}
          siteInfo={siteInfo}
          setSiteInfo={setSiteInfo}
          addProjectToSite={(obj) => {
            setSiteDetailsForAddProject(obj);
            setShowAddProjectModal(true);
          }}
          handleReportModal={(obj) => {
            setSiteDetailsForAddProject(obj);
            setReportModalOpenSearch(true);
          }}
          handleAddSiteName={(obj) => {
            setSiteDetailsForAddProject(obj);
            setShowAddNameModal(true);
          }}
          showChooseOption={showChooseOption}
          currentLocation={currentLocationMap}
          getSiteInfo={getSiteInfo}
          setShowSiteIssuesList={setShowSiteIssuesList}
          showSiteIssuesList={showSiteIssuesList}
          from={appLocation?.state?.from ?? ""}
          fromQuestion={appLocation?.state?.fromQuestion ?? ""}
          fromSignUpPage={props?.from}
        />

        {showSiteCardModal && (
          <SiteCard
            selectedSiteDetails={selectedSiteDetails}
            siteInfo={siteInfo}
            getSiteInfo={getSiteInfo}
            onClose={() => {
              history.replace({ ...appLocation, state: null, search: "" });
              setShowSiteCardModal(false);
              setSelectedSiteDetails(null);
              setShowDropMarker(false);
              setShowAddOption(false);
              setSiteInfo({});
              if (
                appLocation?.state?.from == "dashboard-profile-post" &&
                showChooseOption == false
              ) {
                getLocation();
              } else {
                getSitesofCurrentlocation(
                  location?.lat,
                  location?.lng,
                  activeTab,
                  activeSiteFilter
                );
              }
            }}
            onChangeSite={(obj) => onChangeSite(obj)}
            showChooseOption={showChooseOption}
            addProjectObj={addProjectObj}
            companyData={companyData}
            manufacturerData={manufacturerData}
            productData={productData}
            showSiteInfo={() => {
              setShowChooseOption(false);
              setAddProjectObj({});
            }}
            getNoLocationSiteList={getNoLocationSiteList}
            setShowChooseOption={(value) => {
              setShowChooseOption(value ?? false);
            }}
            from={
              showAddOption
                ? "mapScreenForAddOption"
                : appLocation?.state?.from ?? ""
            }
            onAddProjectClick={onAddProjectClick}
            onAddNewSite={onAddNewSite}
            closeProfileProject={closeProfileProject}
            chooseLocationSite={chooseLocation}
            siteAddressData={siteAddressData}
            currentLocation={currentLocationMap}
            setChangeSiteFlag={setChangeSiteFlag}
            fromQuestion={appLocation?.state?.fromQuestion ?? ""}
            fromSignUpPage={props?.from}
            setSignUpSiteInfo={props?.setSignUpSiteInfo}
            setAddSiteModalForSignUp={props?.setAddSiteModalForSignUp}
          />
        )}

        {showSiteOfSameLocation && (
          <Chooseasite
            isOpen={showSiteOfSameLocation}
            onClose={() => {
              setShowSiteOfSameLocation(false);
              setSiteListOfSameLocation([]);
            }}
            onSiteClick={(site) => {
              if (site) {
                setShowSiteOfSameLocation(false);
                setSiteListOfSameLocation([]);
                return onSiteClick(site);
              }
            }}
            siteList={siteListOfSameLocation}
          />
        )}
        {showAddProjectModal && (
          <AddProjectModal
            show={showAddProjectModal}
            onClose={() => {
              setShowAddProjectModal(false);
              setShowDropMarker(false);
              setShowAddOption(false);
              if (showChooseOption) {
                setShowChooseOption(false);
                setAddProjectObj({});
              }
              history.replace({ ...appLocation, state: null, search: "" });
              setCompanyData({});
              setProductData({});
              setManufacturerData({});
              getLocation();
            }}
            siteDetails={siteDetailsForAddProject}
            onDataSave={onProjectSave}
            onChangeSite={(obj) => {
              setShowAddProjectModal(false);
              onChangeSite(obj);
            }}
            addProjectObj={addProjectObj}
            companyData={companyData}
            manufacturerData={manufacturerData}
            productData={productData}
            // showChooseOption={showChooseOption}
            from={appLocation?.state?.from ?? ""}
            mapScreenFlag={true}
            fromQuestion={appLocation?.state?.fromQuestion ?? ""}
          />
        )}

        {showAddSiteModal && (
          <AddSiteModal
            show={showAddSiteModal}
            onClose={() => {
              setShowAddSiteModal(false);
              setShowDropMarker(false);
            }}
            currentLocation={location}
            chooseLocation={chooseLocation}
            showFabOptions={showFabOptions}
            setChooselocation={setChooselocation}
            setShowchooselocation={setShowchooselocation}
            onDataSave={onSiteAdd}
            setChangeLocation={setChangeLocation}
            setAddNewSiteLocation={setAddNewSiteLocation}
            from={appLocation?.state?.from ?? ""}
            fromQuestion={appLocation?.state?.fromQuestion ?? ""}
            fromSignUpPage={props?.from}
            setSignUpSiteInfo={props?.setSignUpSiteInfo}
            setAddSiteModalForSignUp={props?.setAddSiteModalForSignUp}
          />
        )}

        {showChooselocation && (
          <Chooselocation
            chooseLocation={chooseLocation}
            showChooselocation={showChooselocation}
            setShowchooselocation={setShowchooselocation}
            setShowAddSiteModal={setShowAddSiteModal}
            setChooselocation={setChooselocation}
            changeLocation={changeLocation}
            currentLocation={currentLocationMap}
            addNewSiteLocation={addNewSiteLocation}
            setAddNewSiteLocation={setAddNewSiteLocation}
          />
        )}
        {showFabOptions && (
          <FabOptions
            open={showFabOptions}
            // new code sanket 27-03-2024
            location={location}
            setShowFabOptions={setShowFabOptions}
            onClose={() => {
              setShowFabOptions(false);
            }}
            getCurrentLocation={getCurrentLocation}
            setShowAddSiteModal={setShowAddSiteModal}
            onImageSelected={onImageSelectedForAddProject}
            setChooselocation={setChooselocation}
            onAddProjectForProfile={onAddProjectForProfile}
            from={appLocation?.state?.from ?? ""}
            fromSignUpPage={props?.from}
          />
        )}

        {showAddNameModal && (
          <AddSiteNameModal
            open={showAddNameModal}
            onClose={() => {
              setShowAddNameModal(false);
            }}
            siteDetails={siteDetailsForAddProject}
            onDataSave={() => {
              getSiteInfo();
            }}
          />
        )}

        <ReportModal
          name={siteDetailsForAddProject?.name}
          isOpen={reportModalOpenSearch}
          onClose={() => {
            setReportModalOpenSearch(false);
            setShowSiteIssuesList(false);
          }}
          recordId={siteDetailsForAddProject?.id}
        />
      </GoogleMap>
    </>
  );
};

export default MapScreen;
