import React, { useState } from 'react'
import { Card, Chip, Stack, Typography } from '@mui/material';
import "../../../assets/css/ClientHome.css"
import AddIcon from '@mui/icons-material/Add';
import { ClientHeader } from '../ClientHeader';
import { Edit as EditIcon } from "@material-ui/icons";
import { Delete as DeleteIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import CrewModal from './CrewModal';

const ClientTimesheets = () => {


    const [openCrew, setOpencrew] = useState(false);


    return (
        <>
            <ClientHeader screenName={"Timesheets"} />
            <div className="page rewards-page" style={{ backgroundColor: "#FFFFFF", paddingTop: "2rem" }}>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography >Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography >Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography >Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography >Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography >Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography>Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <Card className='timelist-card'>
                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">October 05, 2024</Typography>
                        <Stack flexDirection={"row"} gap={1}>
                            <EditIcon style={{ color: "blue" }} />
                            <DeleteIcon style={{ color: "red" }} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                        <Stack flexDirection={"row"} gap={4} position={"relative"}>
                            <Stack className="jobs-count">
                                <Typography >Jobs</Typography>
                                <Typography>2</Typography>
                            </Stack>
                            <Stack className='hours-count'>
                                <Typography>Hours</Typography>
                                <Typography>11</Typography>
                            </Stack>
                        </Stack>
                        <Chip label="In Review" style={{ background: "#ffbc0a" }} />
                    </Stack>
                </Card>
                <CrewModal onClose={() => setOpencrew(false)} openCrew={openCrew} />
                <div className="fixed-button-container">
                    <button className="fixed-button" style={{ background: localStorage?.clientAccentColor }} onClick={() => setOpencrew(true)}>
                        <AddIcon />
                    </button>
                </div>
            </div>
        </>
    )
}

export default ClientTimesheets