import { Button, Card, Checkbox, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import { fetchClientEmployee } from "../../../api/ClientApi";

export default function Employeeslist(props) {

    useEffect(() => {
        if (props.showEmployeelist === true) {
            getEmployeelist();
        }
    }, [props.showEmployeelist])

    const getEmployeelist = async () => {
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{ ClientId: localStorage.clientId }],
            };
            const res = await fetchClientEmployee(obj);
            if (res.data?.length > 0) {
                let getList = res?.data?.map((item) => {
                    return {
                        ...item,
                        isSelected: false
                    }
                })
                props.setEmplist(getList);
            }
        } catch (error) {

        }
    }

    const selectEmployee = (item) => {
        let getList = props.emplist?.map((emp) => {
            if (emp?.id === item?.id) {
                return {
                    ...emp,
                    isSelected: item?.isSelected === true ? false : true
                }
            } else {
                return {
                    ...emp
                }
            }
        })
        props.setEmplist(getList);
    }

    const onSelectemp = () => {
        let getSelectedemp = props.emplist.filter((item) => item.isSelected === true);
        if (getSelectedemp?.length > 0) {
            props.setSelectedemp(getSelectedemp);
        }
        props.handleClose();
    }

    return (
        <>
            <Modal
                open={props.showEmployeelist}
                onClose={props.handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Select Employees</Typography>
                            <TextField
                                value={""}
                                placeholder={"Search for an employee"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px" }}>
                            {props.emplist.map((item) => (
                                <Stack flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => selectEmployee(item)}>
                                    <Checkbox value={item?.isSelected}></Checkbox>
                                    <Typography>{item?.firstName + " " + item?.lastName}</Typography>
                                </Stack>
                            ))}
                        </Card>
                        {console.log(props.emplist?.length)}
                        <Button onClick={onSelectemp} color="primary" fullWidth variant="contained" sx={{ marginTop: 2 }} disabled={props.emplist?.filter((item) => item?.isSelected === true)?.length !== 0 ? false : true}>Select {props.emplist?.filter((item) => item?.isSelected === true)?.length !== 0 ? props.emplist?.filter((item) => item?.isSelected === true)?.length : ""}</Button>
                    </div>
                </div>
            </Modal>
        </>
    )

}