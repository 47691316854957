import { Box, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import AddIcon from '@mui/icons-material/Add';

export default function GeneralModal(props) {

 
    return (
        <Box className="reviews-box">
            <Typography fontSize={19} fontWeight={600}>Reviews</Typography>
            <Stack marginTop={1}>
                <Typography fontSize={14} color={"#8d8d8d"}>Date</Typography>
                <Typography fontSize={16} fontWeight={500}>Oct 04, 2024</Typography>
            </Stack>
            <Stack marginTop={1}>
                <Typography fontSize={14} color={"#8d8d8d"}>Job</Typography>
                <Typography fontSize={16} fontWeight={500}>JC113 - JobName3</Typography>
            </Stack>
            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={1} marginBottom={1}>
                <Typography fontSize={17} fontWeight={600}>Employees</Typography>
                <Typography fontSize={15} fontWeight={500}>Edit</Typography>
            </Stack>
            <Card className="emp-card" marginTop={1}> 
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={16} fontWeight={500}>Tejashree Thorve</Typography>
                    <Typography fontSize={16} fontWeight={500}>10.0 km </Typography>
                    <Typography fontSize={16} fontWeight={500}>10 h</Typography>
                </Stack>
            </Card>
            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={1} marginBottom={1}>
                <Typography fontSize={17} fontWeight={600}>Activities</Typography>
                <Typography fontSize={15} fontWeight={500} color={"#083db8"}>Edit</Typography>
            </Stack>
            <Card className="activity-card" marginTop={1}>
                <Stack>
                    <Typography fontSize={16}>JA139 - JobActivity187_6</Typography>
                    <Stack>
                        <Stack>
                            <Typography fontSize={15} color={"#8d8d8d"}>Hours</Typography>
                            <Typography>00.00</Typography>
                        </Stack>
                        <Stack>
                            <Typography fontSize={15} color={"#8d8d8d"}>Quantity</Typography>
                            <Typography>0 sqft</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={1}>
                <Typography fontSize={15} fontWeight={500}>Total Hours</Typography>
                <Typography fontSize={15} fontWeight={500}>0.00</Typography>
            </Stack>
            <Stack marginTop={1}> 
                <Typography fontSize={15} fontWeight={500} color={"#8d8d8d"}>Notes</Typography>
                <TextField rows={2} multiline placeholder="Enter additional details (optional)" />
            </Stack>
            <Stack marginTop={1}>
                <Typography fontSize={15} fontWeight={500} color={"#8d8d8d"}>Pictures</Typography>
                <Grid container gap={1} overflow={"scroll"} flexWrap={"nowrap"} display={"-webkit-box"}>
                    <Grid sm={2} md={3} lg={3} xl={3}>
                        <Box className="add-pic">
                            <AddIcon color="#cccccc" />
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}