import { Button, Card, Checkbox, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import { FetchClientJobActivities2 } from "../../../api/ClientApi";

export default function ClientJobActivities(props) {

    const [jobActivities, setJobactivities] = useState([]);
    const [searchInputText, setSearchInputText] = useState("");
    
    useEffect(() => {
        if(props.showJobActivities === true) {
            getClientjobActivities()
        }
    }, [props.showJobActivities])

    const getClientjobActivities = async () => {
        try {
            const obj = {
                PageNr: 1,
                FullSearch: searchInputText,
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [
                    {
                         clientJobId: props.selectedJobId
                        
                    }
                ]
            }
            const res = await FetchClientJobActivities2(obj);
            console.log("res",res.data);
            const options = res.data.map((item, index) => {
                const isAlreadySelected = props.selectedJobActivities.some((act) => act.id === item.id)
                return {
                    value: `${item.labourActivityCode} - ${item.labourActivityName}`,
                    label: `${item.labourActivityCode} - ${item.labourActivityName}`,
                    id: item.id,
                    isSelected: isAlreadySelected,
                    activityName: `${item.labourActivityCode} - ${item.labourActivityName}`,
                }
            })
            setJobactivities(options)
        } catch (error) {

        }
    }

    const selectClientjob= (item) => {
        let getList = jobActivities?.map((act) => {
            if (act?.id === item?.id) {
                return {
                    ...act,
                    isSelected: item?.isSelected === true ? false : true
                }
            } else {
                return {
                    ...act
                }
            }
        })
        setJobactivities(getList);
    }
    
    const onSelectjobActivities = () => {
         let getSelected = jobActivities.filter((item)=> item.isSelected === true);
         if(getSelected?.length > 0) {
             props.setSelectJobActivities(getSelected);
         }else {
            props.setSelectJobActivities([]);   
         }
         props.handleClose();
    }

    return (
        <>
            <Modal
                open={props.showJobActivities}
                onClose={props.handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Select Job Activities</Typography>
                            <TextField
                                value={""}
                                placeholder={"Search for a job activity"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px" }}>
                            {jobActivities.map((item) => (
                                <Stack flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => selectClientjob(item)}>
                                    <Checkbox value={item?.isSelected}></Checkbox>
                                    <Typography>{item?.value}</Typography>
                                </Stack>
                            ))}
                        </Card>
                        {console.log(jobActivities?.length)}
                        <Button onClick={onSelectjobActivities} color="primary" fullWidth variant="contained" sx={{ marginTop: 2 }} disabled={jobActivities?.filter((item)=> item?.isSelected === true)?.length !== 0 ? false : true}>Select {jobActivities?.filter((item)=> item?.isSelected === true)?.length !== 0 ? jobActivities?.filter((item)=> item?.isSelected === true)?.length : ""}</Button>
                    </div>
                </div>
            </Modal>
        </>
    )

}