import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  IconButton,
  TextField,
  Card,
  CardHeader,
  Avatar,
  Divider,
  Menu,
} from "@material-ui/core";
// import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Publish as UploadIcon,
} from "@material-ui/icons";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Loader from "../../components/Loader";
import DateSelector from "../../components/DateSelector";
import AlertModal from "../../components/AlertModal";
import ShareModal from "./ShareModal";

import {
  NewUnshareTicketsWithUser,
  UnshareTicketsWithUser1,
  deleteTicketPictures,
  fetchTicketTypes,
  saveTicketTypes,
} from "../../api/TicketsApi";

import { fetchCompanies, saveCompanies2 } from "../../api/CompanyApi";
import { BIBlue, BIRed } from "../../assets/buildidColors";
import moment from "moment";
import { MenuItem } from "@mui/material";
import Message from "./../resumebuilder/sections/Message";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AvtarImage from "../../assets/images/AvatarImage.jpeg";
import { saveChatGroupUsers } from "../../api/ChatApi";

export function TicketModal(props) {
  const [isSaving, setIsSaving] = useState(false); // State var for showing loading splash
  const [emptyFieldsAlertOpen, setEmptyFieldsAlertOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const history = useHistory();
  // Ticket details
  const [ticketType, setTicketType] = useState("");
  const [ticketTypeId, setTicketTypeId] = useState(-1);
  const [issuedBy, setIssuedBy] = useState("");
  const [issuedById, setIssuedById] = useState(-1);
  const [issuedOn, setIssuedOn] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [ticketId, setTicketId] = useState("");
  // Ticket images
  const [frontPictureFile, setFrontPictureFile] = useState("");
  const [frontPictureFileName, setFrontPictureFileName] = useState("");
  const [frontPictureUrl, setFrontPictureUrl] = useState("");
  const [backPictureFile, setBackPictureFile] = useState("");
  const [backPictureFileName, setBackPictureFileName] = useState("");
  const [backPictureUrl, setBackPictureUrl] = useState("");
  const [shareTicketUserList, setShareTicketUserList] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [isFrontImageDeleted, setIsFrontImageDeleted] = useState(false);
  const [isBackImageDeleted, setIsBackImageDeleted] = useState(false);
  const [fetchTicketTypeList, setFetchTicketTypeList] = useState([]);
  const [fetchIssuedByList, setFetchIssuedByList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isNewTicketType, setIsNewTicketType] = useState(false);
  const [isNewIssuedBy, setIsNewIssuedBy] = useState(false);
  const [flag, setFlag] = useState(false);
  const [issuedByFlag, setIssuedByFlag] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isIssuedBySearching, setIsIssuedBySearching] = useState(false);
  const [filteredTicketTypeList, setFilteredTicketTypeList] = useState([]);
  const [filteredIssuedByList, setFilteredIssuedByList] = useState([]);
  const filter = createFilterOptions();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [unSharedEmail, setUnSharedEmail] = useState(null);

  console.log("unSharedEmail ===>", unSharedEmail);

  const handleMenuClick = (event, user) => {
    setUnSharedEmail(user);
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setUnSharedEmail(null);
    setMoreMenuAnchorEl(null);
  };

  const handleReport = (recordId) => {
    console.log(`Report for record ID: ${recordId}`);
  };

  // Reset local state (ie. unsaved values) to API's state on Modal open and close
  useEffect(() => {
    setShareTicketUserList(props.ticket?.sharedTicketsList);
    setTicketType(props.ticket?.ticketType);
    setTicketTypeId(props.ticket?.ticketTypeId);
    setIssuedBy(props.ticket?.issuedBy);
    setIssuedById(props.ticket?.issuedById);
    setIssuedOn(props.ticket?.issuedOn);
    setExpiryDate(props.ticket?.expiry);
    setTicketId(props.ticket?.ticketId);
    setFrontPictureUrl(props.ticket?.frontPictureUrl);
    setBackPictureUrl(props.ticket?.backPictureUrl);
    setFrontPictureFileName("");
    setBackPictureFileName("");
    setDisableSaveButton(true);
  }, [
    props?.modalOpen,
    props.ticket?.ticketType,
    props.ticket?.ticketTypeId,
    props.ticket?.issuedBy,
    props.ticket?.issuedById,
    props.ticket?.issuedOn,
    props.ticket?.expiry,
    props.ticket?.ticketId,
    props.ticket?.sharedTicketsList,
    props.ticket?.frontPictureUrl,
    props.ticket?.backPictureUrl,
  ]);

  async function handleDeleteButtonClick() {
    await props.onDelete(props.ticket?.id); // Propagate to parent for API delete
    props.onModalClose();
  }

  const getTicketTypes = async () => {
    const res = await fetchTicketTypes({
      SearchList: [{ name: "" }],
    });
    let a;
    res.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = removeDuplicates(res, "name");
    setFetchTicketTypeList(response);
  };

  const getIssuedBy = async () => {
    const res = await fetchCompanies({
      SearchList: [{ name: "" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = removeDuplicates(res, "name");
    setFetchIssuedByList(response);
  };

  async function saveTicketType() {
    const obj = {
      UserId: Number(localStorage.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          Name: ticketType,
          RecordStatusId: 1,
          CreatedBy: Number(localStorage.userId),
          ModifiedBy: Number(localStorage.userId),
        },
      ],
    };
    const res = await saveTicketTypes(obj);
    const ticketTypeIdValue = res[0]?.objData?.id;
    setTicketType(res[0]?.objData?.name);
    return ticketTypeIdValue;
  }

  async function saveIssuedBy() {
    const obj = {
      UserId: Number(localStorage.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          Name: issuedBy,
          RecordStatusId: 1,
          CreatedBy: Number(localStorage.userId),
          ModifiedBy: Number(localStorage.userId),
        },
      ],
    };
    const res = await saveCompanies2(obj);
    const issuedByValue = res[0]?.objData?.id;
    setIssuedBy(res[0]?.objData?.name);
    return issuedByValue;
  }

  useEffect(() => {
    getTicketTypes();
    getIssuedBy();
  }, []);

  useEffect(() => {
    if (frontPictureUrl && ticketType && issuedBy && issuedOn) {
      setDisableSaveButton(false);
    }
  }, [frontPictureUrl, ticketType, issuedBy, issuedOn]);

  async function saveFormData(getNewTicketId, getNewIssuedId) {
    let ticketData = new FormData();
    ticketData.append("Id", props.ticket.id);
    // ticketData.append( "TicketTypeId",isNewTicketType ? localStorage.ticketTypeIdValue : ticketTypeId );
    ticketData.append("TicketTypeId", getNewTicketId);
    ticketData.append("Expiry", expiryDate || "");
    ticketData.append("TicketId", ticketId || "");
    ticketData.append("IssuedById", getNewIssuedId);
    ticketData.append("IssuedOn", issuedOn || "");
    ticketData.append("ImageFront", frontPictureFile);
    ticketData.append("ImageBack", backPictureFile);
    await props.onSave(ticketData); // Propagate to parent for API save
  }

  async function handleSaveButtonClick() {
    try {
      console.log("Ticket save -====", issuedOn);

      if (
        moment(issuedOn).format("YYYY-MM-DD") >
        moment(expiryDate).format("YYYY-MM-DD")
      ) {
        setEmptyFieldsAlertOpen(true);
        setErrorMsg("Start Date should be before the End Date.");
        return;
      }

      if (!issuedOn) {
        setEmptyFieldsAlertOpen(true);
        setErrorMsg("Issued Date is a required field.");
        return;
      }
      if (ticketType == "") {
        setEmptyFieldsAlertOpen(true);
        setErrorMsg("Please select a ticket type.");
        return;
      }
      if (issuedBy == "") {
        setEmptyFieldsAlertOpen(true);
        setErrorMsg("Please add the company that issued your ticket.");
        return;
      }
      if (frontPictureUrl == "") {
        setEmptyFieldsAlertOpen(true);
        setErrorMsg("Please add the front image of your ticket.");
        return;
      }

      setIsSaving(true); // Show loading splash
      if (isBackImageDeleted && props.ticket?.id != 0) {
        if (backPictureUrl == "") {
          const obj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: false,
            ReturnRecordError: true,
            SoftDelete: false,
            DeleteList: [{ Id: props.ticket.id, BackPictureUrl: "" }],
          };
          await deleteTicketPictures(obj);
        }
      }

      if (isFrontImageDeleted && props.ticket?.id != 0) {
        const obj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: false,
          ReturnRecordError: true,
          SoftDelete: false,
          DeleteList: [{ Id: props.ticket.id, FrontPictureUrl: "" }],
        };
        await deleteTicketPictures(obj);
      }

      let getNewTicketId;
      let getNewIssuedId;
      if (isNewTicketType) {
        getNewTicketId = await saveTicketType();
        if (isNewIssuedBy) {
          getNewIssuedId = await saveIssuedBy();
        } else {
          getNewIssuedId = issuedById;
        }
        await saveFormData(getNewTicketId, getNewIssuedId);
      } else {
        getNewTicketId = ticketTypeId;
        if (isNewIssuedBy) {
          getNewIssuedId = await saveIssuedBy();
        } else {
          getNewIssuedId = issuedById;
        }
        await saveFormData(getNewTicketId, getNewIssuedId);
      }

      props.onModalClose();
      setIsNewTicketType(false);
      setIsNewIssuedBy(false);
      await setIsSaving(false);
      setIsFrontImageDeleted(false);
      setIsBackImageDeleted(false);
      if (props.config === "view-edit") props.onEditModeChange(false);
      else if (props.config === "edit-only") props.onModalClose();
    } catch (error) {
      props.onModalClose();
      setIsNewTicketType(false);
      setIsNewIssuedBy(false);
      await setIsSaving(false);
      setIsFrontImageDeleted(false);
      setIsBackImageDeleted(false);
      alert("Something went wrong, please try again later");
    }
  }

  // Updates filename and file in state based on form input
  function handleChooseFile(event) {
    let splitId = event.target.id.split("-");

    switch (splitId[0]) {
      case "frontPicture":
        setFrontPictureFile(event.target.files[0]); // Actual file for API saving
        setFrontPictureFileName(event.target.value); // Filepath for display
        setFrontPictureUrl(URL.createObjectURL(event.target.files[0])); // Source URL for image display
        break;

      case "backPicture":
        setBackPictureFile(event.target.files[0]); // Actual file for API saving
        setBackPictureFileName(event.target.value); // Filepath for display
        setBackPictureUrl(URL.createObjectURL(event.target.files[0])); // Source URL for image display
        break;

      default:
        console.log("Error with event id split!");
        break;
    }
  }

  function handleCloseImage(imageFrom) {
    if (imageFrom === "frontImage") {
      setIsFrontImageDeleted(true);
      setFrontPictureFile("");
      setFrontPictureFileName("");
      setFrontPictureUrl("");
    } else if (imageFrom === "backImage") {
      setIsBackImageDeleted(true);
      setBackPictureFile("");
      setBackPictureFileName("");
      setBackPictureUrl("");
    }
  }

  function handleIssuedOnChange(date) {
    if (date) setIssuedOn(date.format().substring(0, 10));
    else setIssuedOn(null);
  }
  function handleExpiryChange(date) {
    if (date) setExpiryDate(date.format().substring(0, 10));
    else setExpiryDate(null);
  }
  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  async function getFilteredTicketTypes(val) {
    if (val !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchTicketTypes({
      SearchList: [{ name: val }],
    });

    let a;
    res.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res, "name");
    setFilteredTicketTypeList(response);
  }
  async function getFilteredIssuedBy(val) {
    if (val !== "") {
      setIsIssuedBySearching(true);
    } else {
      setIsIssuedBySearching(false);
    }
    const res = await fetchCompanies({
      SearchList: [{ name: val }],
    });

    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setFilteredIssuedByList(response);
  }
  const unshareTicketWithUser = async () => {
    try {
      if (!unSharedEmail?.sharedWithEmail) return handleMenuClose();
      await NewUnshareTicketsWithUser(
        localStorage?.primaryEmail,
        unSharedEmail?.sharedWithEmail
      );
      props?.getUpdatedShareList();
      handleMenuClose();
    } catch (error) {
      console.error("Error while unsharing ticket:", error);
    }
  };
  console.log("props.ticket :::;---->", props.ticket);

  const onMessgaeClick = async () => {
    if (!unSharedEmail?.sharedWithUserId) return handleMenuClose();
    try {
      const saveListArray = [
        {
          Id: 0,
          ChatGroupId: 0,
          UserId: unSharedEmail?.sharedWithUserId,
          ModifiedBy: Number(localStorage.getItem("userId")),
          Muted: false,
        },
      ];
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };
      let result = await saveChatGroupUsers(chatGroupObj);
      if (result?.data?.length > 0) {
        let chatGroupId = result.data?.[0]?.objData?.chatGroupId;
        history.push({
          pathname: "/chat",
          state: {
            from: "New Chat",
            chatGroupId: chatGroupId,
            showInList: true,
          },
        });
      }
    } catch (error) {
      console.log("Error while creating a chat group :: ", error);
      alert("Something went wrong, Please try again later...");
    }
  };

  // Returns the appropriate modal header buttons based on props.config
  function modalHeaderButtons() {
    switch (props.config) {
      case "view-edit":
        return props.modalEditMode ? (
          <>
            <h4>Edit Ticket</h4>
            <div className="flex-container-horizontal">
              <IconButton color="secondary" onClick={handleDeleteButtonClick}>
                <DeleteIcon />
              </IconButton>
              <IconButton color="primary" onClick={handleSaveButtonClick}>
                <SaveIcon />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <h4>View Ticket</h4>
            <div className="flex-container-horizontal">
              <IconButton
                color="primary"
                onClick={() => setShareModalOpen(true)}>
                <ShareIcon />
              </IconButton>

              <IconButton
                color="primary"
                onClick={() => props.onEditModeChange(true)}>
                <EditIcon />
              </IconButton>
            </div>
          </>
        );
      case "edit-only":
        return (
          <>
            <h4>Add Ticket</h4>
            <IconButton
              color="primary"
              disabled={disableSaveButton}
              onClick={handleSaveButtonClick}>
              <SaveIcon />
            </IconButton>
          </>
        );

      case "view-only":
        return (
          <>
            <h4>View Ticket</h4>
          </>
        );

      default:
        break;
    }
  }
  console.log("shareTicketUserList", shareTicketUserList);

  const modalBody = (
    <div className="flex-container-vertical">
      {/* Buttons - all modals have at least the close button, additional buttons are generated */}
      <div className="modal-card-header-buttons">
        <IconButton color="primary" onClick={props.onModalClose}>
          <CloseIcon />
        </IconButton>

        {/* <h4> {isEditMode ? "Edit Ticket" : "View Tickets"}</h4> */}

        {modalHeaderButtons()}
      </div>
      {/* Content */}
      <div className="modal-card-body">
        <div className="flex-container-horizontal  resp-container-horizontal">
          <div
            className="flex-container-left"
            style={{ flex: 1, marginRight: "20px" }}>
            <div style={{ position: "relative" }}>
              {frontPictureUrl !== "" && props.modalEditMode ? (
                <span
                  style={{
                    position: "absolute",
                    right: "1em",
                    top: "1em",
                    display: "block",
                    cursor: "pointer",
                    height: "10px",
                  }}
                  onClick={() => {
                    handleCloseImage("frontImage");
                  }}>
                  <CloseIcon style={{ color: BIBlue }} />
                </span>
              ) : (
                <></>
              )}
              <div className="ticket-card-image">
                {frontPictureUrl !== "" ? (
                  <img src={frontPictureUrl} alt="Ticket front" />
                ) : (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "200px",
                    }}>
                    Upload front
                  </p>
                )}
              </div>
            </div>

            <div className="content-container">
              {props.modalEditMode ? (
                // Edit mode - picture upload buttons and textfields
                <>
                  {frontPictureFileName !== "" ? (
                    <div className="content-container">
                      <p style={{ fontSize: "14px", margin: 0 }}>
                        {frontPictureFileName}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="input-container">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      component="label"
                      startIcon={<UploadIcon />}>
                      Front Image
                      <input
                        id="frontPicture-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleChooseFile}
                        hidden
                      />
                    </Button>
                  </div>
                  <div>
                    <h5>TICKET INFO</h5>
                  </div>
                  <Divider></Divider>

                  <div className="input-container">
                    <Autocomplete
                      inputValue={ticketType}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.inputValue) {
                          setTicketType(newValue?.inputValue);
                          setIsNewTicketType(true);
                          setFlag(false);
                        } else {
                          setTicketTypeId(newValue?.id);
                          setFlag(false);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setTicketType(newInputValue);
                        setFlag(true);
                        if (newInputValue !== "") {
                          getFilteredTicketTypes(newInputValue);
                        } else {
                          getTicketTypes();
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;

                        const isExisting = options.some(
                          (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      options={
                        isSearching
                          ? filteredTicketTypeList
                          : fetchTicketTypeList
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.title}</li>
                      )}
                      style={{ flex: 1 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ticket Types"
                          variant={props?.edit ? "filled" : "outlined"}
                        />
                      )}
                      open={ticketType?.length > 1 && flag}
                    />
                  </div>

                  <div className="input-container">
                    <Autocomplete
                      inputValue={issuedBy}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.inputValue) {
                          setIssuedBy(newValue?.inputValue);
                          setIsNewIssuedBy(true);
                          setIssuedByFlag(false);
                        } else {
                          setIssuedById(newValue?.id);
                          setIssuedByFlag(false);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setIssuedBy(newInputValue);
                        setIssuedByFlag(true);
                        if (newInputValue !== "") {
                          getFilteredIssuedBy(newInputValue);
                        } else {
                          getIssuedBy();
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;

                        const isExisting = options.some(
                          (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      options={
                        isIssuedBySearching
                          ? filteredIssuedByList
                          : fetchIssuedByList
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.title}</li>
                      )}
                      style={{ flex: 1 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Issued By"
                          variant={props?.edit ? "filled" : "outlined"}
                        />
                      )}
                      open={issuedBy?.length > 1 && issuedByFlag}
                    />
                  </div>

                  <div className="input-container">
                    <TextField
                      id="ticketId-textfield"
                      label="ID# (Optional)"
                      variant="outlined"
                      fullWidth
                      defaultValue={ticketId}
                      onChange={(event) => setTicketId(event.target.value)}
                    />
                  </div>
                </>
              ) : (
                // View Mode - labels only
                <>
                  <p>
                    Ticket Type : <b>{ticketType}</b>
                  </p>
                  <p>
                    Issued By : <b>{issuedBy}</b>
                  </p>
                  {ticketId ? (
                    <p>
                      ID # : <b>{ticketId}</b>
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>

          <div
            className="flex-container-right"
            style={{ flex: 1, marginLeft: "20px" }}>
            <div style={{ position: "relative" }}>
              {backPictureUrl !== "" && props.modalEditMode ? (
                <span
                  style={{
                    position: "absolute",
                    right: "1em",
                    top: "1em",
                    display: "block",
                    cursor: "pointer",
                    height: "10px",
                  }}
                  onClick={() => {
                    handleCloseImage("backImage");
                  }}>
                  <CloseIcon style={{ color: BIBlue }} />
                </span>
              ) : (
                <></>
              )}
              <div className="ticket-card-image">
                {backPictureUrl !== "" ? (
                  <img src={backPictureUrl} alt="Ticket back" />
                ) : (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "200px",
                    }}>
                    {/* Upload back (Optional) */}
                    No back image
                  </p>
                )}
              </div>
            </div>

            <div className="content-container">
              {props.modalEditMode ? (
                // Edit mode - picture upload buttons and textfields
                <>
                  <div className="input-container">
                    {backPictureFileName !== "" ? (
                      <div className="content-container">
                        <p style={{ fontSize: "14px", margin: 0 }}>
                          {backPictureFileName}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      component="label"
                      startIcon={<UploadIcon />}>
                      Rear Image
                      <input
                        id="backPicture-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleChooseFile}
                        hidden
                      />
                    </Button>
                  </div>
                  <div style={{ marginTop: "35px" }}></div>
                  <Divider></Divider>
                  <div className="input-container">
                    <DateSelector
                      label="Issued Date"
                      value={issuedOn ? issuedOn : null}
                      setValue={handleIssuedOnChange}
                    />
                  </div>

                  <div className="input-container">
                    <DateSelector
                      label="Expiry Date (Optional)"
                      value={expiryDate ? expiryDate : null}
                      setValue={handleExpiryChange}
                    />
                  </div>
                </>
              ) : (
                // View mode - labels
                <>
                  <div>
                    <p>
                      Issued On :{" "}
                      <b>{moment(issuedOn).format("MMM D, YYYY")}</b>
                    </p>
                    {expiryDate ? (
                      <p>
                        Expires :{" "}
                        <b
                          style={
                            Date.parse(props.ticket.expiry) < new Date() // Set background as red if ticket is expired
                              ? { color: BIRed }
                              : {}
                          }>
                          {moment(expiryDate).format("MMM D, YYYY")}
                        </b>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {props.config === "view-only" ? ( // Display owner details based on config
          // <>
          //   <p>
          //   </p>
          //   <p>{props.ticket?.ticketOwner}</p>
          // </>
          <>
            <p>Owned By:</p>

            <div
              className="share-list-container"
              style={{
                marginTop: "10px",
                maxHeight: "200px",
                overflow: "auto",
              }}>
              <div
                // key={index}
                className="flex-container-horizontal"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 20px",
                }}>
                <Card
                  className={"add-bg-color remove-shadow"}
                  sx={{ maxWidth: 345 }}>
                  <CardHeader
                    onClick={() =>
                      history.push(
                        `/user-dashboard-profile/${props.ticket?.ticketOwnerId}`
                      )
                    }
                    avatar={
                      <Avatar
                        src={
                          props.ticket?.ticketOwnerPicture !== ""
                            ? props.ticket?.ticketOwnerPicture
                            : AvtarImage
                        }
                      />
                    }
                    title={props.ticket?.ticketOwner}
                    // subheader={user.sharedWithEmail}
                  />
                </Card>

                <IconButton
                  onClick={(event) => {
                    handleMenuClick(event, props.ticket);
                  }}>
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
          </>
        ) : null}

        {props.config === "view-edit" && shareTicketUserList.length > 0 ? (
          !props.modalEditMode && (
            <div style={{ marginTop: "10px" }}>
              <Divider></Divider>
              <p style={{ marginTop: "5px", marginBottom: "10px" }}>
                Shared With
              </p>
              <div
                className="share-list-container"
                style={{
                  marginTop: "10px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}>
                {shareTicketUserList.map((user, index) => (
                  <div
                    key={index}
                    className="flex-container-horizontal"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px 20px",
                    }}>
                    {/* <p>{user.sharedWithUser}<span style={{ color: "grey" }}> ({user.sharedWithEmail})</span></p> */}

                    <Card
                      className={"add-bg-color remove-shadow"}
                      sx={{ maxWidth: 345 }}>
                      <CardHeader
                        onClick={() =>
                          history.push(
                            `/user-dashboard-profile/${user?.sharedWithUserId}`
                          )
                        }
                        avatar={
                          <Avatar
                            src={
                              user.sharedWithUserPictureUrl != ""
                                ? user.sharedWithUserPictureUrl
                                : AvtarImage
                            }
                          />
                        }
                        title={user.sharedWithUser}
                        subheader={user.sharedWithEmail}
                      />
                    </Card>

                    <IconButton
                      onClick={(event) => {
                        handleMenuClick(event, user);
                      }}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>
          )
        ) : props.config === "view-edit" && !props.modalEditMode ? (
          <>
            <Divider></Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}>
              This Ticket has not been shared with anyone yet.
              <span
                onClick={() => setShareModalOpen(true)}
                style={{ cursor: "pointer", color: "blue", marginLeft: "1px" }}>
                <b>Share</b>
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );

  return (
    <>
      <AlertModal
        isOpen={emptyFieldsAlertOpen}
        onClose={() => setEmptyFieldsAlertOpen(false)}
        title={errorMsg}
      />

      <ShareModal
        modalOpen={shareModalOpen}
        onModalClose={() => setShareModalOpen(false)}
        tempConfig="previewTicket"
        config={props.config}
        shareTicketData={props.shareTicketData}
        setShareTicketButtonEnable={() => {}}
      />

      <Modal
        open={props.modalOpen}
        onClose={props.onModalClose}
        disableEnforceFocus>
        <div className="modal-center">
          <div className="modal-card">{isSaving ? <Loader /> : modalBody}</div>
        </div>
      </Modal>
      <Menu
        anchorEl={moreMenuAnchorEl}
        open={Boolean(moreMenuAnchorEl)}
        onClose={handleMenuClose}
        sd
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}>
        <MenuItem
          onClick={() => {
            onMessgaeClick();
          }}
          style={{ color: BIBlue }}
          dense>
          Message
        </MenuItem>
        {props.config !== "view-only" && (
          <MenuItem
            onClick={() => {
              unshareTicketWithUser();
            }}
            style={{ color: BIBlue }}
            dense>
            Unshare
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
