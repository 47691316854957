import React, { useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { savePost, savePostPicture } from "../../api/PostsApi";
import PromptModal from "../../components/PromptModal";
import Loader from "../../components/Loader";
import PostDialog from "../../components/post/PostDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BIBlue } from "../../assets/buildidColors";
import AlertModal from "../../components/AlertModal";

export default function PostInput(props) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [tempPic, setTempPic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);

  const handleClickOpen = () => {
    setUserComment("");
    setTempPic([]);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setUserComment("");
    setTempPic([]);
    setDialogOpen(false);
  };

  async function onDeletePic(index) {
    const updatedPic = tempPic.filter((i) => i !== index);
    setTempPic(updatedPic);
  }

  function handleDiscardClick() {
    setUserComment("");
    setTempPic([]);
    setDiscardModalOpen(false);
    setDialogOpen(false);
  }

  async function handlePostClick() {
    try {
      let allFilesValid = true;
      if (tempPic?.length > 0) {
        for (let i = 0; i < tempPic.length; i++) {
          if (!tempPic[i].type.startsWith("image/")) {
            allFilesValid = false;
            break;
          }
        }
      }

      if (!allFilesValid)
        return alert("Please select valid image files (JPEG, PNG, GIF, etc.).");
      setIsLoading(true);

      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: false,
        SaveList: [
          {
            Id: 0,
            FormatTypeId: 1,
            PostedByUserId: Number(localStorage.userId),
            AppId: 25,
            AppRecordId: 0,
            Message: userComment !== "" ? userComment : "<<<picture>>>",
            Reported: false,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      setDialogOpen(false);
      const postResult = await savePost(obj);
      if (tempPic.length !== 0) {
        await savePostImages(postResult[0].objData.id, props.productId);
      }
      setDialogOpen(false);
      await props.updatePosts();

      setTempPic([]);
      setUserComment("");
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  async function savePostImages(postId) {
    setIsLoading(true);
    try {
      for (let i = 0; i < tempPic.length; i++) {
        let data = new FormData();
        data.append("Id", 0);
        data.append("PostId", postId);
        data.append("ModifiedBy", Number(localStorage.userId));
        data.append("Pictures", tempPic[i]);
        setDialogOpen(false);
        await savePostPicture(data).catch((err) => {
          if (err === "Content Inappropriate") {
            setInappropriateImageAlert(true);
          }
          console.log("Error while saving the post pictures :: ", err);
        });
      }

      await props?.updatePosts();

      setTempPic([]);
      setUserComment("");
    } catch (error) {
      console.log("Error from save post pictures:", error);
    }
    setIsLoading(false);
  }

  function handleCancelButtonClick() {
    // If there are changes to the form, prompt user before discarding
    if (tempPic.length > 0 || userComment !== "") {
      setDiscardModalOpen(true);
    } else setDialogOpen(false);
  }

  return (
    <>
      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />

      <PromptModal
        isOpen={discardModalOpen}
        onClose={() => {
          setDiscardModalOpen(false);
          setDialogOpen(true);
        }}
        title="Are you sure you want to leave?"
        desc="Your review will be discarded."
        redButtonText="Discard"
        onRedButtonClick={handleDiscardClick}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Card
          style={{
            marginTop: "10px",
            paddingInline: 0,
            borderRadius: "10px",
          }}
          className="addpost-card"
        >
          <CardContent className="CardContent-bottom-Padding">
            <Grid container spacing={2} alignItems="center">
              {/* Profile Picture */}
              <Grid item>
                <Avatar alt="Profile Picture" src={localStorage.pictureUrl} />
              </Grid>
              {/* Search Bar */}
              <Grid item xs>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="What do you want to talk about?"
                  onClick={handleClickOpen}
                  // Add any necessary TextField props here
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <PostDialog
        isOpen={dialogOpen}
        onClose={handleClose}
        userComment={userComment}
        setUserComment={setUserComment}
        tempPic={tempPic}
        setTempPic={setTempPic}
        onDeletePic={onDeletePic}
        handlePostClick={handlePostClick}
        handleCancelButtonClick={handleCancelButtonClick}
      />
    </>
  );
}
