import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@mui/material/Grid";
import ConversationScreen from "./ConversationScreen";
import {
  getChatGroupDashboard,
  getMyChatGroupsUsers,
  getUnreadMessagecount,
} from "../../api/ChatApi";
import "../../assets/css/Chat.css";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { IconButton, TextField, InputAdornment, Stack } from "@mui/material";
import NewChatModal from "./NewChatModal";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ReportModal from "../../components/ReportModal";
import { useHistory } from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";

function ChatLayout(props) {
  const history = useHistory();
  const location = useLocation();

  const [userList, setUserlist] = useState([]);
  const [chatGroupUsers, setChatgroupUsers] = useState([]);
  const [isLoder, setIsloader] = useState(false);
  const [searchUser, setSearchuser] = useState("");
  const [showNewchat, setShownewchat] = useState(false);
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
  const [showChat, setShowchat] = useState(false);

  useEffect(() => {
    console.log("Hello i am in chat");
  }, []);

  useEffect(() => {
    if (location?.state?.state !== null) {
      getUserlist();
    }
  }, [location.state]);

  // Get user list
  const getUserlist = async () => {
    setIsloader(true);
    let chatGroupData = await getChatGroupDashboard(
      Number(localStorage.getItem("userId"))
    );
    if (chatGroupData.length > 0) {
      let getChatGroupData = chatGroupData.sort((a, b) => {
        console.log("1");
        let dateA = new Date(a.dateSent),
          dateB = new Date(b.dateSent);
        return dateB - dateA;
      });
      getChatGroupData = getChatGroupData.map((item) => {
        console.log("2");
        return {
          ...item,
          active:
            item.chatGroupId === location?.state?.chatGroupId ? "active" : "",
        };
      });
      getChatGroupData = getChatGroupData.filter(
        (item) => item.messageText !== ""
      );
      if (location?.state?.showInList) {
        console.log("3");
        let getItem = chatGroupData.filter(
          (item) => item.chatGroupId === Number(location?.state?.chatGroupId)
        );
        if (getItem?.length > 0) {
          console.log("4");
          if (getItem[0]?.messageText === "") {
            console.log("5");
            getChatGroupData = [getItem[0], ...getChatGroupData];
            getChatGroupData[0].active = "active";
          }
        }
      }
      // when we create new group show that on list
      if (location?.state?.createGroup === true) {
        console.log("6");
        let getGroup = chatGroupData.filter(
          (item) => item.chatGroupId === location?.state?.chatGroupId
        );
        if (getGroup.length > 0) {
          console.log("7");
          if (getGroup[0]?.messageText === "") {
            console.log("8");
            getChatGroupData = [getGroup[0], ...getChatGroupData];
            getChatGroupData[0].active = "active";
          }
        }
      }
      if (
        location?.state?.from === "New Chat" ||
        location?.state?.from === "Detail"
      ) {
        console.log("9");
        let getItem = getChatGroupData.filter(
          (item) => item.chatGroupId === location?.state?.chatGroupId
        );
        if (getItem?.length != 0) {
          console.log("10");
          getGroupusers(getItem[0]);
        } else {
          console.log("11");
          let getItem = getChatGroupData.filter(
            (item) =>
              item.groupUserId1 === Number(localStorage.getItem("userId"))
          );
          if (getItem?.length > 0) {
            console.log("12");
            getGroupusers(getItem[0]);
          }
        }
      } else {
        console.log("13");
        getChatGroupData[0].active = "active";
        getGroupusers(getChatGroupData[0]);
      }
      console.log("14", getChatGroupData);
      localStorage.setItem("userList", JSON.stringify(getChatGroupData));
      setUserlist(getChatGroupData);
    }
  };

  // Get My ChatGroupusers
  const getGroupusers = async (item) => {
    if (item?.groupUserId1 === Number(localStorage.getItem("userId"))) {
      const currentUser = {
        firstName: item?.mostRecentUserFirstName1,
        lastName: item?.mostRecentUserLastName1,
        chatGroupId: item?.chatGroupId,
        userId: item.groupUserId1,
      };
      setChatgroupUsers([currentUser]);
    } else {
      const chatGroupUsers = await getMyChatGroupsUsers(
        localStorage.getItem("userId"),
        item?.chatGroupId
      );
      if (chatGroupUsers?.length > 0) {
        setChatgroupUsers(chatGroupUsers);
      } else {
        // If the prev user is deleted then
        let currentUser = {
          chatGroupId: item?.chatGroupId,
          firstName: item?.fromUserFirstName,
          lastName: item?.fromUserLastName,
        };
        setChatgroupUsers([currentUser, ...chatGroupUsers]);
        console.log("chatGroupUsers", chatGroupUsers, "item", item);
      }
    }
    localStorage.setItem("nrOfUsersInGroup", item?.nrOfUsersInGroup);
    localStorage.setItem("chatGroupId", item?.chatGroupId);
  };

  const handleSearchUser = () => {
    const filteredUsers = userList.filter((user) =>
      user.groupUserFirstName1.toLowerCase().includes(searchUser.toLowerCase())
    );
    setUserlist(filteredUsers);
  };

  const selectCurrentItem = async (item) => {
    console.log("prev chatgroupid ", localStorage.getItem("chatGroupId"));
    console.log("prev userid ", localStorage.getItem("userId"));
    history.push({
      pathname: "/chat",
      state: {
        from: "New Chat",
        chatGroupId: item?.chatGroupId,
      },
    });
    localStorage.setItem("nrOfUsersInGroup", item?.nrOfUsersInGroup);
    localStorage.setItem("chatGroupId", item?.chatGroupId);
    setSearchuser("");
    setShowchat(true);
  };

  const searchOnchange = async (event) => {
    const searchText = event.target.value;
    setSearchuser(searchText);
    if (searchText.trim() === "") {
      await getUserlist();
    } else {
      const filteredUsers = userList.filter((user) =>
        user.groupUserFirstName1
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      setUserlist(filteredUsers);
    }
  };

  const onKeypress = () => {};

  const openNewchat = () => {
    setShownewchat(true);
  };

  const GetDatetime = (props) => {
    let datetime;
    if (props?.date !== null) {
      const momentDate = moment(props.date);
      if (!moment.isMoment(props.date)) datetime = moment(props.date);
      if (momentDate.isSame(moment(), "day")) {
        datetime = moment.parseZone(props.date).local().format("h:mm A");
      } else if (momentDate.isSame(moment().subtract(1, "d"), "day")) {
        datetime = "Yesterday";
      } else if (momentDate.isSame(moment(), "week")) {
        datetime = moment.parseZone(props.date).local().format("dddd");
      } else {
        datetime = moment.parseZone(props.date).local().format("YYYY-MM-DD");
      }
    } else {
      datetime = "";
    }
    return <span className="msg-time">{datetime}</span>;
  };

  const renderLastmessage = (item) => {
    const isImage =
      item?.messageText?.match(/^http.*\.(jpeg|jpg|gif|png)$/) != null;
    return (
      <span className="last-message">
        {item.messageText != ""
          ? `${
              item.fromUserFirstName + " " + item.fromUserLastName ==
              localStorage.firstName + " " + localStorage.lastName
                ? "You"
                : item.fromUserFirstName.trim()
            }: ${isImage ? "Photo" : item.messageText.trim()}`
          : isImage
          ? "Photo"
          : item.messageText.trim()}
      </span>
    );
  };

  return (
    <>
      {props?.fabButton ? (
        <>
          <Grid
            item
            xs={isLoder === true ? 12 : window.innerWidth > 992 ? 9 : 12}
            sm={12}
            md={12}
            lg={isLoder === true ? 12 : 9}
            xl={isLoder === true ? 12 : 9}
            style={{ display: showChat ? "block" : "" }}
            className="chat-grid"
          >
            <ConversationScreen
              setReportModalOpenSearch={setReportModalOpenSearch}
              setIsloader={setIsloader}
              chatGroupUsers={chatGroupUsers}
              isLoder={isLoder}
              setUserlist={setUserlist}
              userList={userList}
              setShowchat={setShowchat}
              showChat={showChat}
              fabButton={props?.fabButton}
              setExpanded={props?.setExpanded}
              expanded={props?.expanded}
              handleFabMenuClose={props?.handleFabMenuClose}
            />
          </Grid>
        </>
      ) : (
        <div className="page chat-screen">
          <div className="page-content">
            <Grid container spacing={2} className="chatgrid-main">
              {!isLoder && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  className="userlist-grid"
                  style={{
                    display: showChat === false ? "block" : "",
                    backgroundColor: "#ffeebf",
                  }}
                >
                  <TextField
                    value={searchUser}
                    onChange={searchOnchange}
                    onKeyPress={onKeypress}
                    placeholder={"Search..."}
                    color="primary"
                    size={"small"}
                    className="user-search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleSearchUser}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            // Only show "X" button when field not empty
                            searchUser !== "" ? (
                              <IconButton onClick={() => setSearchuser("")}>
                                <CloseIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="search-field" onClick={openNewchat}>
                    New Chat
                  </span>
                  <List className="chat-userlist">
                    {userList.map((item, index) => (
                      <div
                        className={`user-item ${item?.active}`}
                        key={index}
                        onClick={() => selectCurrentItem(item)}
                      >
                        <ListItem className="list-item" button key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <ListItemIcon style={{ minWidth: "42px" }}>
                              <Avatar src={item?.groupUserPictureUrl1} />
                            </ListItemIcon>
                            <Stack direction={"column"}>
                              <ListItemText className="list-item-text">
                                {item?.groupUserFirstName1.trim()}
                                {item?.nrOfUsersInGroup <= 2 &&
                                  ` ${item?.groupUserLastName1?.trim()}`}
                                {item?.me && " (me)"}
                                {item?.nrOfUsersInGroup == 3 &&
                                  ` and ${item?.groupUserFirstName2.trim()}`}
                                {item?.nrOfUsersInGroup > 3 &&
                                  `, ${item?.groupUserFirstName2.trim()} and ${
                                    item?.nrOfUsersInGroup - 3
                                  } others`}
                              </ListItemText>
                              {renderLastmessage(item)}
                            </Stack>
                          </div>
                          <Stack direction={"column"} alignItems={"center"}>
                            <GetDatetime date={item?.dateSent} />
                            {Number(localStorage.getItem("chatGroupId")) ===
                            item?.chatGroupId ? (
                              <></>
                            ) : (
                              item?.unreadMessagesNr !== 0 && (
                                <div className="unread-msg">
                                  <span className="unreadmsg-count">
                                    {item?.unreadMessagesNr}
                                  </span>
                                </div>
                              )
                            )}
                          </Stack>
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </Grid>
              )}
              <Grid
                item
                xs={isLoder === true ? 12 : window.innerWidth > 992 ? 9 : 12}
                sm={12}
                md={12}
                lg={isLoder === true ? 12 : 9}
                xl={isLoder === true ? 12 : 9}
                style={{ display: showChat ? "block" : "" }}
                className="chat-grid"
              >
                <ConversationScreen
                  setReportModalOpenSearch={setReportModalOpenSearch}
                  setIsloader={setIsloader}
                  chatGroupUsers={chatGroupUsers}
                  isLoder={isLoder}
                  setUserlist={setUserlist}
                  userList={userList}
                  setShowchat={setShowchat}
                  showChat={showChat}
                />
              </Grid>
            </Grid>
            {showNewchat && (
              <NewChatModal
                showNewchat={showNewchat}
                setShownewchat={setShownewchat}
              />
            )}
            <ReportModal
              name={""}
              recordId={Number(localStorage.getItem("chatGroupId"))}
              isOpen={reportModalOpenSearch}
              onClose={() => {
                setReportModalOpenSearch(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ChatLayout;
