import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { messaging } from "./firebase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";    

const Toastify = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const handleNotificationClick = (item) => {
    if (item?.data !== undefined) {
      let obj = item?.data["gcm.n.link"];
      const inputString = obj;
      const regex = /screenName=([^&]+)/;
      const match = inputString.match(regex);
      if (match !== null) {
        const screenName = match[1];
        switch (screenName) {
          case "DetailPost":
            history.push("/dashboard", {
              comment: item?.data?.PostId,
              from: "DashboardNotifications",
              fromTo: "getNotify",
              refresh: new Date(),
            });
            break;
          case "ConversationScreen":
            history.push({
              pathname: "/chat",
              state: {
                from: "New Chat",
                chatGroupId: Number(item?.data?.ChatGroupId),
                showInList: false,
              },
            });
            break;
          case "ContactProfile":
            history.push(`/user-dashboard-profile/${item?.data?.UserId}`);
            // history.push({ pathname: "/user-dashboard-profile", state: { from: "DashboardNotifications", UserId: Number(item?.data?.UserId) }, });
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    console.log("useEffect--messaging-------", messaging);
    const unsubscribe = messaging.onMessage((payload) => {
      console.log("onMessage Listener", payload);
      const { title, body } = payload.notification || {};
      addToast(`${title === undefined ? "BuildID" : title}: ${body}`, {
        appearance: "info",
        onClick: () => handleNotificationClick(payload),
        style: { cursor: "pointer", marginTop: "8px" },
        autoDismiss: true,
      });
    });
    return () => {
      // Cleanup when the component is unmounted
      unsubscribe();
    };
  }, [addToast]);

  return <></>;
};

export default Toastify;
