import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { styled, alpha } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CircularProgress,
  Fab,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BuildIDLogo from "../assets/images/Build-ID-Transparent.png";
import SitesTutorial from "./Tutorial/SitesTutorial";
import DashboardTutorial from "./Tutorial/DashboardTutorial";
import MontageTutorial from "./Tutorial/MontageTutorial";
import TicketTutorial from "./Tutorial/TicketsTutotial";
import JobPostingTutorial from "./Tutorial/JobPostingTutorial";
import CarpoolTutorial from "./Tutorial/CarpoolTutorial";
import ResumeBuilderTutorial from "./Tutorial/ResumeBuilderTutorial";
import ContentSearchTutorial from "./Tutorial/ContentSearchTutorial";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useActions } from "./ActionsContext";
import packageInfo from "../../package.json";
import Notification from "../pages/notifications/Notification";
import { useEffect } from "react";
import { messaging } from "../firebase";
import { useToasts } from "react-toast-notifications";
import { BIBlue, BIRed } from "../assets/buildidColors";
import ChatLayout from "../pages/connect/ChatLayout";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PlaceHolderImage from "../assets/images/AvatarImage.jpeg";
import {
  fetchClientEmployee,
  updateClientEmployeeInfo,
} from "../api/ClientApi";
import { userDeviceLogout } from "../api/UserApi";
import ReferralPage from "./ReferralPage";
import { getDbValue } from "../api/RestClient";
import MenuIcon from "@mui/icons-material/Menu";
import ReportModal from "./ReportModal";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // width: "100px",
      height: "19px",
    },
  },
}));

const TopAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [tutorialIndex, setTutorialIndex] = useState("");
  const [tutorialType, setTutorialType] = useState(null);
  const [searchtext, setSearchtext] = useState("");
  const location = useLocation();
  const pathname = location?.pathname;
  const history = useHistory();
  const { addToast } = useToasts();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [openReferModal, setOpenReferModal] = useState(false);

  const isMoreMenuOpen = Boolean(moreMenuAnchorEl);
  const { builderBucksBalance, nrOfSites, workYears, numUniqueCompanies } =
    useActions();
  const [notifyAnchorEl, setNotifyAnchorEl] = useState(null);
  const isNotificationOpen = Boolean(notifyAnchorEl);
  const [expanded, setExpanded] = useState(false);
  const [chatFlag, setChatFlag] = useState(false);
  const [referLink, setReferLink] = useState("");
  const [mobileProfileFlag, setMobileProfileFlag] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportPostName, setRepostPostName] = useState("");
  // Client Account select
  useEffect(() => {
    if (localStorage?.newUser === "true") {
      onTutorialClick();
      localStorage.setItem("newUser", false);
    }
  }, []);

  // ====================================== Account END ==============================================

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    // setMobileProfileFlag(false);
  };

  const onChangeEditProfile = () => {
    history.push("/profile");
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleSettingClick = () => {
    history.push("/settings");
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleEarnBuilderBucksClick = () => {
    history.push("/rewards/earn-builderbucks");
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleSiteClick = () => {
    history.push("/new-map");
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNotificationOpen = (event) => {
    setNotifyAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotifyAnchorEl(null);
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleToastClick = (payload) => {
    history.push("/chat");
  };

  useEffect(() => {
    const unsubscribe = messaging.onMessage((payload) => {
      const { title, body } = payload.notification || {};
      addToast(`${title}: ${body}`, {
        appearance: "info",
        onClick: () => handleToastClick(payload),
        style: { cursor: "pointer", marginTop: "8px" },
        autoDismiss: true,
      });
    });
    return () => {
      // Cleanup when the component is unmounted
      unsubscribe();
    };
  }, [addToast]);

  const handleFabMenuOpen = () => {
    setChatFlag(true);
  };

  const handleFabMenuClose = () => {
    setExpanded(false);
    setChatFlag(false);
  };

  async function handleLogOut() {
    let deviceId = localStorage.getItem("deviceId");
    if (deviceId !== null) {
      await userDeviceLogout(deviceId);
    }
    // Reset localStorage
    localStorage.clear();
    props.setUserId(-1);
    // localStorage.setItem("userId",-1);
    history.push("/");
    // Reset local state
    // setUserId(-1);
    // setProfilePictureUrl("");
    // setFirstName("");
    // setLastName("");
    // setTradeName("");
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      className={
        pathname === "/dashboard" ||
        pathname === "/new-map" ||
        pathname === "/montages" ||
        pathname === "/tickets" ||
        pathname === "/job-postings" ||
        pathname === "/carpool" ||
        pathname === "/resume-builder" ||
        pathname === "/content-search"
          ? "profile-menuCard-with-tutorial"
          : "profile-menuCard-withOut-tutorial"
      }>
      <div className="profile-menu">
        <MenuItem className="profile-item">
          <Stack
            width={"-webkit-fill-available"}
            flexDirection={"row"}
            style={{ gap: "10px" }}
            onClick={() => onChangeEditProfile()}>
            <Avatar
              className="profile-icon"
              style={{ height: "37px", width: "37px" }}
              alt="Remy Sharp"
              src={props.profilePictureUrl}
            />
            <Stack>
              <h5 className="profie-name">{`${props?.firstName} ${props?.lastName}`}</h5>
              <span className="trade-name">
                {typeof props?.tradeName === "undefined" ||
                props?.tradeName === "null" ||
                props?.tradeName === ""
                  ? "No Trade"
                  : props.tradeName}
              </span>
            </Stack>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            marginTop={"5px"}
            justifyContent={"space-between"}
            width={"100%"}>
            <div className="builderbuks-count">
              <p>
                <b>{builderBucksBalance}</b>
              </p>
              <span className="builderbuks-fontSizes">BuilderBuks</span>
            </div>
            <span
              className="earn-builder"
              onClick={handleEarnBuilderBucksClick}>
              Earn BuilderBucks
            </span>
          </Stack>
          <Stack
            className="site-info-stack"
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            marginTop={"10px"}>
            <Stack className="sites-count">
              <Stack className="sites-count" onClick={handleSiteClick}>
                <h3 className="count">{nrOfSites}</h3>
                <span
                  className="builderbuks-fontSizes"
                  style={{ color: "#ffffff" }}>
                  Sites
                </span>
              </Stack>
            </Stack>
            <Stack className="sites-count">
              <Stack className="sites-count" onClick={handleSiteClick}>
                <h3 className="count">{workYears}</h3>
                <span
                  className="builderbuks-fontSizes"
                  style={{ color: "#ffffff" }}>
                  Years
                </span>
              </Stack>
            </Stack>
            <Stack className="sites-count">
              <Stack className="sites-count" onClick={handleSiteClick}>
                <h3 className="count">{numUniqueCompanies}</h3>
                <span
                  className="builderbuks-fontSizes"
                  style={{ color: "#ffffff" }}>
                  Companies
                </span>
              </Stack>
            </Stack>
          </Stack>
        </MenuItem>
        <MenuItem
          className="menu-list-height"
          onClick={onChangeEditProfile}
          style={{ marginTop: "5px" }}>
          <ListItemIcon>
            <AccessTimeIcon fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>
            <Typography className="builderbuks-fontSizes">
              Account Activity
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem className="menu-list-height" onClick={handleSettingClick}>
          <ListItemIcon>
            <SettingsIcon fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>
            <Typography className="builderbuks-fontSizes">Settings</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem className="menu-list-height" onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography className="builderbuks-fontSizes">Logout</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem className="menu-list-height">
          <Stack className="last-menu" style={{}}>
            <a
              target={"_blank"}
              style={{ textDecoration: "none" }}
              rel="noreferrer"
              href="https://www.iubenda.com/privacy-policy/90161377">
              {" "}
              <h5 className="page-link"> Privacy Policy</h5>
            </a>
            <a
              target={"_blank"}
              style={{ textDecoration: "none" }}
              rel="noreferrer"
              href="https://www.iubenda.com/terms-and-conditions/90161377">
              {" "}
              <h5 className="page-link">Terms & Conditions</h5>
            </a>
            <h5 className="page-link">Build v{packageInfo?.version}</h5>
          </Stack>
        </MenuItem>
      </div>
    </Menu>
  );

  const renderNotification = (
    <Menu
      anchorEl={notifyAnchorEl}
      open={isNotificationOpen}
      onClose={handleNotificationClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      // style={{ left: '105px' }}
      style={{
        left:
          pathname === "/dashboard" ||
          pathname === "/new-map" ||
          pathname === "/montages" ||
          pathname === "/tickets" ||
          pathname === "/job-postings" ||
          pathname === "/carpool" ||
          pathname === "/resume-builder" ||
          pathname === "/content-search"
            ? "95px"
            : "60px",
        top: "5px",
      }}
      classes={{ paper: "Notification-menu-color" }}>
      <div className="Notification-menu experience-form">
        <Notification handleNotificationClose={handleNotificationClose} />
      </div>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      style={{
        left: "25px",
        top: "4px",
        padding: "5px 0px",
      }}>
      <MenuItem
        onClick={() => {
          history.push("/chat");
          handleMobileMenuClose();
        }}
        sx={{ padding: "0px 5px", minHeight: "34px" }}>
        <IconButton size="small" color="inherit">
          <Badge badgeContent={0} color="error">
            <QuestionAnswerIcon fontSize="small" />
          </Badge>
          {props.chatNotification === true && (
            <span className="chat-notification"></span>
          )}
        </IconButton>
        <Typography sx={{ fontSize: "0.675rem" }} variant="body2">
          Messages
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push("/notifications");
          handleMobileMenuClose();
        }}
        sx={{ padding: "0px 5px", minHeight: "34px" }}>
        <IconButton size="small" color="inherit">
          <Badge badgeContent={0} color="error">
            <NotificationsIcon fontSize="small" />
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "0.675rem" }} variant="body2">
          Notifications
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleProfileMenuOpen(e);
          handleMobileMenuClose(e);
          setMobileProfileFlag(true);
        }}
        sx={{ padding: "0px 5px", minHeight: "34px" }}>
        <IconButton size="small" sx={{ padding: "0px 5px" }}>
          <Avatar
            sx={{ width: 24, height: 24 }}
            alt="Remy Sharp"
            src={props.profilePictureUrl}
          />
        </IconButton>
        <Typography variant="body2" sx={{ fontSize: "0.675rem" }}>
          {props.firstName + " " + props.lastName}
        </Typography>
      </MenuItem>
    </Menu>
  );

  const onTutorialClick = () => {
    if (pathname === "/dashboard") {
      setTutorialIndex(1);
      return setTutorialType("dashboard");
    }
    if (pathname === "/new-map") {
      setTutorialIndex(1);
      return setTutorialType("sites");
    }
    if (pathname === "/montages") {
      setTutorialIndex(1);
      return setTutorialType("montages");
    }
    if (pathname === "/tickets") {
      setTutorialIndex(1);
      return setTutorialType("ticket");
    }
    if (pathname === "/job-postings") {
      setTutorialIndex(1);
      return setTutorialType("job-postings");
    }
    if (pathname === "/carpool") {
      setTutorialIndex(1);
      return setTutorialType("carpool");
    }
    if (pathname === "/resume-builder") {
      setTutorialIndex(1);
      return setTutorialType("resume-builder");
    }
    if (pathname === "/content-search") {
      setTutorialIndex(1);
      return setTutorialType("content-search");
    }
    setTutorialIndex(0);
    return setTutorialType(null);
  };

  const onReportClick = () => {
    setReportModalOpen(true);
    setMoreMenuAnchorEl(false);
    if (pathname === "/dashboard") {
      setRepostPostName("post");
    } else if (pathname === "/new-map") {
      setRepostPostName("sites");
    } else if (pathname === "/montages") {
      setRepostPostName("montages");
    } else if (pathname === "/tickets") {
      setRepostPostName("ticket");
    } else if (pathname === "/job-postings") {
      setRepostPostName("job-postings");
    } else if (pathname === "/carpool") {
      setRepostPostName("carpool");
    } else if (pathname === "/resume-builder") {
      setRepostPostName("resume-builder");
    } else if (pathname === "/content-search") {
      setRepostPostName("content-search");
    } else if (pathname === "/timesheets") {
      setRepostPostName("timesheets");
    } else if (pathname === "/expenses") {
      setRepostPostName("expenses");
    } else if (pathname === "/rewards/store/") {
      setRepostPostName("store");
    } else if (pathname === "/rewards/earn-builderbucks/") {
      setRepostPostName("earn-builderbucks");
    } else if (pathname === "/rewards/account-activity/") {
      setRepostPostName("account-activity");
    } else if (pathname === "/rewards/earn-rewards") {
      setRepostPostName("earn-rewards");
    } else if (pathname === "/buy-sell") {
      setRepostPostName("buy-sell");
    } else if (pathname === "/chat") {
      setRepostPostName("chat");
    }
  };

  const showTutorialBtn = () => {
    if (
      pathname === "/dashboard" ||
      pathname === "/new-map" ||
      pathname === "/montages" ||
      pathname === "/tickets" ||
      pathname === "/job-postings" ||
      pathname === "/carpool" ||
      pathname === "/resume-builder" ||
      pathname === "/content-search"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showReportBtn = () => {
    if (
      pathname === "/dashboard" ||
      pathname === "/new-map" ||
      pathname === "/montages" ||
      pathname === "/tickets" ||
      pathname === "/chat" ||
      pathname === "/expenses" ||
      pathname === "/contacts" ||
      pathname === "/buy-sell" ||
      pathname === "/job-postings" ||
      pathname === "/carpool" ||
      pathname === "/resume-builder" ||
      pathname === "/content-search" ||
      pathname === "/rewards/store/" ||
      pathname === "/rewards/earn-builderbucks/" ||
      pathname === "/rewards/account-activity/" ||
      pathname === "/rewards/earn-rewards"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const searchOnchange = (e) => {
    setSearchtext(e.target.value);
  };

  const searchContent = (e) => {
    if (e.key === "Enter") {
      history.push({
        pathname: "/content-search",
        state: {
          search: searchtext,
        },
      });
    }
  };

  React.useEffect(() => {
    let url = location?.pathname.split("/");
    if (url[1] !== "content-search") {
      setSearchtext("");
    }
  }, [location?.pathname]);

  const navigateTochat = () => {
    history.push("/chat");
    props.setChatnotification(false);
  };
  // let referLink = "";
  const handleReferClick = () => {
    setOpenReferModal(true);
    getDbValue();
    if (localStorage?.db === "3") {
      setReferLink(
        `https://bimiscwebapi-web-user-development.azurewebsites.net/refer/${localStorage.userId}`
      );
      // setReferLink(`http://192.168.1.47:3000/refer/${localStorage.UserReferralCode}`);
    } else if (localStorage?.db === "2") {
      setReferLink(
        `https://bimiscwebapi-web-user-staging.azurewebsites.net/refer/${localStorage.userId}`
      );
    } else if (localStorage?.db === "1") {
      setReferLink(
        `https://bimiscwebapi-web-user-production.azurewebsites.net/refer/${localStorage.userId}`
      );
    }
    setMoreMenuAnchorEl(null);
  };
  //  `https://play.google.com/store/apps/details?id=com.mybuilderid&referrer=CampaingId=${localStorage.userId}`
  const handleCloseReferModal = () => {
    setOpenReferModal(false);
  };

  return (
    <Box>
      <AppBar position="static" className="topbar-main">
        <Toolbar className="topbar-toolbar">
          <div className="drawer-header-div">
            <Link to="/dashboard" className="drawer-header-link">
              <img className="drawer-header-logo" src={BuildIDLogo} alt="" />
            </Link>
          </div>
          <div className="dashboard-searchbar">
            <Search
              sx={{ margin: 0 }}
              className="search-buildid"
              onKeyDown={(e) => searchContent(e)}>
              <SearchIconWrapper>
                <SearchIcon color="#cccccc " />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchtext}
                placeholder="Search BuildID"
                inputProps={{ "aria-label": "search" }}
                onChange={searchOnchange}
                size="small"
              />
            </Search>
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "none", md: "flex", marginRight: "10px" } }}>
            {JSON.parse(localStorage.getItem("clientList")) && (
              <FormControl style={{ marginTop: "3px" }} fullWidth>
                <Select
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  style={{
                    backgroundColor: "transparent",
                    height: "30px",
                    border: "none",
                  }}
                  value={props?.preClient}
                  label="Account">
                  {JSON.parse(localStorage.getItem("clientList")).map(
                    (client) => {
                      return (
                        <MenuItem
                          onClick={() => props?.handleClientClick(client)}
                          value={client.ClientId}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <Avatar
                              style={{}}
                              sx={{ width: 20, height: 20 }}
                              src={client?.ClientLogo || PlaceHolderImage}
                            />

                            <p style={{ marginLeft: "10px", marginTop: "5px" }}>
                              {client?.OrganizationName || client?.ClientName}
                            </p>
                          </div>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            )}

            <IconButton
              size="small"
              color="inherit"
              style={{ color: "#083db8", marginRight: "10px" }}
              onClick={() => navigateTochat()}>
              <Badge badgeContent={0} color="error">
                <QuestionAnswerIcon fontSize="medium" />
              </Badge>
              {props.chatNotification === true && (
                <span className="chat-notification"></span>
              )}
            </IconButton>

            <IconButton
              size="small"
              color="inherit"
              style={{ color: "#083db8", marginRight: "10px" }}
              href="https://mybuilderid.com/faqapp"
              target="_blank">
              <Badge badgeContent={0} color="error">
                <HelpIcon fontSize="medium" />
              </Badge>
            </IconButton>

            <IconButton
              size="small"
              color="inherit"
              style={{ color: "#083db8", marginRight: "10px" }}
              onClick={handleNotificationOpen}>
              <Badge badgeContent={0} color="error">
                <NotificationsIcon fontSize="medium" />
              </Badge>
            </IconButton>
            <Stack
              direction="row"
              alignItems={"center"}
              sx={{ cursor: "pointer", marginRight: "10px" }}
              margin={"0px 5px"}
              marginRight={showReportBtn() ? "0px" : "15px"}
              spacing={2}
              onClick={handleProfileMenuOpen}>
              <Avatar
                alt="Remy Sharp"
                style={{ height: "30px", width: "30px" }}
                src={props.profilePictureUrl}
              />
            </Stack>

            {/* {showTutorialBtn() && ( */}
            {/* <IconButton
              size="small"
              style={{ color: "#083db8" }}
              onClick={onTutorialClick}>
              <MoreIcon fontSize="medium" />
            </IconButton> */}
            {showReportBtn() && (
              <IconButton
                aria-label="settings"
                onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}>
                <MoreIcon />
              </IconButton>
            )}
            <Menu
              anchorEl={moreMenuAnchorEl}
              open={Boolean(moreMenuAnchorEl)}
              onClose={() => setMoreMenuAnchorEl(null)}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}>
              {showTutorialBtn() && (
                <MenuItem
                  onClick={onTutorialClick}
                  style={{ color: BIBlue }}
                  dense>
                  Tutorial
                </MenuItem>
              )}
              {pathname === "/dashboard" ? (
                <MenuItem
                  style={{ color: BIBlue }}
                  onClick={handleReferClick}
                  dense>
                  Refer Someone To BuildId
                </MenuItem>
              ) : null}
              <MenuItem onClick={onReportClick} style={{ color: BIRed }} dense>
                Report
              </MenuItem>
            </Menu>
            <ReferralPage
              open={openReferModal}
              referLink={referLink}
              onClose={handleCloseReferModal}
            />
            {/* )} */}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
          <IconButton
            className="drawer-menu-icon"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props?.handleDrawerToggle}
            sx={{ display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <ReportModal
          isOpen={reportModalOpen}
          onClose={() => setReportModalOpen(false)}
          name={reportPostName}
          recordId={
            reportPostName == "earn-rewards"
              ? Number(localStorage?.quizQuestionId)
              : 0
          }
        />
      </AppBar>
      {/* {pathname === "/chat" || pathname.includes("/client") || pathname === "/new-map" ? null :
        <div className="fab-icon-dashboard">
          <Fab size="small"
            style={{ backgroundColor: '#ffbc0a', color: BIBlue }}
            aria-label="add"
            onClick={handleFabMenuOpen}
          >
            <QuestionAnswerIcon fontSize="small" />
          </Fab>
        </div>} */}
      {chatFlag ? (
        <div>
          <div className={expanded ? "expanded-card" : "expanding-card"}>
            <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
              <ChatLayout
                fabButton={true}
                expanded={expanded}
                setExpanded={setExpanded}
                handleFabMenuClose={handleFabMenuClose}
              />
            </Card>
          </div>
        </div>
      ) : null}
      {/* {!mobileProfileFlag
        ? renderMenu
        : renderMobileMenu
        ? renderMenu
        : renderMobileMenu} */}
      {renderMobileMenu}
      {renderMenu}
      {renderNotification}
      {/* {tutorialIndex && tutorialType === "sites" && ( */}
      {tutorialType === "sites" && (
        <SitesTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 5 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "dashboard" && ( */}
      {tutorialType === "dashboard" && (
        <DashboardTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 3 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "montages" && ( */}
      {tutorialType === "montages" && (
        <MontageTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 6 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "ticket" && ( */}
      {tutorialType === "ticket" && (
        <TicketTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 4 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "job-postings" && ( */}
      {tutorialType === "job-postings" && (
        <JobPostingTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 3 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "carpool" && ( */}
      {tutorialType === "carpool" && (
        <CarpoolTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 4 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "resume-builder" && ( */}
      {tutorialType === "resume-builder" && (
        <ResumeBuilderTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 4 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
      {/* {tutorialIndex && tutorialType === "content-search" && ( */}
      {tutorialType === "content-search" && (
        <ContentSearchTutorial
          onPress={() => {
            setTutorialIndex((p) => (p >= 3 ? "" : p + 1));
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
    </Box>
  );
};

export default TopAppBar;
