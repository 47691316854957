import React, { useEffect, useState } from 'react'
import { ClientHeader } from '../ClientHeader'
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import AddClientJobModal from './AddClientJobModal';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteClientTimesheetJobImages, DeleteClientTimesheetJobs, FetchClientTimesheetJobs, SaveClientTimesheetJobImages, SaveClientTimesheets } from '../../../api/ClientApi';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const AddClientTimesheetIndividual = () => {

    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? '';
    let userId = localStorage.getItem("userId");

    let location = useLocation();
    let history = useHistory();


    const [data, setData] = useState([]);
    const [date, setDate] = useState(location?.state?.headerData?.date ?? new Date());
    const [timesheetId, setTimesheetId] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [addedJobList, setAddedJobList] = useState([]);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [allExistingPics, setAllExistingPics] = useState([]);

    const [deleteImageIds, setDeleteImageIds] = useState([]);

    let clientId = localStorage.getItem("clientId");


    useEffect(() => {
        if (location?.state?.from === "viewindividualtimesheet") {
            let timesheetData = location?.state?.data ?? [];
            setData(timesheetData);
            let existing_pics = []
            setTimesheetId(timesheetData?.[0]?.clientTimesheetId);
            let allLists = [];
            timesheetData.map((item) => {

                // const parsedUDField = JSON.parse(item.udFields);
                // const transformedUDField = Object.fromEntries(
                //     Object.entries(parsedUDField).map(([key, value]) => [parseInt(key), value === 1])
                // );
                // const transformedUDFieldArr = item.udFieldsList.map((ele, index) => ({ id: ele.id, label: ele.label, value: ele.value }))

                const jobId = item.clientJobId;
                const jobObj = {
                    Id: item.id,
                    ClientJobId: item.clientJobId,
                    JobName: `${item.clientJobCode} - ${item.clientJobName}`,
                    Notes: item.notes,
                    UDFields: item.udFields,
                    UDFieldsList: item.udFieldsList,
                    PictureList: item.pictureList,
                    Deleted: false,

                }
                const timesheetJob = { ...jobObj };

                const empObj = item.timesheetEmployeesList.find((ele, index) => ele.clientJobId === jobId);
                const timesheetEmpObj = { ...empObj };

                const timesheetEmp = {
                    Id: timesheetEmpObj.id,
                    ClientJobId: timesheetEmpObj.clientJobId,
                    ClientEmployeeId: timesheetEmpObj.clientEmployeeId,
                    Mileage: timesheetEmpObj.mileage.toFixed(1),
                    MileageUnit: timesheetEmpObj.mileageUnit,
                    UDFields: timesheetEmpObj.udFields,
                    Deleted: false,
                }

                const hourObj = item.timesheetHoursList.find((ele, index) => ele.clientJobId === jobId);
                const timesheetHoursObj = { ...hourObj }

                const timesheetHours = {
                    Id: timesheetHoursObj.id,
                    ClientJobId: timesheetHoursObj.clientJobId,
                    ClientEmployeeId: timesheetHoursObj.clientEmployeeId,
                    ClientJobActivityId: timesheetHoursObj.clientJobActivityId,
                    Hours: timesheetHoursObj.hours.toFixed(2),
                    Deleted: false,
                }

                let timesheetActivity;
                if (item.timesheetActivitiesList.length > 0) {
                    const actObj = item.timesheetActivitiesList.find((ele, index) => ele.clientJobId === jobId);
                    const timesheetActivityObj = { ...actObj };
                    timesheetActivity = {
                        Id: timesheetActivityObj.id,
                        ClientJobId: timesheetActivityObj.clientJobId,
                        ClientActivityId: timesheetActivityObj.clientActivityId,
                        JobActivityName: `${timesheetActivityObj.clientJobActivityCode} - ${timesheetActivityObj.clientJobActivityName}`,
                        Qty: timesheetActivityObj.qty,
                        QtyUnit: timesheetActivityObj.qtyUnit,
                        Notes: timesheetActivityObj.notes,
                        UDFields: timesheetActivityObj.udFields,
                        Deleted: false,
                    }
                } else {
                    timesheetActivity = {
                        Id: 0,
                        ClientJobId: 0,
                        ClientActivityId: 0,
                        JobActivityName: "",
                        Qty: 0,
                        QtyUnit: "km",
                        Notes: "",
                        UDFields: "",
                        Deleted: false,
                    }
                }



                let timesheetObj = {
                    id: Math.random().toString(),
                    timesheetJob,
                    timesheetEmp,
                    timesheetHours,
                    timesheetActivity
                };
                allLists.push(timesheetObj);
                existing_pics.push(...item.pictureList);
            })
            setAddedJobList(allLists);
            setAllExistingPics(existing_pics);
        }
    }, [location?.state?.from === "viewindividualtimesheet"])


    const onJobItemClick = (item) => {
        setSelectedJobDetails(item);
        setShowAddJobModal(true);
    }

    const onJobRemoveClick = (id) => {
        const filteredAddedList = addedJobList.filter((ele) => ele.id !== id);
        setAddedJobList(filteredAddedList);
    }

    const checkObject = (obj) => {
        try {
            if (obj) {
                if (typeof obj === "object") {
                    if (obj?.fileUrl) {
                        return obj?.fileUrl;
                    }
                    return URL.createObjectURL(obj);
                } else {
                    return obj?.fileUrl;
                }
            } else {
                return "";
            }
        } catch (error) {
            return "";
        }
    };



    const deleteImagesHandler = async () => {
        try {
            // let allAddedPicsAre = [];
            // const test1 = addedJobList.map((ele, index) => {
            //     allAddedPicsAre.push(...ele.timesheetJob.PictureList);
            // })
            // const picsToBeDeleted = allExistingPics.filter((ele1) => !allAddedPicsAre.some((ele2) => ele1?.id === ele2?.Id))
            // const allIds = picsToBeDeleted.map((ele) => {
            //     return {
            //         Id: ele.id,
            //         ModifiedBy: userId,
            //     }
            // });

            let allIds = deleteImageIds?.map((id) => {
                return {
                    Id: id,
                    ModifiedBy: userId,
                }
            })
            const obj = {
                UserId: 1,
                ReturnRecordId: false,
                ReturnRecordError: true,
                SoftDelete: true,
                DeleteList: allIds
            }
            await DeleteClientTimesheetJobImages(obj);

            // if (allIds.length > 0) {
            //     await DeleteClientTimesheetJobImages(obj);
            // }
        }
        catch (err) {
            setIsLoading(false);
            console.log("ERROR ON DELETE IMAGE", err)
        }
    }

    const saveTimesheetImageHandler = async (resData, timesheetId, updatedtTimesheetJobListForImages) => {
        try {
            let updatedArr = updatedtTimesheetJobListForImages.map((addedJob, index) => {
                let updatedId = addedJob?.Id === 0 ? resData[index]?.id : addedJob?.Id;
                let imageToSave = [...addedJob.PictureList]?.filter((item) => !item.id);

                return {
                    Id: updatedId,
                    clientJobId: addedJob.ClientJobId,
                    PictureList: imageToSave
                }
            })
            //! this updated array have to loop for saving pictures 
            for (let i = 0; i < updatedArr.length; i++) {
                if (updatedArr[i].PictureList.length > 0) {
                    for (let index = 0; index < updatedArr[i].PictureList.length; index++) {
                        const picture = updatedArr[i].PictureList[index];
                        try {
                            const formData = new FormData();
                            formData.append("Id", Number.isInteger(picture?.Id || picture?.Id) ? picture?.Id || picture?.Id : 0);
                            formData.append("ClientTimesheetJobId", updatedArr?.[i].Id);
                            formData.append("ClientJobId", updatedArr?.[i].clientJobId);
                            formData.append("ClientActivityId", 0);
                            formData.append("ModifiedBy", userId);
                            formData.append("ClientTimesheetId", timesheetId);
                            formData.append("FileName", picture);
                            const response = await SaveClientTimesheetJobImages(formData);
                        } catch (error) {
                            console.error(`Error uploading image ${index}:`, error);
                        }
                    }
                }
            }
        } catch (err) {
            setIsLoading(false)
            console.log(" ERROR IN SAVING IMAGES", err)
        }
    }

    const fetchTimesheet = async (timesheetId, updatedtTimesheetJobListForImages) => {
        try {
            const timesheetJobObject = {
                PageNr: 1,
                // NrOfRecPerPage:20,
                FullSearch: '',
                UserId: userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{
                    ClientTimesheetId: timesheetId
                }]
            };
            const res = await FetchClientTimesheetJobs(timesheetJobObject);
            await saveTimesheetImageHandler(res?.data, timesheetId, updatedtTimesheetJobListForImages)
        } catch (err) {
            setIsLoading(false)
            console.log("ERROR WHILE FETCHING TIMESHEET IN INDIVIDUAL TIMESHEET SAVE PAGE", err)
        }
    }

    const onSubmitClick = async () => {
        try {
            setIsLoading(true);
            const TimeSheetJobList = [];
            const EmployeeList = [];
            const TimesheetHoursList = [];
            const ActivityList = [];

            addedJobList.map((ele, index) => {
                TimeSheetJobList.push({ ...ele.timesheetJob });
                EmployeeList.push({ ...ele.timesheetEmp });
                TimesheetHoursList.push({ ...ele.timesheetHours });
                ActivityList.push({ ...ele.timesheetActivity });
            })

            const updatedtTimesheetJobList = TimeSheetJobList.map((ele, index) => {
                const transformedData = ele.UDFieldsList
                    .filter(field => field.value)
                    .map(field => `"${field.id}":1`)
                    .join(', ');

                const udResult = `{${transformedData}}`;

                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    Notes: ele.Notes,
                    UDFields: udResult,
                    Deleted: ele.Deleted
                }
            })
            const updatedtTimesheetJobListForImages = TimeSheetJobList.map((ele, index) => {
                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    PictureList: ele.PictureList
                }
            })

            const updatedEmpList = EmployeeList.map((ele, index) => {
                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    ClientEmployeeId: ele.ClientEmployeeId,
                    Mileage: ele.Mileage ? parseFloat(ele.Mileage) : 0,
                    MileageUnit: ele.MileageUnit,
                    UDFields: ele.UDFields,
                    Deleted: ele.Deleted,
                }
            })

            const updatedActList = ActivityList.map((ele, index) => {
                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    ClientActivityId: ele.ClientActivityId,
                    Qty: ele.Qty,
                    QtyUnit: ele.QtyUnit,
                    Notes: ele.Notes,
                    UDFields: ele.UDFields,
                    Deleted: ele.Deleted,
                }
            })

            const updatedHrList = TimesheetHoursList.map((ele, index) => {
                return {

                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    ClientEmployeeId: ele.ClientEmployeeId,
                    ClientJobActivityId: ele.ClientJobActivityId,
                    Hours: parseFloat(ele.Hours),
                    Deleted: ele.Deleted,
                }
            })
            const timesheetObj = {

                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: timesheetId,
                        Date: date,
                        DateSubmitted: new Date(),
                        StatusId: 1,
                        TypeId: 1,
                        ClientId: clientId,
                        Deleted: false,
                        TimeSheetJobList: updatedtTimesheetJobList,
                        EmployeeList: updatedEmpList,
                        TimesheetHoursList: updatedHrList,
                        ActivityList: updatedActList
                    }
                ]

            }

            const res = await SaveClientTimesheets(timesheetObj);

            const result = data?.filter(
                (dataItem) => !addedJobList.some((addedJob) => addedJob.timesheetJob.Id === dataItem.id)
            );
            const timesheetToBeDeleted = result.map((ele) => ({ Id: ele.id }));
            const deleteObj = {
                UserId: Number(userId),
                ReturnRecordId: false,
                ReturnRecordError: true,
                SoftDelete: true,
                DeleteList: timesheetToBeDeleted,
            }
            if (timesheetToBeDeleted.length > 0) {
                const deleteRes = await DeleteClientTimesheetJobs(deleteObj);
            }

            let resClientTimesheetId = res?.data?.[0]?.objData?.ClientTimesheetId ?? null

            if (resClientTimesheetId) {
                await fetchTimesheet(resClientTimesheetId, updatedtTimesheetJobListForImages);
            }

            //! FETCH TIMESHEET 

            if (deleteImageIds?.length > 0) {
                await deleteImagesHandler();
            }

            if (location?.state?.from === "viewindividualtimesheet" && timesheetId !== 0) {
                history.push(`/client-individual-timesheets/${timesheetId}`)
            }


            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Error while saving timesheet data :: ", error);
        }
    }

    return (
        <>
            <ClientHeader screenName={"Timesheets"} />
            {
                !isLoading ? (
                    <div>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Timesheet Date"
                            format="YYYY-MM-DD"
                            value={date}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={(date) => {
                                setDate(moment(date).format("YYYY-MM-DD"));
                            }}
                        />
                        <div>
                            Jobs
                        </div>
                        <Button
                            onClick={() => {
                                setSelectedJobDetails(null);
                                setShowAddJobModal(true);
                            }}
                        >
                            Add Job
                        </Button>

                        <Stack>
                            {
                                addedJobList?.length > 0 &&
                                (
                                    <>
                                        <div>
                                            {
                                                addedJobList?.map((item, index) => (
                                                    <div key={index} onClick={() => { onJobItemClick(item) }}>
                                                        <Stack>
                                                            <Typography>{item?.timesheetJob?.JobName}</Typography>
                                                            <IconButton onClick={(e) => {
                                                                e.stopPropagation();
                                                                onJobRemoveClick(item?.id)
                                                            }} >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Stack>

                                                        {
                                                            item?.timesheetActivity?.JobActivityName?.length <= 3 || item?.timesheetActivity?.JobActivityName == "" ? null :
                                                                <div>
                                                                    <Typography style={{ fontWeight: "500", color: "grey", fontSize: 14 }}>{item?.timesheetActivity?.JobActivityName}</Typography>
                                                                </div>
                                                        }

                                                        <Stack direction={'row'} gap={2}>
                                                            <div>
                                                                <Typography>
                                                                    Hours
                                                                </Typography>
                                                                <Typography>
                                                                    {item?.timesheetHours?.Hours}
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                <Typography>
                                                                    Mileage
                                                                </Typography>
                                                                <Typography>
                                                                    {item?.timesheetEmp?.Mileage !== "" ? Number(item?.timesheetEmp?.Mileage).toFixed(1) : (0.0).toFixed(1)} {item?.timesheetEmp?.MileageUnit}
                                                                </Typography>
                                                            </div>
                                                        </Stack>

                                                        <div>
                                                            {item?.timesheetJob?.UDFieldsList?.map((udValues, index) => (
                                                                (udValues?.value == 'true' || udValues?.value == true) ?
                                                                    <div key={index}>
                                                                        <Chip label={udValues?.label} />
                                                                    </div> : null
                                                            ))
                                                            }
                                                        </div>

                                                        <div>
                                                            <Typography > {item?.timesheetJob?.Notes} </Typography>
                                                        </div>

                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "10px",
                                                                    overflow: "auto",
                                                                    maxWidth: "450px",
                                                                }}
                                                            >
                                                                {item?.timesheetJob?.PictureList?.length > 0 &&
                                                                    item?.timesheetJob?.PictureList.map((img, index) => (
                                                                        <div>
                                                                            <img src={checkObject(img)} />
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </Stack>

                        <div>
                            <Button style={{ color: clientAccentColor ?? "" }}
                                disabled={addedJobList?.length === 0}
                                onClick={onSubmitClick}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )
                    :
                    (
                        <>
                            <div>
                                Loading......
                            </div>
                        </>
                    )
            }

            {
                showAddJobModal &&
                (
                    <AddClientJobModal
                        show={showAddJobModal}
                        handleClose={() => {
                            setShowAddJobModal(false);
                        }}
                        onAddJob={(timesheetObj) => {
                            if (addedJobList?.length === 0) {
                                setAddedJobList([timesheetObj])
                            } else {
                                setAddedJobList((preValue) => ([...preValue, timesheetObj]))
                            }
                            return setShowAddJobModal(false);
                        }}
                        onJobEdit={(timesheetObj) => {
                            let cloneJobList = [...addedJobList];
                            let updatedJobList = cloneJobList?.map((item) => (item?.id === timesheetObj?.id ? timesheetObj : item));
                            setAddedJobList(updatedJobList);
                            return setShowAddJobModal(false);
                        }}
                        selectedJobDetails={selectedJobDetails}
                        deleteImageFromBackend={(newId) => {
                            setDeleteImageIds((preValue) => [...preValue, ...newId])
                        }}
                    />
                )
            }
        </>
    )
}

export default AddClientTimesheetIndividual