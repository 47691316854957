import React, { useEffect, useState } from "react";
import {
  Typography,
  ListItemButton,
  Grid,
  Avatar,
  Chip,
  Tab,
  Fab,
} from "@mui/material";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/Loader";
import { FetchClientSupport } from "../../../api/ClientApi";
import { ClientHeader } from "../ClientHeader";
import "../../../assets/css/ClientHome.css";
import { TabContext } from "@material-ui/lab";
import ClientSupportViewModal from "./ClientSupportViewModal";
import ClientSupportAddModal from "./ClientSupportAddModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tabs } from "@material-ui/core";

function ClientSupport() {
  const [supportList, setSupportList] = useState([]);
  const [addmodel, setaddmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [value, setValue] = React.useState("1");
  const [pageNo, setPageNo] = useState(1);
  const [hasMoreFlag, setHasMoreFlag] = useState(false);
  const [filterData, setFilterData] = useState("");

  useEffect(() => {
    getSupportList(1, "");
    if (clientSearchTerm != "") {
      setSupportList([]);
    }
  }, [clientSearchTerm]);

  const handleChange = (event, newValue) => {
    setSupportList([]);
    setValue(newValue);
    if (newValue == "1") {
      getSupportList(1, "");
      setPageNo(1);
      setFilterData("");
    } else if (newValue == "2") {
      getSupportList(1, "Open");
      setPageNo(1);
      setFilterData("Open");
    } else if (newValue == "3") {
      getSupportList(1, "Completed");
      setPageNo(1);
      setFilterData("Completed");
    } else if (newValue == "4") {
      getSupportList(1, "Closed");
      setPageNo(1);
      setFilterData("Closed");
    }
  };

  const closeModel = () => {
    setaddmodel(false);
  };

  const getSupportList = async (PAGE, data) => {
    const obj = {
      PageNr: PAGE,
      NrOfRecPerPage: 10,
      FullSearch: clientSearchTerm,
      UserId: localStorage?.userId,
      IncludeRecordNr: false,
      FetchAllowedRecordsOnly: true,
      SearchList: [
        {
          clientId: localStorage.getItem("clientId"),
          status: data,
        },
      ],
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };

    try {
      const res = await FetchClientSupport(obj);
      setPageNo((prev) => prev + 1);

      if (res?.data.length > 0) {
        setHasMoreFlag(true);
      } else {
        setHasMoreFlag(false);
      }
      setSupportList((prevList) => [...prevList, ...res?.data]);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const tabArray = [
    {
      value: "1",
      label: "All",
    },
    {
      value: "2",
      label: "Open",
    },
    {
      value: "3",
      label: "Completed",
    },
    {
      value: "4",
      label: "Closed",
    },
  ];

  return (
    <>
      <ClientHeader
        screenName={"Support"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div style={{ marginTop: "20px" }}>
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Loader />
              </div>
            ) : (
              <TabContext value={"1"}>
                <Tabs
                  sx={{
                    ".Mui-selected": {
                      color: localStorage?.clientAccentColor,
                    },
                  }}
                  TabIndicatorProps={{
                    style: { backgroundColor: localStorage?.clientAccentColor },
                  }}
                  value={value}
                  onChange={handleChange}
                >
                  {tabArray.map((tab, index) => (
                    <Tab
                      key={index}
                      value={tab.value}
                      label={tab.label}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        marginRight: "20px",
                      }}
                    />
                  ))}
                </Tabs>

                <InfiniteScroll
                  dataLength={supportList?.length}
                  next={() => getSupportList(pageNo, filterData)}
                  hasMore={hasMoreFlag}
                  loader={hasMoreFlag && <Loader />}
                  scrollableTarget="scrollableDiv_role"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {supportList?.length == 0 ? (
                    <>
                      <Typography
                        style={{
                          color: "grey",
                          textAlign: "center",
                          marginTop: "20px",
                          height: "100vh",
                        }}
                      >
                        No Support Requests.
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                  {supportList?.map((item, index) => {
                    return <ListComponent data={item} />;
                  })}
                </InfiniteScroll>
              </TabContext>
            )}
          </div>
        </div>
      </div>
      <div className="fixed-button-container">
        <Fab
          className="fixed-button"
          style={{ background: localStorage?.clientAccentColor }}
          onClick={() => setaddmodel(true)}
        >
          <AddIcon
            style={{
              color: "white",
            }}
          />
        </Fab>
      </div>
      <ClientSupportAddModal
        open={addmodel}
        onClose={closeModel}
        reloadData={() => {
          getSupportList();
        }}
      />
    </>
  );
}

export default ClientSupport;

const ListComponent = ({ data }) => {
  const [viewOpen, setViewOpen] = useState(false);
  return (
    <>
      <ListItemButton
        onClick={() => setViewOpen(true)}
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid container>
          <Grid
            item
            display={"flex"}
            sx={12}
            md={12}
            xl={12}
            lg={12}
            sm={12}
            justifyContent={"space-between"}
          >
            <Grid item display={"flex"} alignItems={"center"} gap={1}>
              <Avatar src={data?.userProfilePicture} />
              <Typography>{data?.clientEmployeeName}</Typography>
            </Grid>
            <Typography
              style={{
                color: "grey",
              }}
            >
              {moment(data?.dateCreated).format("MMMM DD, YYYY")}
            </Typography>
          </Grid>
          <Grid
            marginTop={1}
            item
            display={"flex"}
            sx={12}
            md={12}
            xl={12}
            lg={12}
            sm={12}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
              variant="body1"
            >
              {data?.description}
            </Typography>
          </Grid>
          <Grid
            item
            marginTop={1}
            display={"flex"}
            sx={12}
            lg={12}
            md={12}
            xl={12}
            sm={12}
            justifyContent={"space-between"}
          >
            <div>
              {data?.supportCategoryName && (
                <Chip
                  style={{
                    backgroundColor: "lightgray",
                    color: "black",
                    borderRadius: "5px",
                  }}
                  label={data?.supportCategoryName}
                />
              )}
            </div>
            <Chip
              style={
                data?.status == "Open"
                  ? {
                      backgroundColor: "lightgray",
                      color: "black",
                      minWidth: "100px",
                    }
                  : data?.status == "Close" || data?.status == "Closed"
                  ? {
                      backgroundColor: "lightgreen",
                      color: "green",
                      minWidth: "100px",
                    }
                  : data?.status == "Completed"
                  ? {
                      backgroundColor: "lightyellow",
                      color: "brown",
                      minWidth: "100px",
                    }
                  : {}
              }
              label={data?.status}
            />
          </Grid>
        </Grid>
      </ListItemButton>
      <ClientSupportViewModal
        open={viewOpen}
        onClose={() => {
          setViewOpen(false);
        }}
        id={data.id}
      />
    </>
  );
};
