import React, { useState } from "react";
import SearchField from "../../components/SearchField";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import SearchListSection from "../search/SearchListSection";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { searchProducts } from "../../api/ProductsApi";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import Loader from "../../components/Loader";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddItemModel from "../search/AddItemModal";

const myProductsArray = ["Worked With", "Following"];
const productsTypeArray = ["All", "Tools", "Materials"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel2(props) {
  const { children2, value2, index2, ...other2 } = props;

  return (
    <div
      role="tabpanel"
      hidden={value2 !== index2}
      id={`vertical-tabpanel-${index2}`}
      aria-labelledby={`vertical-tab-${index2}`}
      {...other2}
    >
      {value2 === index2 && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children2}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel2.propTypes = {
  children2: PropTypes.node,
  index2: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function ProductList(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeMyProducts, setActiveMyProducts] = useState("Worked With");
  const [searchText, setSearchText] = useState("");
  const [productType, setProductType] = useState("All");
  const [productTabValue, setProductTabValue] = useState(0);
  const [page, setPage] = useState(1);
  const [getUpdatedData, setGetUpdatedData] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [hasData, setHasData] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const getProducts = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage.userId),
        SearchString: searchText,
        ProductType: "All",
        MyProductCategory: "",
        PageNumber: pageNumber ?? 1,
        NrRowsPerPage: 20,
      };
      const result = await searchProducts(obj);
      const products = result?.data
        ?.map((res) => {
          if (res.manufacturerName == "") {
            return;
          } else return res;
        })
        .filter((value) => value != null || value != undefined);
      if (result?.data?.length > 0) {
        if (products?.length === 0) {
          getProducts(pageNumber + 1, searchText);
          return;
        }
      }
      setTotalRecord(result?.nrOfRecords);
      setHasData(result?.data?.length != 0 ? true : false);
      return products;
    },
    [page]
  );

  const handleChangeProductTab = (event, newValue) => {
    setProductTabValue(newValue);
    setPage(1);
  };

  const increaseOffset = (callback) => {
    setPage((prevPageNr) => prevPageNr + 1);
    callback(page + 1);
  };

  const clearSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setGetUpdatedData(true);
    }
  };

  const getToolsProducts = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage.userId),
        SearchString: searchText,
        ProductType: "Tool",
        MyProductCategory: "",
        PageNumber: pageNumber ?? 1,
        NrRowsPerPage: 20,
      };
      const result = await searchProducts(obj);
      const products = result?.data
        ?.map((res) => {
          if (res.manufacturerName == "") {
            return null;
          } else return res;
        })
        .filter((value) => value != null);
      setTotalRecord(result?.nrOfRecords);
      setHasData(result?.data?.length != 0 ? true : false);
      return products;
    },
    [page]
  );

  const getMaterialsProducts = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage.userId),
        SearchString: searchText,
        ProductType: "Material",
        MyProductCategory: "",
        PageNumber: pageNumber ?? 1,
        NrRowsPerPage: 100,
      };
      const result = await searchProducts(obj);

      const products = result?.data
        ?.map((res) => {
          if (
            res.manufacturerName == ""
            // && res.createdBy !== Number(localStorage.userId)
          ) {
            return null;
          } else return res;
        })
        .filter((value) => value != null);
      setTotalRecord(result?.nrOfRecords);
      setHasData(result?.data?.length != 0 ? true : false);
      return products;
    },
    [page]
  );

  const getWorkedData = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage.userId),
        SearchString: searchText,
        ProductType: "",
        MyProductCategory: "worked with",
        PageNumber: pageNumber ?? 1,
        NrRowsPerPage: 20,
      };
      const res = await searchProducts(obj);
      setTotalRecord(res?.nrOfRecords);
      setHasData(res?.data?.length != 0 ? true : false);
      return res.data;
    },
    [page]
  );

  const getFollowingProductData = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage.userId),
        SearchString: searchText,
        ProductType: "",
        MyProductCategory: "following",
        PageNumber: pageNumber ?? 1,
        NrRowsPerPage: 20,
      };
      const res = await searchProducts(obj);
      setTotalRecord(res?.nrOfRecords);
      setHasData(res?.data?.length != 0 ? true : false);
      return res.data;
    },
    [page]
  );

  return (
    <div className="dashboard-page">
      <AddItemModel
        open={addItemModalOpen}
        onClose={() => {
          setAddItemModalOpen(false);
        }}
        type={"products"}
      />
      <div style={{ padding: "0% 4%" }}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginBottom: "10px",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              width: "35vw",
            }}
          >
            <SearchField
              value={searchText}
              onChange={(value) => clearSearch(value)}
              onSubmit={() => {
                setPage(1);
                setGetUpdatedData(true);
              }}
              placeholder={"Search Products"}
            />
          </div>
          <p
            style={{
              backgroundColor: "white",
              padding: "12px",
              color: BIGrey,
              borderRadius: "5px",
            }}
          >
            {totalRecord} : Result
          </p>
        </Stack>
        <div style={{ display: "flex", position: "fixed", width: "81%" }}>
          <Tabs
            orientation="vertical"
            textColor={BIBlue}
            indicatorColor="primary"
            aria-label="primary tabs example"
            style={{ marginTop: "10px", width: "250px" }}
            value={productTabValue}
            onChange={handleChangeProductTab}
          >
            <Tab
              textColor={BIBlue}
              className={"tab-btn-radius"}
              label="All Products"
              {...a11yProps(0)}
            />
            <Tab
              textColor={BIBlue}
              className={"tab-btn-radius"}
              style={{ marginTop: "10px" }}
              label="My Products"
              {...a11yProps(1)}
            />
          </Tabs>

          <TabPanel value={productTabValue} index={0} style={{ width: "100%" }}>
            <Tabs
              textColor={BIBlue}
              indicatorColor="primary"
              value={productType}
              onChange={async (event, newTab) => {
                setPage(1);
                await setProductType(newTab);
              }}
            >
              {productsTypeArray.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab}
                  label={tab}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginRight: "20px",
                  }}
                />
              ))}
            </Tabs>
            {productType === "All" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getProducts}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="product"
                  hasMore={hasData}
                />
              </div>
            )}
            {productType === "Tools" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getToolsProducts}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="product"
                  hasMore={hasData}
                />
              </div>
            )}
            {productType === "Materials" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getMaterialsProducts}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="product"
                  hasMore={hasData}
                />
              </div>
            )}
          </TabPanel>

          <TabPanel value={productTabValue} index={1} style={{ width: "100%" }}>
            <Tabs
              textColor={BIBlue}
              indicatorColor="primary"
              value={activeMyProducts}
              onChange={(event, newTab) => {
                setPage(1);
                setActiveMyProducts(newTab);
              }}
            >
              {myProductsArray.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab}
                  label={tab}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginRight: "20px",
                  }}
                />
              ))}
            </Tabs>
            {activeMyProducts === "Worked With" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getWorkedData}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="product"
                  hasMore={hasData}
                />
              </div>
            )}
            {activeMyProducts === "Following" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getFollowingProductData}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="product"
                  hasMore={hasData}
                />
              </div>
            )}
          </TabPanel>
        </div>
      </div>
      <div style={{ position: "absolute", bottom: "40px", right: "10px" }}>
        <IconButton
          size="small"
          style={{ color: BIBlue }}
          onClick={() => {
            setAddItemModalOpen(true);
          }}
        >
          <AddCircleIcon
            fontSize="large"
            style={{
              height: "50px",
              width: "50px",
            }}
          />
        </IconButton>
      </div>
    </div>
  );
}
