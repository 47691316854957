import { IconButton, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BIGrey, BILightGrey } from "../../../assets/buildidColors";
import moment from "moment/moment";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import { saveClientSAR } from "../../../api/ClientApi";
import { LoadingButton } from "@mui/lab";
import { Close } from "@material-ui/icons";

function SiteActivityReportModal(props) {
  const { open, onClose, data, edit, clientJobsData, reLoadData } = props;
  const [buttonDisable, setButtonDisable] = useState(true);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [weather, setWeather] = useState("");
  const [loading, setLoading] = useState(false);

  const resetData = () => {
    setDate(new Date());
    setTime(new Date());
    setWeather("");
  };
  useEffect(() => {
    if (weather == "") {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [weather]);

  useEffect(() => {
    if (edit) {
      setWeather(clientJobsData?.weather);
      setDate(moment(clientJobsData?.date).format());
      setTime(clientJobsData?.time);
    }
  }, []);

  const handleCreateReport = async () => {
    setLoading(true);
    try {
      const payload = {
        ReturnRecordId: true,
        ReturnRecordError: true,
        UserId: localStorage?.userId,
        SaveList: [
          {
            Id: edit ? clientJobsData?.id : 0,
            ClientEmployeeId: data.clientEmployeeId,
            ClientEmployeeJobId: data.id,
            Date: date ? moment(date).format("YYYY-MM-DD") : "",
            Time: time ? moment(time).toISOString() : "",
            Weather: weather,
            ModifiedBy: localStorage?.userId,
          },
        ],
      };
      await saveClientSAR(payload);
      await reLoadData();
    } catch (err) {
      console.log("ERRR :::: ", err);
    }
    setLoading(false);
    resetData();
    onClose();
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              minHeight: "400px",
              width: "500px",
            }}
            className="modal-card"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={onClose} style={{ padding: "5px" }}>
                <Close
                  style={{
                    color: localStorage.clientAccentColor,
                  }}
                />
              </IconButton>
            </div>
            <div>
              <Typography
                style={{
                  color: BIGrey,
                }}
              >
                Job
              </Typography>
              <Typography> {`${data?.jobCode} - ${data?.jobName}`}</Typography>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <div>
                  <Typography
                    style={{
                      color: BIGrey,
                    }}
                  >
                    Date
                  </Typography>
                  <KeyboardDatePicker
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        backgroundColor: "#f2f2f2",
                        borderRadius: "10px",
                        height: "40px",
                        padding: "  0 10px",
                      },
                    }}
                    inputVariant={"standard"}
                    size="small"
                    onChange={(e) => {
                      setDate(moment(e).format());
                    }}
                    minDate={new Date()}
                    value={date}
                    className="date-picker"
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY"
                    fullWidth
                    autoOk={true}
                    KeyboardButtonProps={{
                      style: { color: localStorage.clientAccentColor }, // Change icon color here
                    }}
                  />
                </div>
                <div>
                  <Typography
                    style={{
                      color: BIGrey,
                    }}
                  >
                    Time
                  </Typography>
                  <TimePicker
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        backgroundColor: "#f2f2f2",
                        borderRadius: "10px",
                        height: "40px",
                        padding: "  0 10px",
                      },
                    }}
                    inputVariant={"standard"}
                    size="small"
                    onChange={(e) => {
                      setTime(moment(e).format());
                    }}
                    value={time}
                    className="date-picker"
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  style={{
                    color: BIGrey,
                  }}
                >
                  Weather
                </Typography>
                <TextField
                  className="date-picker"
                  multiline
                  rows={4}
                  value={weather}
                  onChange={(e) => {
                    setWeather(e.target.value);
                  }}
                  size="small"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                    padding: "5px 10px",
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  style={{
                    color: BIGrey,
                  }}
                  textAlign={"center"}
                >
                  Reports can only be edited within 48 hours.
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <LoadingButton
                  style={{
                    textTransform: "none",
                    backgroundColor: !buttonDisable
                      ? localStorage.clientAccentColor
                      : BILightGrey,
                    boxShadow: "none",
                    color: "white",
                  }}
                  onClick={() => {
                    handleCreateReport();
                  }}
                  loading={loading}
                  disabled={buttonDisable}
                  fullWidth
                  loadingPosition="start"
                >
                  Create Report
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SiteActivityReportModal;
