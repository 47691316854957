import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import defaultTrade from "../../../assets/images/default-trade-profile-thumbail.jpeg";
import "./ClientTimesheets.css";

export default function ActivitiesModal(props) {


    const openJobactivities = () => {
        props.SetShowjobactivities(true);
    }

    const removeJobActivities = () => {

    }

    return (
        <>
            <Stack gap={2}>
                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontSize={19} fontWeight={600}>Activities</Typography>
                    {
                        props.selectedJobActivities?.length !== 0 && (
                            <Typography fontSize={16} color={"#1976d2"} fontWeight={500}>Select activities</Typography>
                        )
                    }
                </Stack>
                {
                    props.selectedJobActivities?.length === 0 ? (
                        <Button color="primary" onClick={() => openJobactivities()} fullWidth variant="contained" >Select Activities</Button>
                    ) : (
                        <Box className="jobActivities-list">
                            <Card className="jobActivities-card">
                                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography fontSize={15} fontWeight={600}>JA139 - JobActivity187_6</Typography>
                                    <IconButton
                                        color="primary"
                                        onClick={removeJobActivities}>
                                        <CloseIcon />
                                    </IconButton>
                                </Stack>
                                <Stack flexDirection={"row"} gap={1} alignItems={"end"}>
                                    <Stack gap={1}>
                                        <Typography fontSize={15}>Hours</Typography>
                                        <TextField
                                            placeholder={"00.00"}
                                            disabled
                                            color="primary"
                                            size={"small"}
                                        />
                                    </Stack>
                                    <Stack gap={1}>
                                        <Typography fontSize={15}>Quantity</Typography>
                                        <TextField
                                            placeholder={"00.00"}
                                            disabled
                                            color="primary"
                                            size={"small"}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={10}
                                                size="small"
                                            >
                                                <MenuItem value={10}>sqrt</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="LOA" />
                                        <FormControlLabel required control={<Checkbox />} label="Overtime" />
                                    </FormGroup>
                                </Stack>
                                <Stack gap={1} marginBottom={1}>
                                    <Typography fontSize={15}>Notes</Typography>
                                    <TextField
                                        value={""}
                                        multiline
                                        placeholder={"Enter additional details(optional)"}
                                        color="primary"
                                        rows={2}
                                        className="notes-field"
                                    />
                                </Stack>
                                <Stack gap={1}>
                                    <Typography fontSize={15}>Pictures</Typography>
                                    <Grid container gap={1} overflow={"scroll"} flexWrap={"nowrap"} display={"-webkit-box"}>
                                        <Grid sm={2} md={3} lg={3} xl={3}>
                                            <Box className="add-pic">
                                                <AddIcon color="#cccccc" />
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Stack>
                            </Card>
                            <Card className="jobActivities-card">
                                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography fontSize={15} fontWeight={600}>JA139 - JobActivity187_6</Typography>
                                    <IconButton
                                        color="primary"
                                        onClick={removeJobActivities}>
                                        <CloseIcon />
                                    </IconButton>
                                </Stack>
                                <Stack flexDirection={"row"} gap={1} alignItems={"end"}>
                                    <Stack gap={1}>
                                        <Typography fontSize={15}>Hours</Typography>
                                        <TextField
                                            placeholder={"00.00"}
                                            disabled
                                            color="primary"
                                            size={"small"}
                                        />
                                    </Stack>
                                    <Stack gap={1}>
                                        <Typography fontSize={15}>Quantity</Typography>
                                        <TextField
                                            placeholder={"00.00"}
                                            disabled
                                            color="primary"
                                            size={"small"}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={10}
                                                size="small"
                                            >
                                                <MenuItem value={10}>sqrt</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="LOA" />
                                        <FormControlLabel required control={<Checkbox />} label="Overtime" />
                                    </FormGroup>
                                </Stack>
                                <Stack gap={1} marginBottom={1}>
                                    <Typography fontSize={15}>Notes</Typography>
                                    <TextField
                                        value={""}
                                        multiline
                                        placeholder={"Enter additional details(optional)"}
                                        color="primary"
                                        rows={2}
                                        className="notes-field"
                                    />
                                </Stack>
                                <Stack gap={1}>
                                    <Typography fontSize={15}>Pictures</Typography>
                                    <Grid container gap={1} overflow={"scroll"} flexWrap={"nowrap"} display={"-webkit-box"}>
                                        <Grid sm={2} md={3} lg={3} xl={3}>
                                            <Box className="add-pic">
                                                <AddIcon color="#cccccc" />
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                        <Grid sm={2} md={3} lg={3} xl={3} >
                                            <Box>
                                                <img className="add-pic" src={defaultTrade}></img>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Stack>
                            </Card>
                        </Box>
                    )
                }
            </Stack>
        </>
    )

}