import React, { useEffect, useState } from "react";
import { Avatar, Grid, Stack } from "@mui/material";
import { Divider, IconButton, Typography } from "@material-ui/core";
import {
  BIGreen,
  BIGrey,
  BILightGreen,
  BILightRed,
  BIRed,
  BIWhite,
} from "../../../assets/buildidColors";
import { Edit as EditIcon } from "@mui/icons-material";
import "../../../assets/css/ClientHome.css";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteClientExpenses,
  fetchClientexpenses,
  fetchClientExpensesHistory,
  fetchClientexpensesReceipts,
} from "../../../api/ClientApi";
import { Delete as DeleteIcon } from "@material-ui/icons";
import PromptModal from "../../../components/PromptModal";
import Addexpenses from "./Addexpenses";
import Loader from "../../../components/Loader";
import { ClientHeader } from "../ClientHeader";
import moment from "moment";
import PictureViewer from "../../../components/PictureViewer";

const ExpensesDetails = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [previewImage, setPreviewImage] = useState(false);
  const [expensesDetails, setExpensesdetails] = useState();
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [openAlert, setOpenalert] = useState(false);
  const [showAddExpenses, setShowAddExpenses] = useState(false);
  const [loader, setLoader] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [expenseHistory, setExpenseHistory] = useState([]);

  useEffect(async () => {
    fetchExpensesdata();
    getReceiptsHistory();
  }, []);

  const fetchExpensesdata = async () => {
    setLoader(true);
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage?.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [{ id: id }],
      SortList: [
        {
          FieldName: "Id",
          Direction: "DESC",
        },
      ],
    };
    let getExpenses = await fetchClientexpenses(obj);
    setExpensesdetails(getExpenses?.data[0]);
    setLoader(false);
  };

  const getReceiptsHistory = async () => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ clientExpenseId: id }],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
      };

      const res = await fetchClientExpensesHistory(obj);
      setExpenseHistory(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openAlertbox = async () => {
    setOpenalert(true);
  };

  const deleteExpenses = async () => {
    setLoader(true);
    let object = {
      userId: Number(localStorage?.userId),
      returnRecordError: true,
      softDelete: false,
      deleteList: [
        {
          id: id,
        },
      ],
    };
    await deleteClientExpenses(object);
    setLoader(false);
    history.push("/client-expenses");
  };

  if (loader) {
    return (
      <>
        <ClientHeader
          screenName={"Expenses"}
          clientSearchTerm={clientSearchTerm}
          setClientSearchTerm={setClientSearchTerm}
        />
        <Loader />
      </>
    );
  }

  return (
    <>
      <ClientHeader
        screenName={"Expenses"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content">
          <div className="expense-detail">
            <PromptModal
              isOpen={openAlert}
              onClose={() => setOpenalert(false)}
              title="Are you sure want to delete this expenses"
              desc="Please choose your option."
              redButtonText="Delete"
              blueButtonText="Cancel"
              onBlueButtonClick={() => setOpenalert(false)}
              onRedButtonClick={() => deleteExpenses()}
            />
            {expensesDetails?.status === "Approved" && (
              <Stack style={{ margin: "10px" }}>
                <Typography
                  style={{
                    backgroundColor: BILightGreen,
                    color: BIGreen,
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  This Expense has been approved and cannot be edited.
                </Typography>
              </Stack>
            )}
            {expensesDetails?.status === "Rejected" && (
              <Stack style={{ margin: "10px" }}>
                <Typography
                  style={{
                    backgroundColor: BILightRed,
                    color: BIRed,
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  This Expense has been rejected.
                </Typography>
              </Stack>
            )}

            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                {moment(expensesDetails?.dateSubmitted).format("MMMM DD, YYYY")}
              </Typography>
              <Stack style={{ flexDirection: "row" }}>
                {expensesDetails?.status !== "Approved" && (
                  <IconButton onClick={() => setShowAddExpenses(true)}>
                    <EditIcon sx={{ color: localStorage.clientAccentColor }} />
                  </IconButton>
                )}

                <IconButton onClick={openAlertbox}>
                  <DeleteIcon sx={{ color: BIWhite }} />
                </IconButton>
              </Stack>
            </Stack>
            <Stack>
              <Typography variant="h6" style={{ fontWeight: "500" }}>
                {expensesDetails?.title}
              </Typography>
              <Typography style={{ color: BIGrey, fontSize: "16px" }}>
                {`${expensesDetails?.clientExpenseCode} - ${expensesDetails?.clientExpenseName}`}
              </Typography>
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ margin: "15px 0px" }}
            >
              {expensesDetails?.clientJobCode != "" && (
                <Stack>
                  <Typography style={{ color: BIGrey }}>Job</Typography>
                  <Typography>{`${expensesDetails?.clientJobCode} - ${expensesDetails?.clientJobName}`}</Typography>
                </Stack>
              )}
              {expensesDetails?.mileage != 0 &&
              expensesDetails?.mileageUnit != "" ? (
                <Stack alignItems={"center"}>
                  <Typography style={{ color: BIGrey }}>Distance</Typography>
                  <Typography>
                    {`${expensesDetails?.mileage} ${expensesDetails?.mileageUnit}`}
                  </Typography>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ margin: "15px 0px" }}
            >
              {expensesDetails?.subTotal != 0 &&
                (expensesDetails?.tax != 0 || expensesDetails?.tip != 0) && (
                  <Stack>
                    <Typography>Subtotal</Typography>
                    <Typography>Taxes</Typography>
                    <Typography>Tip</Typography>
                  </Stack>
                )}
              <Stack>
                <Typography style={{ fontWeight: "600", marginTop: "10px" }}>
                  Total
                </Typography>
              </Stack>
              <Stack style={{ textAlign: "right" }}>
                {expensesDetails?.subTotal != 0 &&
                  (expensesDetails?.tax != 0 || expensesDetails?.tip != 0) && (
                    <>
                      <Typography>
                        {expensesDetails?.currencySign}
                        {expensesDetails?.subTotal.toFixed(2)}
                      </Typography>
                      <Typography>
                        {expensesDetails?.currencySign}
                        {expensesDetails?.tax.toFixed(2)}
                      </Typography>
                      <Typography style={{ color: BIGrey }}>
                        {expensesDetails?.currencySign}
                        {expensesDetails?.tip.toFixed(2)}
                      </Typography>
                    </>
                  )}
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                    marginTop: "10px",
                  }}
                >
                  {`${expensesDetails?.currencyCode}${expensesDetails?.currencySign}`}{" "}
                  {expensesDetails?.total.toFixed(2)}
                </Typography>
                <Typography style={{ color: BIGrey }}>
                  {expensesDetails?.paidWith == 1
                    ? "Paid personally"
                    : "Paid with company card"}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Divider />
              {expensesDetails?.description !== "" && (
                <Typography style={{ margin: "20px 0px", color: BIGrey }}>
                  {expensesDetails?.description}
                </Typography>
              )}
              {expensesDetails?.expenseReceiptList.length > 0 && (
                <Stack style={{ margin: "10px 0px" }}>
                  <Typography style={{ color: BIGrey }}>Receipts</Typography>
                  <div style={{ marginTop: "10px" }}>
                    <Stack
                      display={"flex"}
                      justifyContent={"start"}
                      alignItems={"center"}
                      direction={"row"}
                      gap={2}
                    >
                      {expensesDetails?.expenseReceiptList?.map(
                        (item, index) => (
                          <img
                            onClick={() => {
                              setPreviewImage(true);
                              setIndex(index);
                            }}
                            src={item?.fileUrl}
                            alt={index}
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "20px",
                            }}
                          />
                        )
                      )}
                    </Stack>
                  </div>
                </Stack>
              )}
            </Stack>
            <PictureViewer
              isOpen={previewImage}
              onClose={() => setPreviewImage(false)}
              pictures={expensesDetails?.expenseReceiptList?.map((item) => ({
                src: item.fileUrl,
                alt: "Receipt",
              }))}
              viewerIndex={index}
            />
            <div
              style={{
                margin: "10px 0px",
                padding: "0px1 10px",
                backgroundColor: BIWhite,
              }}
            >
              <Typography
                style={{ textAlign: "center", margin: "15px", color: BIGrey }}
              >
                Submitted on{" "}
                {moment(expensesDetails?.dateSubmitted).format(
                  "MMMM D, YYYY, h:mm a"
                )}
              </Typography>

              {showAddExpenses && (
                // <Addexpenses showAddExpenses={showAddExpenses} setShowAddExpenses={setShowAddExpenses} />
                <Addexpenses
                  reloadData={() => fetchExpensesdata()}
                  open={showAddExpenses}
                  onClose={() => setShowAddExpenses(false)}
                  form={"edit"}
                  id={id}
                  expensesDetails={expensesDetails}
                  clientId={expensesDetails?.clientId}
                />
              )}
            </div>
            {expenseHistory?.map((item, index) => {
              return <HistoryList data={item} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesDetails;

const HistoryList = ({ data }) => {
  return (
    <>
      <Grid marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
        <Grid
          sx={6}
          md={6}
          xl={6}
          lg={6}
          sm={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
          item
        >
          <Grid display={"flex"} alignItems={"center"} gap={1} item>
            <Avatar src={data?.userProfilePicture} />
            <Typography>{data?.statusChangedByUser}</Typography>
          </Grid>
          <Typography style={{ color: "grey" }}>
            {moment(data?.statusChangedDate).format("MMMM DD, YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
        <Typography>{data?.statusDescription}</Typography>
      </Grid>
      <Grid
        display={"flex"}
        justifyContent={"center"}
        marginTop={1}
        sx={12}
        md={12}
        xl={12}
        lg={12}
        sm={12}
      >
        <Typography style={{ color: "grey" }}>
          {` ${data.expenseStatus} ${moment(data?.statusChangedDate).format(
            "MMMM DD, YYYY HH:MM a"
          )}`}
        </Typography>
      </Grid>
    </>
  );
};
