import React, { useState, useEffect } from "react";

import { IconButton, Checkbox, FormControlLabel } from "@material-ui/core";
import {
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon,
} from "@material-ui/icons";

import { Error as ErrorOutlineOutlinedIcon } from "@material-ui/icons";

import { stringToLocale } from "../../../assets/helpers";
import { useCallback } from "react";
import { BILightRed } from "../../../assets/buildidColors";
import AlertModal from "../../../components/AlertModal";
import { useHistory } from "react-router-dom";

function ExperienceCard(props) {

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <div className="card" style={{ maxHeight: "100%" }}>
        <div className="flex-container-horizontal">
          <div className="flex-container-horizontal" style={{ flex: 1 }}>
            {/* <div className="content-container" style={{ flex: 1 }}> */}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <h3>Experience</h3>
            </span>

          </div>
          <div>
            {
              // Show appropriate expand/collapse icon
              isExpanded ? (
                <IconButton
                  color="primary"
                  onClick={() => setIsExpanded(false)}>
                  <CollapseIcon />
                </IconButton>
              ) : (
                <IconButton color="primary" onClick={() => setIsExpanded(true)}>
                  <ExpandIcon />
                </IconButton>
              )
            }
          </div>
        </div>

        <div>
          {isExpanded ? (
            <>
              {props?.workHistory.length > 0 ? (
                // Display user's companies
                props?.workHistory.map((company, index) => (

                  <div key={index}>
                    {index !== 0 ? <hr /> : <></>}
                    <Company
                      companyItemHandlePressed={props?.companyItemHandlePressed}
                      itemType={"KnownCompanies"}
                      company={company}
                      index={index}
                      projectItemHandlePressed={props?.projectItemHandlePressed}
                    />
                  </div>
                ))
              ) : (
                <p>No data available for companies</p>
              )}

              {props?.unknownCompany.length != 0 ?
                <>
                  <p><b>No Company</b></p>
                  {props?.unknownCompany.map((company, index) => (
                    <div key={index}>
                      <Project
                        projectItemHandlePressed={props?.noCompanyprojectItemHandlePressed}
                        itemType={"UnknownCompanies"}
                        company={company}
                        index={index}
                        project={company}
                        projectName={company}
                        companyIndex={0}
                      />
                    </div>
                  ))}</> : <></>}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default ExperienceCard;

function Company(props) {
  const history = useHistory()
  const handleCompanyClick = (company) => {
    return history.push(`/company/${company?.companyId ?? 0}`)
  }

  return (
    <div className="content-container">
      <div className="flex-container-horizontal" style={{ justifyContent: "space-between" }}>
        <div>
          {(props?.company?.companyName !== "") ?
            <>
              <p className="link"><b onClick={() => handleCompanyClick(props?.company)}>{props?.company?.companyName}</b></p>
              <p>{props?.company?.roleName}</p>
              {	// If startDate is null, then it's "No date"
                (props?.company?.startDate !== null)
                  ? (<p>{stringToLocale(props?.company.startDate)} - {props?.company?.currentCompany === false ? props?.company.endDate == null || props?.company?.endDate == "Invalid date" ? null : stringToLocale(props?.company?.endDate) : "Present"}</p>)
                  : <></>
              }
              <p>{props?.company?.description}</p></> : <></>
          }
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={props?.company.isSelected}
                color="primary"
                onChange={() =>
                  props?.companyItemHandlePressed(props?.company, props?.index)
                }
              />
            }
          />
        </div>
      </div>
      {props?.company?.experience.sort((a, b) => { let dateA = new Date(a?.startDate), dateB = new Date(b?.startDate); return dateB - dateA; }).map((project, index) => {
        return <Project
          key={index}
          index={index}
          project={project}
          company={props?.company}
          ischecked={props?.company?.projects}
          projectItemHandlePressed={props?.projectItemHandlePressed}
          companyIndex={props?.index}
        />
      })
      }
    </div>
  );
}

function Project(props) {
  const [noAddressAlert, setNoAddressAlert] = useState(false);

  const handleProjectClick = useCallback(async (project) => {
    window.location = "/sites/site/" + project.siteId;
  }, []);


  return (
    <>
      <div className="profile-experience-project-item">
        <div className="flex-container-horizontal">
          <div className="flex-container-left">
            <div className="flex-container-horizontal">
              <p className="link">
                <b onClick={() => handleProjectClick(props?.project)}>
                  {props?.project?.projectName}
                </b>
                <p>{props?.project?.siteName}</p>
              </p>


            </div>

            {	// If startDate is null or empty, then it's "No date"
              (props?.project?.startDate !== null && props?.project?.startDate !== "")
                ? (
                  <p>{stringToLocale(props?.project?.startDate)} - {
                    // If endDate is null, then it's to "Present"
                    props?.project?.present === false ? props?.project?.endDate == null || props?.project?.endDate == "Invalid date" ? null : stringToLocale(props?.project?.endDate) : "Present"
                  }
                  </p>
                )
                : <p> </p>
            }
            {/* <p>{props?.project?.description}</p> */}
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props?.project.isSelected}
                  color="primary"
                  onChange={() => props?.projectItemHandlePressed({ item: props?.project, index: props?.companyIndex, projectIndex: props?.index })}
                />
              }
            />
          </div>

          {/* AlertModal */}
          <AlertModal
            isOpen={noAddressAlert}
            onClose={() => setNoAddressAlert(false)}
            title={"No address"}
            body={
              "This site will not be visible on the map until an address is added to it."
            }
          />
        </div>
      </div>
    </>
  );
}
