import { KeyboardDatePicker } from "@material-ui/pickers";
import { Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { getClientUDFieldsbyClientId } from "../../../api/ClientApi";
import {
    Close as CloseIcon,
} from "@material-ui/icons";


export default function GeneralModal(props) {

    useEffect(() => {
        if (props.showEmployeelist === false) {
            getUDfields();
        }
    }, [props.showEmployeelist]);


    const getUDfields = async () => {
        const getResponse = await Promise.all(props.selectedEmp.map(async (employee) => {
            const obj = {
                clientId: localStorage?.clientId,
                appId: 11,
                entityRecordId: employee?.id,
            }
            const res = await getClientUDFieldsbyClientId(obj);
            console.log("res=-==?", res);
            return {
                ...employee,
                udField: res
            }
        }));
        console.log("getResponse====>", getResponse)
        props.setSelectedemp(getResponse);
    }

    const handleSearchjob = () => {
        props.setShowJobListModal(true)
    }

    const onSelectemployee = () => {
        props.setShowemployeelist(true);
    }

    return (
        <Stack>
            <Typography fontSize={19} fontWeight={600}>General</Typography>
            <Stack marginTop={2} gap={1}>
                <Typography fontSize={16} fontWeight={500}>Date</Typography>
                <KeyboardDatePicker
                    className="form-field"
                    variant="inline"
                    inputVariant={"outlined"}
                    placeholder="MM/DD/YYYY"
                    format={"MMM D, YYYY"}
                    value={
                        new Date()
                    }
                    fullWidth
                    size="small"
                    autoOk={true}
                />
            </Stack>
            <Stack marginTop={2} gap={1}>
                <Typography fontSize={16} fontWeight={500}>Job</Typography>
                <TextField
                    value={props.jobName}
                    placeholder={"Search for a Job"}
                    color="primary"
                    size={"small"}
                    className="user-search"
                    onClick={handleSearchjob}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={handleSearchjob}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack marginTop={2} gap={2} flexDirection={props?.selectedEmp?.length === 0 ? "column" : "row"} justifyContent={"space-between"}>
                <Typography fontSize={19} fontWeight={600}>Employees</Typography>
                {
                    props?.selectedEmp?.length === 0 ? (
                        <Button color="primary" variant="contained" onClick={() => onSelectemployee()}>Select Employee</Button>
                    ) : (
                        <Typography fontSize={17} fontWeight={500}>Select employees</Typography>
                    )
                }

            </Stack>

            {
                props.selectedEmp.map((item) => (
                    <Card className="selected-empcard">
                        <Grid container alignItems={"center"}>
                            <Grid item md={6} lg={6} xl={6}>
                                <Typography>{item?.firstName + " " + item?.lastName}</Typography>
                            </Grid>
                            <Grid item md={3} lg={3} xl={3}>
                                <TextField
                                    placeholder={"0.0"}
                                    color="primary"
                                    size={"small"}
                                    value={""}
                                />
                            </Grid>
                            <Grid item md={3} lg={3} xl={3} display={"flex"} justifyContent={"end"} sx={{ gap: "20px" }} alignItems={"center"}>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    size="small"
                                    value={10}
                                >
                                    <MenuItem value={10}>km</MenuItem>
                                    <MenuItem value={20}>mi</MenuItem>
                                </Select>
                                <CloseIcon />
                            </Grid>
                        </Grid>
                        {item?.udField?.map((item) => (
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label={item?.fieldLabel} />
                            </FormGroup>
                        ))}
                    </Card>
                ))
            }
        </Stack >
    )
}