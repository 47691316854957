import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import PictureViewer from "../../../components/PictureViewer";
import {
  FetchClientSupport,
  FetchClientSupportCategories,
  saveClientHistory,
  saveSupportReviews,
} from "../../../api/ClientApi";
import { Close } from "@material-ui/icons";
import {
  BIGreen,
  BILightGreen,
  BILightRed,
  BIRed,
} from "../../../assets/buildidColors";
import Loader from "../../../components/Loader";
import { LoadingButton } from "@mui/lab";

export default function ClientSupportViewModal({ open, onClose, id }) {
  const [index, setIndex] = useState(0);
  const [pictureView, setPictureView] = useState(false);
  const [supportHistory, setSupportHistory] = useState([]);
  const [commentDescription, setCommentDescription] = useState([]);
  const scrollRef = useRef();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let currentStatus = "";
  const [helpOpen, setHelpOpen] = useState(false);
  const [closeRequestModel, setCloseRequestModel] = useState(false);

  useEffect(() => {
    console.log("idddddddddddddddddddddddddd", id, open);
    if (id) {
      if (open) {
        getSupportList();
      }
    }
  }, [open]);

  const getSupportList = async () => {
    console.log("Called");
    setIsLoading(true);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 50,
      FullSearch: "",
      UserId: localStorage?.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          id: id,
        },
      ],
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };

    try {
      const res = await FetchClientSupport(obj);
      console.log("res======", res);
      setData(res.data[0]);
      getSupportCategories(res.data[0]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSupportCategories = async (sup_id) => {
    setIsLoading(true);

    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          supportId: sup_id.id,
        },
      ],
      SortList: [
        {
          FieldName: "id",
          Direction: "ASC",
        },
      ],
    };

    try {
      const res = await FetchClientSupportCategories(obj);
      setSupportHistory(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const userId = localStorage.userId;

  const HandelSaveClientHistory = async (value) => {
    setIsLoading(true);
    let obj = {};
    if (value == "re-open" || value == "open" || value == "closed") {
      obj = {
        Id: 0,
        // Id: lastItemId.id,
        SupportId: data.id,
        Status: value == "re-open" ? "Open" : "Closed",
        // Status: value == 're-open' ? 'Reopened' : 'Closed',
        StatusChangedBy: Number(userId),
        StatusChangedDate: moment().format("YYYY-MM-DD"),
        // Comment: lastItemId.comment,
        ModifiedBy: Number(userId),
      };
    } else if (value == "comment") {
      obj = {
        Id: 0,
        SupportId: data.id,
        // Status: "Completed", // <----  completed status
        // Status: 'Closed',          // <----  Close status
        Status: "Open",
        // Status: supportData[0].status == 'Reopened' ? 'Reopened' : 'Open',
        StatusChangedBy: Number(userId),
        StatusChangedDate: moment().format("YYYY-MM-DD"),
        Comment: commentDescription,
        ModifiedBy: Number(userId),
      };
      setCommentDescription("");
    }

    try {
      const saveClientHistoryObject = {
        UserId: Number(userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [obj],
      };
      const res = await saveClientHistory(saveClientHistoryObject);
      getSupportList();
      setCommentDescription("");
      await getSupportCategories();
      scrollRef.current.scrollTo(999999, 99999);
    } catch (e) {
      console.log("ERROR : [saveClientHistory] :", e);
      setCommentDescription("");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            style={{
              padding: "20px 20px 10px 20px",
              width: "600px",
            }}
            className="modal-card"
          >
            {isLoading ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={() => {
                      onClose();
                      setCommentDescription("");
                    }}
                    style={{ padding: "0px" }}
                  >
                    <Close />
                  </IconButton>
                </div>
                <div
                  ref={scrollRef}
                  style={{
                    maxHeight: "80vh",
                    overflow: "auto",
                    paddingRight: "5px",
                  }}
                >
                  <Grid container>
                    <Grid
                      sx={12}
                      md={12}
                      xl={12}
                      lg={12}
                      sm={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid item display={"flex"} alignItems={"center"} gap={1}>
                        <Avatar src={data?.userProfilePicture} />
                        <Typography>{data.clientEmployeeName}</Typography>
                        <Typography
                          style={{
                            color: "grey",
                          }}
                        >
                          {data.clientEmployeeId ==
                          localStorage.clientEmployeeId
                            ? " (me)"
                            : ""}
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          color: "grey",
                        }}
                      >
                        {moment(data?.dateCreated).format("MMMM DD, YYYY")}
                      </Typography>
                    </Grid>
                    {data?.supportCategoryName && (
                      <Chip
                        label={data?.supportCategoryName}
                        style={{
                          backgroundColor: "lightgrey",
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                      />
                    )}
                    <Grid container>
                      <Grid sx={6} md={6} xl={6} lg={6} sm={6}>
                        <Typography
                          style={{
                            color: "grey",
                          }}
                        >
                          Urgency
                        </Typography>
                        <Typography>{`${data?.urgencyId} - ${data?.urgencyName}`}</Typography>
                      </Grid>
                      {data?.ecDate && (
                        <Grid sx={6} md={6} xl={6} lg={6} sm={6}>
                          <Typography
                            style={{
                              color: "grey",
                            }}
                          >
                            Expected Complection Date
                          </Typography>
                          <Typography>
                            {moment(data?.ecDate).format("MMMM DD, YYYY")}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          maxWidth: "100%",
                          overflow: "auto",
                        }}
                      >
                        {data?.pictureList?.map((item, index) => {
                          return (
                            <img
                              onClick={() => {
                                setIndex(index);
                                setPictureView(true);
                              }}
                              style={{
                                cursor: "pointer",
                                height: "150px",
                                minWidth: "150px",
                                borderRadius: "10px",
                              }}
                              src={item.fileUrl}
                            />
                          );
                        })}
                      </div>
                    </Grid>
                    <Grid item>
                      <Grid
                        marginTop={1}
                        sx={12}
                        md={12}
                        xl={12}
                        lg={12}
                        sm={12}
                      >
                        <Typography
                          style={{
                            color: "grey",
                          }}
                        >
                          Status
                        </Typography>
                        <Chip
                          style={
                            data?.status == "Open"
                              ? {
                                  backgroundColor: "lightgray",
                                  color: "black",
                                  minWidth: "100px",
                                }
                              : data?.status == "Close" ||
                                data?.status == "Closed"
                              ? {
                                  backgroundColor: "lightgreen",
                                  color: "green",
                                  minWidth: "100px",
                                }
                              : data?.status == "Completed"
                              ? {
                                  backgroundColor: "lightyellow",
                                  color: "brown",
                                  minWidth: "100px",
                                }
                              : {}
                          }
                          label={data?.status}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      margin: "10px 0px",
                    }}
                  />
                  <Grid>
                    <Grid
                      sx={12}
                      md={12}
                      xl={12}
                      lg={12}
                      sm={12}
                      item
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Typography
                        style={{
                          color: "grey",
                        }}
                      >
                        {`Opened ${moment(data?.dateCreated).format(
                          "MMMM DD,YYYY , hh:mm a"
                        )}`}
                      </Typography>
                    </Grid>
                    <Grid>
                      <div
                        style={{
                          maxHeight: "100px",
                          overflow: "auto",
                        }}
                      >
                        <Typography>{data.description}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflow: "auto",
                    }}
                  ></div>
                  {supportHistory?.map((item, index) => {
                    let status = "";

                    if (currentStatus == "Completed") {
                      status = "Reopened";
                    } else {
                      status = item.status;
                    }
                    currentStatus = item.status;

                    return <SupportList data={item} status={status} />;
                  })}
                </div>
                {data.status == "Open" && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      disableUnderline={false}
                      onChange={(e) => setCommentDescription(e.target.value)}
                      value={commentDescription}
                      placeholder="Write a comment"
                      style={{
                        backgroundColor: "lightgray",
                        marginTop: "10px",
                        borderRadius: "10px",
                      }}
                      size="small"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                    <IconButton
                      disabled={commentDescription == ""}
                      onClick={() => HandelSaveClientHistory("comment")}
                    >
                      <SendIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          color:
                            commentDescription == ""
                              ? "grey"
                              : localStorage.clientAccentColor,
                        }}
                      />
                    </IconButton>
                  </div>
                )}
                {data.status == "Completed" && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: BILightRed,
                        color: BIRed,
                        textTransform: "none",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        HandelSaveClientHistory("re-open");
                      }}
                    >
                      Re-open Request
                    </Button>
                    <Button
                      fullWidth
                      style={{
                        borderRadius: "10px",
                        backgroundColor: BILightGreen,
                        color: BIGreen,
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setCloseRequestModel(true);
                      }}
                    >
                      Close Request
                    </Button>
                    <IconButton onClick={() => setHelpOpen(true)}>
                      <HelpIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          color: localStorage.clientAccentColor,
                        }}
                      />
                    </IconButton>
                  </div>
                )}
                <div>
                  <PictureViewer
                    isOpen={pictureView}
                    onClose={() => setPictureView(false)}
                    viewerIndex={index}
                    pictures={data?.pictureList?.map((item) => ({
                      src: item.fileUrl,
                    }))}
                  />
                  <HelpModal
                    open={helpOpen}
                    onClose={() => {
                      setHelpOpen(false);
                    }}
                  />
                  <CloseRequestModal
                    open={closeRequestModel}
                    supportId={data.id}
                    onClose={() => {
                      HandelSaveClientHistory("closed");
                      setCloseRequestModel(false);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

const SupportList = ({ data, status }) => {
  return (
    <>
      <>
        {data.comment.trim() == "" ? (
          <>
            {data.status != "Closed" && (
              <Grid
                display={"flex"}
                justifyContent={"center"}
                marginTop={1}
                sx={12}
                md={12}
                xl={12}
                lg={12}
                sm={12}
              >
                <Typography style={{ color: "grey" }}>
                  {}
                  {` ${status} ${moment(data?.statusChangedDate).format(
                    "MMMM DD, YYYY hh:mm a"
                  )}`}
                </Typography>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
              <>
                <Grid
                  sx={6}
                  md={6}
                  xl={6}
                  lg={6}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={1}
                  item
                >
                  <Grid display={"flex"} alignItems={"center"} gap={1} item>
                    <Avatar src={data?.userProfilePicture} />
                    <Typography>{data?.statusChangedByUser}</Typography>
                  </Grid>
                  <Typography style={{ color: "grey" }}>
                    {moment(data?.statusChangedDate).format("MMMM DD, YYYY")}
                  </Typography>
                </Grid>
                <Grid marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
                  <Typography>{data?.comment}</Typography>
                </Grid>
              </>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                marginTop={1}
                sx={12}
                md={12}
                xl={12}
                lg={12}
                sm={12}
              >
                <Typography style={{ color: "grey" }}>
                  {` ${status} ${moment(data?.statusChangedDate).format(
                    "MMMM DD, YYYY hh:mm a"
                  )}`}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </>
  );
};

const HelpModal = ({ open, onClose, data }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-center">
        <div
          style={{
            padding: "20px 20px 10px 20px",
            width: "600px",
          }}
          className="modal-card"
        >
          <Typography>
            Clode this Support Request to Let <b>Thermal Organization</b> Know
            that your issue has been resolved.
          </Typography>
          <Typography>
            if you require further support, you can re-open this request
          </Typography>
          <Button onClick={() => onClose()}>Ok</Button>
        </div>
      </div>
    </Modal>
  );
};

const CloseRequestModal = ({ open, onClose, supportId }) => {
  const [Review, setReview] = useState("");
  const [RatingValue, setRatingValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const handelSave = async () => {
    setLoading(true);
    try {
      let obj = {
        UserId: localStorage.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            SupportId: supportId,
            Rating: RatingValue,
            Review: Review,
            ModifiedBy: localStorage.userId,
          },
        ],
      };
      const res = await saveSupportReviews(obj);
      setLoading(false);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            outline: "none",
          }}
          className="modal-center"
        >
          <div
            style={{
              padding: "20px",
              width: "600px",
              outline: "none",
            }}
            className="modal-card"
          >
            <Typography>
              How would you rate the support you received?
            </Typography>
            <Rating
              name="simple-controlled"
              value={RatingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
              style={{
                marginTop: "5px",
              }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={Review}
              onChange={(e) => setReview(e.target.value)}
              disableUnderline={false}
              placeholder="Write a review..."
              style={{
                backgroundColor: "lightgray",
                marginTop: "5px",
                borderRadius: "10px",
              }}
              size="small"
              sx={{
                "& fieldset": { border: "none" },
              }}
              multiline
              rows={4}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <LoadingButton
                style={{
                  backgroundColor: "lightgray",
                  color: "black",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                fullWidth
                onClick={() => onClose()}
              >
                Skip
              </LoadingButton>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                style={{
                  backgroundColor: localStorage.clientAccentColor,
                  color: "white",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                fullWidth
                onClick={() => handelSave()}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
