import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
  Radio,
} from "@material-ui/core";
import { TextField, InputAdornment } from "@material-ui/core";
import moment from "moment";
import {
  SaveUserQuizAnswers,
  saveUserAnswers,
  saveUserQuestion,
} from "../../api/QuizApi";
import PromptModal from "../../components/PromptModal";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import {
  fetchSiteCompanyRoles,
  fetchSites,
  saveSiteCompany,
} from "../../api/SitesApi";
import { fetchProducts, saveProducts } from "../../api/ProductsApi";
import { fetchRoles, saveRole } from "../../api/RolesApi";
import { fetchTrades, requestTrade } from "../../api/TradesApi";
import { fetchCompanies, saveCompanies2 } from "../../api/CompanyApi";
import {
  GetNewQuestionForTheUser2,
  fetchUserSitesProjects,
  getNewQuestionForTheUser,
} from "../../api/UserApi";
import BuilderBucksBalance from "../../components/BuilderBucksBalance";
import Store from "./Rewards";
import { FaBriefcase, FaMapMarkerAlt, FaWrench } from "react-icons/fa";
import DateSelector from "../../components/DateSelector";
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  Autocomplete as GooglePlacesAutocomplete,
  LoadScript,
} from "@react-google-maps/api";
import { useRef } from "react";
import UserUsages from "../../components/userUsages/UserUsages";
import {
  fetchUserRewardactivities,
  getUserRewardActivities2,
} from "../../api/RewardsApi";
import {
  Autocomplete,
  Box,
  Input,
  ListItemButton,
  createFilterOptions,
} from "@mui/material";
import { fetchTicketTypes } from "../../api/TicketsApi";
import {
  fetchProjectCompanyRoles,
  getProjectTypes,
} from "../../api/ProjectsApi";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ChooseLocationModal from "./ChooseLocationModal";

const filter = createFilterOptions();
let answersArray = [];

function EarnBuilderBucks(props) {
  const [earnBuilderBucks, setEarnBuilderBucks] = useState(null);
  const [remainingQuestion, setRemainingQuestion] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [prevQueId, setPrevQuesId] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [options, setOptions] = useState([]);
  const { pathname } = useLocation();
  const appLocation = useLocation();

  const updateEarnBuck = useCallback(async () => {
    try {
      if (props?.from !== "dashboard-profile") {
        setLoadingData(true);
      }
      const questionData = await GetNewQuestionForTheUser2(localStorage.userId);
      if (questionData) {
        if (questionData?.quizType == "Select") {
          const options = questionData?.options
            ?.split(";")
            .filter((op) => op?.trim() != "");
          const newOptions = options.map((op) => {
            return { isSelected: false, optionName: op, isTextField: false };
          });
          setOptions(newOptions ?? []);
        }
        setEarnBuilderBucks(questionData);
        if (appLocation?.state?.obj?.from == "map") {
          const questionData = appLocation?.state?.obj?.question ?? {};
          setPrevQuesId(questionData?.id);
        }
        setPrevQuesId(questionData?.id);
      } else {
        setEarnBuilderBucks(
          `No more question available at this time ! \n Please try again later !`
        );
        props?.setVisibleQuestions(false);
        setRemainingQuestion("empty");
      }
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.log("GetQuestionError:", error);
    }
  }, []);

  // On page load, retrieve store from API
  useEffect(() => {
    UserUsages("EarnBuilderBucks");
    if (appLocation?.state?.obj?.from == "map") {
      if (appLocation?.state?.obj?.for == "site") {
        const questionData = appLocation?.state?.obj?.question ?? {};
        const selectedSitefromMap = appLocation?.state?.obj?.siteInfo ?? {};
        setSelectedSite(selectedSitefromMap);
        setEarnBuilderBucks(questionData);
        setPrevQuesId(questionData?.id);
        setLoadingData(false);
      } else {
        const questionData = appLocation?.state?.obj?.question ?? {};
        const selectedProjectfromMap = appLocation?.state?.obj?.project ?? null;
        setSelectedProject(selectedProjectfromMap);
        setEarnBuilderBucks(questionData);
        setPrevQuesId(questionData?.id);
        setLoadingData(false);
      }
    } else {
      updateEarnBuck();
    }
  }, []);

  return (
    <>
      {pathname !== "/dashboard" && (
        <Store activeTab={props?.activeTab ?? ""} />
      )}
      <div className={pathname !== "/dashboard" && "page"}>
        <div className={pathname !== "/dashboard" && "page-content"}>
          <div className="earnBuck-cards-container">
            {pathname !== "/dashboard" && <BuilderBucksBalance />}
            {loadingData ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                <EarnBuilderBucksCard
                  id={earnBuilderBucks?.id}
                  getQuestions={() => updateEarnBuck()}
                  question={earnBuilderBucks}
                  remainingQuestion={remainingQuestion}
                  config="view-edit"
                  prevQueId={prevQueId}
                  setLoadingData={setLoadingData}
                  selectedProject={selectedProject}
                  from={appLocation?.state?.obj?.from}
                  appLocation={appLocation}
                  setSelectedProject={setSelectedProject}
                  options={options}
                  selectedSite={selectedSite}
                  setSelectedSite={setSelectedSite}
                  fromDashboard={props?.from}
                  setVisibleQuestions={props?.setVisibleQuestions}
                  setAnswercount={props.setAnswercount}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EarnBuilderBucks;

function EarnBuilderBucksCard(props) {
  const today = moment();
  const userId = localStorage.userId;
  const [answer1, setAnswer1] = useState("");
  const [currentQuestionNr, setCurrentQueNr] = useState(0);
  const [isSaveAlert, setIsSaveAlert] = useState(false);
  const [isNewAlert, setIsNewAlert] = useState(false);
  const [isAddTextFieldVisible, setIsAddTextFieldVisible] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [isPresent, setIsPresent] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [optionText, setOptionText] = useState("");
  const [options, setOptions] = useState(props?.options ?? []);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchFields, setSearchFields] = useState([]);
  const [otherCompanyRole, setOtherCompanyRole] = useState("");
  const [autocomplete, setAutocomplete] = useState(null); // Google Places Autocomplete API instance
  const [scriptLibraries] = useState(["places"]);
  const [addressData, setAddressData] = useState({
    address: "",
    country: "",
    address1: "",
    province: "",
    city: "",
    postalCode: "",
    latitude: 0,
    longitude: 0,
  });
  const { pathname } = useLocation();
  const history = useHistory();
  let isRoleIdAssigned = useRef(false);
  const [questionEntityName, setQuestionEntityName] = useState("");
  const [questionEntityId, setQuestionEntityId] = useState("");
  const [flag, setFlag] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(false);
  const [showChooseLocationModal, setShowChooseLocationModal] = useState(false);

  useEffect(() => {
    if (props?.options?.length > 0) {
      setOptions(props?.options ?? []);
    }
    localStorage.setItem("quizQuestionId", props?.question?.id);
  }, [props?.options?.length > 0]);

  const saveCompanyRole = async (
    itemId,
    companyRole1,
    otherCompanyRole1,
    key
  ) => {
    if (companyRole1.toLowerCase() === "other" && otherCompanyRole1 === "") {
      alert("Please indicate company role");
      return;
    }
    try {
      const obj = {
        Id: 0,
        SiteId: Number(props?.question?.variableTypeBId ?? 0),
        CompanyId: Number(props?.question?.variableTypeAId ?? 0),
        CreatedBy: Number(userId),
        DateCreated: moment().format(),
        DateModified: moment().format(),
        ModifiedBy: Number(userId),
        SiteCompanyRoleId: itemId,
        OtherSiteCompanyRole: otherCompanyRole1,
      };
      const result = await saveSiteCompany(obj);
      answersArray.push(Number(result?.data));
      if (key === 1) {
        setAnswer1(result?.data);
        isRoleIdAssigned.current = true;
        handleAnswers(1);
      } else {
        const newData = searchFields.filter((fields) => {
          return (
            fields.name.toLowerCase() !== companyRole1.toLowerCase() &&
            fields.otherCompanyRole.toLowerCase() !==
              otherCompanyRole1.toLowerCase()
          );
        });
        setSearchFields(newData);
        if (newData.length === 0) {
          handleAnswers(1);
        }
      }
    } catch (ex) {
      console.log("ErrorCompanyRole:", ex.message ?? ex);
      setSearchFields([]);
      return;
    }
  };

  const handleAnswers = async (response) => {
    props?.setLoadingData(true);
    if (response === 1) {
      switch (props?.question?.quizType) {
        case "Search":
          if (
            props?.question?.responseType === "SiteCompanyRole" &&
            !isRoleIdAssigned
          ) {
            await saveCompanyRole(answer1, searchItem, otherCompanyRole, 1);
            return;
          }
          if (props?.from !== "map" && answer1 === "") {
            alert(
              "Oops! You forgot to add the answer. You can also skip this question by clicking Don't Ask Again or Ask Me Later."
            );
            return;
          }
          // else if (props?.from !== "map" && answer1 === 0) {
          //   saveSelectedItem(selectedItem, 1);
          //   return;
          // };
          if (
            props?.question?.responseType === "SiteCompanyRole" &&
            searchFields.length !== 0
          ) {
            searchFields.map((data) =>
              saveCompanyRole(data.id, data.name, data.otherCompanyRole, 2)
            );
            return;
          } else if (props?.question?.response_EntityName == "Project") {
            answersArray.push(props?.selectedProject?.id);
          } else if (props?.question?.response_EntityName?.trim() == "Site") {
            answersArray.push(props?.selectedSite?.id);
          } else if (props?.question?.responseType != "SiteCompanyRole") {
            if (answer1 == 0 && questionEntityName != "") {
              let getCompanyId = await saveSelectedItem(
                questionEntityName,
                props?.question?.response_EntityName
              );
              answersArray.push(getCompanyId);
            } else {
              answersArray.push(answer1);
            }
          }
          break;
        case "Date":
          if (
            props?.question?.minResponses !== 1 &&
            !currentCompany &&
            moment(startDate).format("YYYY-DD-MM") >
              moment(endDate).format("YYYY-DD-MM")
          ) {
            alert(
              "Start Date should be before the End Date or click Present if you are still working on this site."
            );
            return;
          }
          break;
        case "Multiline":
          answersArray.push(answer1);
          break;
        case "Address":
          const addArray = {
            address: addressData.address,
            address1: addressData.address1,
            city: addressData.city,
            country: addressData.country,
            province: addressData.province,
            postalCode: addressData.postalCode,
            latitude: addressData.latitude,
            longitude: addressData.longitude,
          };
          answersArray.push(addArray);
          break;
        case "Number":
          answersArray.push(answer1);
          break;
        case "Select":
          const selectedOptions = options.filter((op) => op.isSelected);
          if (selectedOptions.length > props?.question?.maxResponses) {
            alert(
              `You can only select upto ${props?.question?.maxResponses} responses`
            );
            return;
          }
          selectedOptions.map((op) => answersArray.push(op.optionName));
          break;
        default:
          return;
      }
    }
    try {
      if (response === 1) {
        saveAnswers(0);
        return;
      } else if (response == 2) {
        const obj2 = {
          UserId: Number(userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              UserId: Number(userId),
              UserQuizId: props?.prevQueId,
              AnswerTypeId: 2,
              Answer: "",
              Answer1: "",
              Answer2: "",
              Answer3: "",
              Answer4: "",
              Answer5: "",
              Answer6: "",
              Answer7: "",
              Answer8: "",
              Answer9: "",
            },
          ],
        };
        await SaveUserQuizAnswers(obj2);
        //<--- ask me later section
      } else if (response == 3) {
        const obj2 = {
          UserId: Number(userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              UserId: Number(userId),
              UserQuizId: props?.prevQueId,
              AnswerTypeId: 3,
              Answer: "",
              Answer1: "",
              Answer2: "",
              Answer3: "",
              Answer4: "",
              Answer5: "",
              Answer6: "",
              Answer7: "",
              Answer8: "",
              Answer9: "",
            },
          ],
        };
        await SaveUserQuizAnswers(obj2);
      }
      answersArray = [];
      setAnswer1("");
      setSearchItem("");
      setOtherCompanyRole("");
      setSearchFields([]);
      setAddressData({
        address: "",
        address1: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        latitude: 0,
        longitude: 0,
      });
      setCurrentQueNr(currentQuestionNr + 1);
      setStartDate(null);
      setEndDate(null);
      setOptions([]);
      setOptionText("");
      props?.setSelectedProject("");
      props?.setSelectedSite("");
      setOptionText("");
      isRoleIdAssigned.current = false;
      history.replace({ ...props?.appLocation, state: null, search: "" });
      // await props.getQuestions();
      props?.setLoadingData(false);
      if (props?.fromDashboard == "dashboard-profile") {
        props?.setVisibleQuestions(false);
        return;
      }
      await props.getQuestions();
    } catch (error) {
      console.log("SaveUserQue:", error);
      alert(error.message);
    }
  };

  const saveAnswers = async () => {
    try {
      if (props?.question?.quizType == "Address") {
        const obj2 = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              UserId: Number(localStorage.userId),
              UserQuizId: props?.prevQueId,
              AnswerTypeId: 1,
              Answer: addressData.address ?? "",
              Answer1: addressData.address1 ?? "",
              Answer2: addressData.city ?? "",
              Answer3: addressData.province ?? "",
              Answer4: addressData.country ?? "",
              Answer5: addressData.postalCode ?? "",
              Answer6: addressData.latitude ?? "",
              Answer7: addressData.longitude ?? "",
              Answer8: "",
              Answer9: "",
            },
          ],
        };
        await SaveUserQuizAnswers(obj2);
      } else if (props?.question?.quizType == "Date") {
        const obj2 = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              UserId: Number(localStorage.userId),
              UserQuizId: props?.prevQueId,
              AnswerTypeId: 1,
              Answer: moment(startDate).format("YYYY-MM-DD") ?? "",
              Answer1:
                endDate != ""
                  ? moment(endDate).format("YYYY-MM-DD")
                  : currentCompany
                  ? moment().format("YYYY-MM-DD")
                  : "",
              Answer2: "",
              Answer3: "",
              Answer4: "",
              Answer5: "",
              Answer6: "",
              Answer7: "",
              Answer8: "",
              Answer9: "",
            },
          ],
        };
        await SaveUserQuizAnswers(obj2);
        if (props?.question?.response_EntityName == "Company") {
          // await updateCompanyData();
        }
      } else {
        if (props?.question?.canAddResponse) {
          const obj2 = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                UserId: Number(localStorage.userId),
                UserQuizId: props?.prevQueId,
                AnswerTypeId: 1,
                Answer: "",
                Answer1: "",
                Answer2: "",
                Answer3: "",
                Answer4: "",
                Answer5: "",
                Answer6: "",
                Answer7: "",
                Answer8: "",
                Answer9: "",
              },
            ],
          };
          let userObjectCopy = { ...obj2 };
          answersArray.forEach((answer, index) => {
            if (index === 0) {
              userObjectCopy.SaveList[0].Answer = answer.toString();
            } else if (index >= 1 && index <= 8) {
              userObjectCopy.SaveList[0][`Answer${index}`] = answer.toString();
            }
          });
          await SaveUserQuizAnswers(obj2);
          await getUserrewardActivities();
        } else {
          const ans = answersArray
            ?.map((ans) => {
              return ans;
            })
            ?.pop();
          const obj2 = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                UserId: Number(localStorage.userId),
                UserQuizId: props?.prevQueId,
                AnswerTypeId: 1,
                Answer: typeof ans == "number" ? `${ans}` : ans,
                Answer1: "",
                Answer2: "",
                Answer3: "",
                Answer4: "",
                Answer5: "",
                Answer6: "",
                Answer7: "",
                Answer8: "",
                Answer9: "",
              },
            ],
          };
          await SaveUserQuizAnswers(obj2);
        }
      }
      answersArray = [];
      setAnswer1("");
      setSearchItem("");
      setOtherCompanyRole("");
      setSearchFields([]);
      setAddressData({
        address: "",
        address1: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        latitude: 0,
        longitude: 0,
      });
      setCurrentQueNr(currentQuestionNr + 1);
      setStartDate(null);
      setEndDate(null);
      setOptions([]);
      setOptionText("");
      props?.setSelectedProject("");
      props?.setSelectedSite("");
      setOptionText("");
      isRoleIdAssigned.current = false;
      history.replace({ ...props?.appLocation, state: null, search: "" });

      props?.setLoadingData(false);
      if (props?.fromDashboard == "dashboard-profile") {
        props?.setVisibleQuestions(false);
        return;
      }
      await props.getQuestions();
    } catch (error) {
      props?.setLoadingData(false);
      console.log("SaveAns___Error:", error);
    }
  };

  const saveSelectedItem = async (selectedItem, option) => {
    const obj = {
      Id: 0,
      Name: selectedItem,
      CreatedBy: Number(localStorage?.userId),
      DateCreated: moment().format(),
      ModifiedBy: Number(localStorage?.userId),
      DateModified: moment().format(),
    };

    const tradeObj = {
      UserId: 1,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [{ Id: 0, Name: selectedItem, Description: "", ModifiedBy: 1 }],
    };

    const mainObj = {
      UserId: Number(localStorage?.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
    };
    const saveObject = {
      ...mainObj,
      SaveList: [
        {
          Id: 0,
          Name: selectedItem,
          ModifiedBy: Number(localStorage?.userId),
          RecordStatusId: 1,
          Manufacturer: false,
        },
      ],
    };
    try {
      if (option == "Company") {
        const res = await saveCompanies2(saveObject);
        return res[0]?.objData?.id;
      } else if (option == "Trade") {
        const formData = new FormData();
        formData.append("Id", 0);
        formData.append("Name", selectedItem);
        formData.append("TradePictureUrl", "");
        formData.append("Description", "");
        formData.append("ModifiedBy", Number(localStorage?.userId));

        let tradeRes = await requestTrade(formData);
        return tradeRes;
      } else if (option == "Product") {
        const productObj = {
          UserId: Number(localStorage?.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              Name: selectedItem,
              Info: "",
              RecordStatusId: 1,
              ModifiedBy: Number(localStorage.userId),
              ManufacturerId: 0,
            },
          ],
        };
        const productRes = await saveProducts(productObj);
        return productRes[0]?.objData?.id;
      } else if (option == "Title/Role" || option == `Company's Role`) {
        const roleId = await saveRole(obj);
        return roleId;
      }
    } catch (err) {
      console.log("Error:[ADD_EXPERIENCE_COMPANY] SAVE_SELECTED_ITEM:", err);
    }
  };

  const getUserrewardActivities = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: "false",
        TypeOfObjectReturned: "",
        SearchList: [
          {
            RecordId: props.id,
            UserId: localStorage.userId,
            ApplicationId: 6,
          },
        ],
        SortList: [{}],
      };
      const getResponse = await fetchUserRewardactivities(obj);
      if (getResponse?.length > 0) {
        props.setAnswercount(getResponse[0]?.bucks);
      }
    } catch (error) {}
  };

  const [isClosing, setIsClosing] = useState(false);

  const onSubmitOption = (index) => {
    if (optionText !== "" && isClosing) {
      const newOptions = options.filter((op, i) => {
        if (i === index) {
          op.isTextField = false;
          op.optionName = optionText;
        }
        return op;
      });
      setOptions(newOptions);
      setOptionText("");
      setIsAddTextFieldVisible(false);
      setIsClosing(false);
      setAnswer1(newOptions.length > 0 ? "Add" : "");
    } else {
      const newOptions = options.filter((item, i) => i !== index);
      setOptions(newOptions);
      setOptionText("");
      setIsAddTextFieldVisible(false);
    }
    setTest(false);
  };

  const onCloseOption = (e, index) => {
    e.stopPropagation(); // Prevents blur event from firing
    setIsClosing(false);
    const newOptions = options.filter((item, i) => i !== index);
    setOptions(newOptions);
    setOptionText("");
    setIsAddTextFieldVisible(false);
  };

  const onAddAnswerPressed = () => {
    searchFields.push({
      name: "",
      id: 0,
      roleId: 0,
      manufacturerId: 0,
      manufacturerName: "",
      otherCompanyRole: "",
      isTextField: true,
    });
    setSearchFields([...searchFields]);
  };

  const onAddOptionPressed = () => {
    setIsClosing(true);
    options.push({ isSelected: false, optionName: "", isTextField: true });
    Promise.resolve(setOptions([...options])).then(() => {
      setIsAddTextFieldVisible(true);
    });
  };

  const onOptionSelected = (index) => {
    if (props?.question?.maxResponses !== 1) {
      const updatedOptions = [...options];
      const updatedSelectedOptions = [...selectedOptions];

      if (updatedOptions[index].isSelected) {
        updatedOptions[index].isSelected = false;
        const newData = updatedSelectedOptions.filter(
          (op) => op.optionName !== updatedOptions[index].optionName
        );
        const newOptions = updatedOptions.filter(
          (op) => op.isSelected && op.optionName !== ""
        );
        setOptions(updatedOptions);
        setAnswer1(newOptions.length > 0 ? "Add" : "");
        setSelectedOptions(newData);
      } else {
        updatedOptions[index].isSelected = true;
        updatedSelectedOptions.push(updatedOptions[index]);
        const newOptions = updatedOptions.filter(
          (op) => op.isSelected && op.optionName !== ""
        );
        setOptions(updatedOptions);
        setAnswer1(newOptions.length > 0 ? "Add" : "");
        setSelectedOptions(updatedSelectedOptions);
      }

      const optionsData = updatedOptions.filter((op) => op.isSelected);
      if (optionsData.length > props?.question?.maxResponses) {
        const removed = updatedSelectedOptions.shift();
        updatedOptions.forEach((op) => {
          if (op.optionName === removed.optionName) {
            op.isSelected = false;
          }
        });
        setOptions(updatedOptions);
      }
    } else if (props?.question?.maxResponses === 1) {
      const newOptions = options.map((op, i) => {
        if (i === index) op.isSelected = true;
        else op.isSelected = false;
        return op;
      });
      const newOptionsData = newOptions.filter(
        (op) => op.isSelected && op.optionName !== ""
      );
      setOptions(newOptions);
      setAnswer1(newOptionsData.length > 0 ? "Add" : "");
    }
  };

  async function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getFilterQuestionData = async (searchText) => {
    if (searchText !== "" && searchText?.length > 1) {
      setIsSearching(true);

      const object = {
        PageNr: 1,
        FullSearch: "",
        SearchList: [
          {
            Name: searchText,
            // Manufacturer: false,
            Manufacturer:
              props?.question?.response_EntityName?.toLowerCase() ==
              "manufacturer"
                ? true
                : false,
          },
        ],
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
        IncludeRecordNr: false,
        TypeOfObjectReturned: "NameOnly",
      };
      const productObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: userId,
        IncludeRecordNr: false,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            Name: searchText,
            // ManufacturerId: manufacturerId
          },
        ],
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
      };
      const companyRoleObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: userId,
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [{ Name: searchText }],
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
      };

      if (props?.question?.response_EntityName === "Company") {
        const companyRes = await fetchCompanies(object);
        let a;
        companyRes?.data?.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        const response = await removeDuplicates(companyRes?.data, "name");
        setFilterData(response);
      } else if (props?.question?.response_EntityName === "Product") {
        const productRes = await fetchProducts(productObj);
        let a;
        productRes?.data?.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        const response = await removeDuplicates(productRes?.data, "name");
        setFilterData(response);
        // setFilterData(productRes.data)
      } else if (
        props?.question?.response_EntityName?.toLowerCase() == "role/title" ||
        props?.question?.response_EntityName?.toLowerCase() == "role" ||
        props?.question?.response_EntityName?.toLowerCase() == "title/role"
      ) {
        const roleRes = await fetchRoles(object, "All");
        let a;
        roleRes?.data?.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        const response = await removeDuplicates(roleRes?.data, "name");
        setFilterData(response);
        // setFilterData(roleRes?.data);
      } else if (
        props?.question?.response_EntityName?.toLowerCase() == "trade"
      ) {
        const tradeRes = await fetchTrades(object, "All");
        let a;
        tradeRes?.data?.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        const response = await removeDuplicates(tradeRes?.data, "name");
        setFilterData(response);
      } else if (
        props?.question?.response_EntityName?.toLowerCase() == "project"
      ) {
        const projectObj = {
          PageNr: 1,
          FullSearch: "",
          SearchList: [
            {
              ProjectName: searchText,
              Manufacturer:
                props?.question?.response_EntityName?.toLowerCase() ==
                "manufacturer"
                  ? true
                  : false,
            },
          ],
          IncludeRecordNr: false,
          NrOfRecPerPage: 20,
          FetchAllowedRecordsOnly: true,
        };
        const projectRes = await fetchUserSitesProjects(projectObj);
        let a;
        projectRes?.map((x) => {
          a = x;
          a["title"] = x?.projectName;
          return a;
        });
        const response = await removeDuplicates(projectRes, "projectName");
        setFilterData(response);
      } else if (
        props?.question?.response_EntityName?.toLowerCase() == "ticket type"
      ) {
        const ticketRes = await fetchTicketTypes(object);
        setFilterData(ticketRes);
      } else if (
        props?.question?.response_EntityName?.toLowerCase() === "manufacturer"
      ) {
        const manufacturerRes = await fetchCompanies(object);
        let a;
        manufacturerRes?.data?.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        const response = await removeDuplicates(manufacturerRes?.data, "name");
        setFilterData(response);
      } else if (props?.question?.response_EntityName === "Site") {
        const siteRes = await fetchSites(object);
        let a;
        siteRes?.data?.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        const response = await removeDuplicates(siteRes?.data, "name");
        setFilterData(response);
      } else if (
        props?.question?.response_EntityName?.toLowerCase() == "site type"
      ) {
        const siteTypeRes = await getProjectTypes(object);
        setFilterData(siteTypeRes);
      } else if (
        props?.question?.response_EntityName == "CompanyRole" ||
        props?.question?.response_EntityName == "ProjectCompanyRole" ||
        props?.question?.response_EntityName == `Company's Role`
      ) {
        const projectCompanyRolesRes = await fetchProjectCompanyRoles(
          companyRoleObj
        );
        let a;
        projectCompanyRolesRes.map((x) => {
          a = x;
          a["title"] = x?.name;
          return a;
        });
        setFilterData(projectCompanyRolesRes);
      }
    } else {
      setIsSearching(false);
    }
  };

  const onPresentChange = () => {
    setCurrentCompany(!currentCompany);
    setEndDate(null);
    setAnswer1(startDate != null && !currentCompany ? moment() : "");
  };

  const handleStartDateChange = (date) => {
    let getDate = moment(date).startOf("month");
    const newDate = moment(getDate).format("YYYY-MM-DD");
    setStartDate(newDate);
    let checkEndDate = endDate == null ? "" : endDate;
    setAnswer1(
      props?.question?.minResponses != 1
        ? checkEndDate != ""
          ? checkEndDate
          : currentCompany
          ? moment()
          : ""
        : "2"
    );
  };

  const handleEndDateChange = (date) => {
    let getDate = moment(date).startOf("month");
    const newDate = moment(getDate).format("YYYY-MM-DD");
    setEndDate(newDate);
    setAnswer1(startDate == null ? "" : newDate);
  };

  const onChooseSitePressed = () => {
    history.push({
      pathname: "/new-map",
      state: {
        from: "quiz_site",
        questionData: props?.question,
        entityName: "Site",
        fromQuestion: props?.fromDashboard,
      },
    });
  };

  const onChooseProjectPressed = () => {
    const obj = {
      allowNewSite: false,
      chooseProjectForQuiz: true,
      questionData: props?.question,
    };

    history.push({
      pathname: "/new-map",
      state: {
        obj,
        from: "quiz",
        fromQuestion: props?.fromDashboard,
      },
    });
  };

  const [test, setTest] = useState(false);

  return (
    <>
      <div
        className="store-card"
        style={{
          margin:
            props?.fromDashboard == "dashboard-profile" ? "0px" : "15px 0px",
        }}
      >
        {/* <div className={pathname !== "/dashboard" && "store-card-content"}> */}
        <div className={"store-card-content"}>
          {props?.question ? (
            <div>
              <p
                className="store-card-title "
                style={{ color: "black", margin: "5px", marginTop: "0px" }}
              >
                {props?.question?.quizDescription}
              </p>
              {props?.question?.parameter1 != "" && (
                <div
                  className={"blue-text"}
                  style={{ margin: "5px", marginTop: "0px" }}
                >
                  {props?.question?.parameter1_Name?.trim() != "" &&
                  (props?.question?.quiz_EntityName1 == "Project" ||
                    props?.question?.quiz_EntityName1 == "Site  " ||
                    props?.question?.quiz_EntityName1 == "Site" ||
                    props?.question?.quiz_EntityName1 == "Role" ||
                    props?.question?.quiz_EntityName1 == "Role/title") ? (
                    <>
                      <FaMapMarkerAlt />{" "}
                      <span
                        style={{ paddingLeft: "10px" }}
                        className={
                          props?.question?.quiz_EntityName1 == "Project" ||
                          props?.question?.quiz_EntityName1 == "Company" ||
                          props?.question?.quiz_EntityName1 ==
                            "Manufacturers" ||
                          props?.question?.quiz_EntityName1 == "Product"
                            ? "link"
                            : ""
                        }
                        onClick={() => {
                          if (props?.question?.quiz_EntityName1 == "Project") {
                            history.push(
                              `/projects/project/${Number(
                                props?.question?.parameter1
                              )}`
                            );
                            return;
                          }
                        }}
                      >
                        {props?.question?.parameter1_Name}
                      </span>
                    </>
                  ) : props?.question?.parameter1_Name?.trim() != "" &&
                    (props?.question?.quiz_EntityName1 == "Company" ||
                      props?.question?.quiz_EntityName1 == "Manufacturers") ? (
                    <>
                      <FaBriefcase />
                      <span
                        style={{ paddingLeft: "10px" }}
                        className={
                          props?.question?.quiz_EntityName1 == "Project" ||
                          props?.question?.quiz_EntityName1 == "Company" ||
                          props?.question?.quiz_EntityName1 ==
                            "Manufacturers" ||
                          props?.question?.quiz_EntityName1 == "Product"
                            ? "link"
                            : ""
                        }
                        onClick={() => {
                          if (props?.question?.quiz_EntityName1 == "Company") {
                            history.push(
                              `/company/${Number(props?.question?.parameter1)}`
                            );
                            return;
                          } else if (
                            props?.question?.quiz_EntityName1 == "Manufacturers"
                          ) {
                            history.push(
                              `/manufacturer/${Number(
                                props?.question?.parameter1
                              )}`
                            );
                            return;
                          }
                        }}
                      >
                        {props?.question?.parameter1_Name}
                      </span>
                    </>
                  ) : props?.question?.parameter1_Name?.trim() != "" &&
                    props?.question?.quiz_EntityName1 == "Product" ? (
                    <>
                      <FaWrench />
                      <span
                        style={{ paddingLeft: "10px" }}
                        className={
                          props?.question?.quiz_EntityName1 == "Project" ||
                          props?.question?.quiz_EntityName1 == "Company" ||
                          props?.question?.quiz_EntityName1 ==
                            "Manufacturers" ||
                          props?.question?.quiz_EntityName1 == "Product"
                            ? "link"
                            : ""
                        }
                        onClick={() => {
                          if (props?.question?.quiz_EntityName1 == "Product") {
                            history.push(
                              `/product/${Number(props?.question?.parameter1)}`
                            );
                            return;
                          }
                        }}
                      >
                        {props?.question?.parameter1_Name}
                      </span>
                    </>
                  ) : null}
                </div>
              )}
              {props?.question?.parameter1Additional_Name != "" && (
                <div
                  style={{
                    color: "GrayText",
                    margin: "5px",
                    marginTop: "0px",
                  }}
                >
                  {props?.question?.parameter1Additional_Name}
                </div>
              )}

              {props?.question?.parameter2 !== "" && (
                <div
                  className="blue-text"
                  style={{ margin: "5px", marginTop: "0px" }}
                >
                  {props?.question?.parameter2_Name?.trim() != "" &&
                  (props?.question?.quiz_EntityName2 == "Project" ||
                    props?.question?.quiz_EntityName2 == "Site  " ||
                    props?.question?.quiz_EntityName2 == "Site" ||
                    props?.question?.quiz_EntityName2 == "Role" ||
                    props?.question?.quiz_EntityName2 == "Role/title") ? (
                    <>
                      <FaMapMarkerAlt />
                      <span
                        style={{ paddingLeft: "10px" }}
                        className={
                          props?.question?.quiz_EntityName1 == "Project" ||
                          props?.question?.quiz_EntityName1 == "Company" ||
                          props?.question?.quiz_EntityName1 ==
                            "Manufacturers" ||
                          props?.question?.quiz_EntityName1 == "Product"
                            ? "link"
                            : ""
                        }
                        onClick={() => {
                          if (props?.question?.quiz_EntityName2 == "Project") {
                            history.push(
                              `/projects/project/${Number(
                                props?.question?.parameter2
                              )}`
                            );
                            return;
                          }
                        }}
                      >
                        {props?.question?.parameter2_Name}
                      </span>
                    </>
                  ) : props?.question?.parameter2_Name?.trim() != "" &&
                    (props?.question?.quiz_EntityName2 == "Company" ||
                      props?.question?.quiz_EntityName2 == "Manufacturers") ? (
                    <>
                      <FaBriefcase />
                      <span
                        style={{ paddingLeft: "10px" }}
                        className={
                          props?.question?.quiz_EntityName1 == "Project" ||
                          props?.question?.quiz_EntityName1 == "Company" ||
                          props?.question?.quiz_EntityName1 ==
                            "Manufacturers" ||
                          props?.question?.quiz_EntityName1 == "Product"
                            ? "link"
                            : ""
                        }
                        onClick={() => {
                          if (props?.question?.quiz_EntityName2 == "Company") {
                            history.push(
                              `/company/${Number(props?.question?.parameter2)}`
                            );
                            return;
                          } else if (
                            props?.question?.quiz_EntityName2 == "Manufacturers"
                          ) {
                            history.push(
                              `/manufacturer/${Number(
                                props?.question?.parameter2
                              )}`
                            );
                            return;
                          }
                        }}
                      >
                        {props?.question?.parameter2_Name}
                      </span>
                    </>
                  ) : props?.question?.parameter2_Name?.trim() != "" &&
                    props?.question?.quiz_EntityName2 == "Product" ? (
                    <>
                      <FaWrench />
                      <span
                        style={{ paddingLeft: "10px" }}
                        className={
                          props?.question?.quiz_EntityName1 == "Project" ||
                          props?.question?.quiz_EntityName1 == "Company" ||
                          props?.question?.quiz_EntityName1 ==
                            "Manufacturers" ||
                          props?.question?.quiz_EntityName1 == "Product"
                            ? "link"
                            : ""
                        }
                        onClick={() => {
                          if (props?.question?.quiz_EntityName2 == "Product") {
                            history.push(
                              `/product/${Number(props?.question?.parameter2)}`
                            );
                            return;
                          }
                        }}
                      >
                        {props?.question?.parameter2_Name}
                      </span>
                    </>
                  ) : null}
                </div>
              )}
              {props?.question?.parameter2Additional_Name != "" && (
                <div
                  style={{
                    color: "GrayText",
                    margin: "5px",
                    marginTop: "0px",
                  }}
                >
                  {props?.question?.parameter2Additional_Name}
                </div>
              )}

              {props?.question?.quizType == "Search" ? (
                <>
                  <div
                    style={{
                      width:
                        props?.fromDashboard == "dashboard-profile"
                          ? "100%"
                          : "50%",
                    }}
                  >
                    <div className="flex-container-horizontal">
                      <div className="flex-container-left" style={{ flex: 1 }}>
                        {props?.question?.response_EntityName?.trim() ==
                          "Site" ||
                        props?.question?.response_EntityName == "Project" ? (
                          <div style={{ margin: "5px" }}>
                            <TextField
                              variant="outlined"
                              // placeholder={`Search for a ${props?.question?.response_EntityName}`}
                              value={
                                props?.question?.response_EntityName?.trim() ==
                                "Site"
                                  ? props?.selectedSite?.name == ""
                                    ? "Unnamed Site"
                                    : props?.selectedSite?.name
                                  : props?.selectedProject?.projectName
                              }
                              onClick={
                                props?.question?.response_EntityName?.trim() ==
                                "Site"
                                  ? onChooseSitePressed
                                  : onChooseProjectPressed
                              }
                              fullWidth
                              label={`Search for a ${props?.question?.response_EntityName}`}
                            />
                          </div>
                        ) : (
                          // : props?.question?.response_EntityName ==
                          // "SiteCompanyRole" ? (
                          // <div style={{ margin: "5px", marginTop: "0px" }}>
                          //   <TextField
                          //     id="filled-read-only-input"
                          //     InputProps={{
                          //       readOnly: true,
                          //     }}
                          //     fullWidth
                          //     placeholder={`Search for a ${
                          //       props?.question?.response_EntityName ==
                          //       "SiteCompanyRole"
                          //         ? "Company Role"
                          //         : props?.question?.response_EntityName
                          //     }`}
                          //     variant={"outlined"}
                          //     size="small"
                          //   />
                          // </div>
                          // )
                          <div style={{ margin: "5px" }}>
                            <Autocomplete
                              // size="small"
                              disabled={props.edit ? true : false}
                              inputValue={questionEntityName}
                              onChange={(event, newValue) => {
                                if (newValue && newValue.inputValue) {
                                  setQuestionEntityName(newValue?.inputValue);
                                  setAnswer1(0);
                                  setFlag(false);
                                } else if (newValue == null) {
                                  setAnswer1("");
                                } else {
                                  setAnswer1(newValue?.id);
                                  setFlag(false);
                                }
                              }}
                              onInputChange={(event, newInputValue) => {
                                setQuestionEntityName(newInputValue);
                                setFlag(true);
                                if (newInputValue !== "") {
                                  getFilterQuestionData(newInputValue);
                                } else {
                                  setAnswer1("");
                                }
                                // else {
                                //     getCompanies();
                                // }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;

                                const isExisting = options.some(
                                  (option) => inputValue === option.title
                                );
                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    title: `Add "${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                              options={isSearching ? filterData : []}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option;
                                }
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                return option.title;
                              }}
                              renderOption={(props, option) => (
                                <li key={option?.id} {...props}>
                                  {option.title}
                                </li>
                              )}
                              style={{ flex: 1 }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`Search for a ${
                                    props?.question?.response_EntityName ==
                                    "SiteCompanyRole"
                                      ? "Company Role"
                                      : props?.question?.response_EntityName
                                  }`}
                                  variant={"outlined"}
                                  // size="small"
                                />
                              )}
                              open={questionEntityName?.length > 1 && flag}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {props?.question?.quizType != "Search" && (
                      <>
                        {props?.question?.canAddResponse &&
                          props?.question?.maxResponses > 1 && (
                            <div style={{ margin: "5px", marginTop: "0px" }}>
                              <Button
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => onAddAnswerPressed()}
                              >
                                ADD ANSWER
                              </Button>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </>
              ) : props?.question?.quizType === "Number" ? (
                <div
                  style={{
                    width:
                      props?.fromDashboard == "dashboard-profile"
                        ? "100%"
                        : "50%",
                    margin: "5px",
                    marginTop: "0px",
                  }}
                  // className="rewards-input-container"
                >
                  <TextField
                    variant="outlined"
                    label="Answer"
                    fullWidth
                    onChange={(e) => setAnswer1(e.target.value)}
                    type="number"
                    value={props?.question?.answer}
                    // size="small"
                  />
                </div>
              ) : props?.question?.quizType === "Date" ? (
                <>
                  <div
                    style={{
                      width:
                        props?.fromDashboard == "dashboard-profile"
                          ? "100%"
                          : "50%",
                      margin: "5px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      className="flex-container-vertical"
                      style={{ alignItems: "stretch" }}
                    >
                      <KeyboardDatePicker
                        autoOk
                        open={openDatePicker}
                        onClose={() => setOpenDatePicker(false)}
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        placeholder="Month-Year"
                        value={startDate === null ? null : startDate}
                        views={["year", "month"]}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => handleStartDateChange(date)}
                        maxDate={moment(new Date()).format("YYYY-MM")}
                        InputProps={{
                          readOnly: true,
                          onClick: () => setOpenDatePicker(true),
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      width:
                        props?.fromDashboard == "dashboard-profile"
                          ? "100%"
                          : "50%",
                      margin: "5px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      className="flex-container-vertical"
                      style={{ alignItems: "stretch" }}
                    >
                      <KeyboardDatePicker
                        autoOk
                        open={openEndDatePicker}
                        onClose={() => setOpenEndDatePicker(false)}
                        variant="inline"
                        inputVariant={currentCompany ? "filled" : "outlined"}
                        label="End Date"
                        placeholder="Month-Year"
                        value={
                          !currentCompany
                            ? endDate === null
                              ? null
                              : endDate
                            : null
                        }
                        views={["year", "month"]}
                        disabled={currentCompany}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => handleEndDateChange(date)}
                        maxDate={today.format("YYYY-MM-DD")}
                        InputProps={{
                          readOnly: true,
                          onClick: () => setOpenEndDatePicker(true),
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <Checkbox
                      color="primary"
                      checked={currentCompany}
                      onChange={() => onPresentChange()}
                    />
                    <span>Present</span>
                  </div>
                </>
              ) : props?.question?.quizType === "Select" ? (
                <div>
                  <p style={{ marginLeft: "5px" }}>
                    {props?.question?.maxResponses === 1
                      ? "Select 1"
                      : props?.question?.maxResponses > 1
                      ? `Select up to ${props?.question?.maxResponses}`
                      : "Select all that apply"}
                  </p>
                  {options?.map((op, index) => {
                    return (
                      <div key={index}>
                        <div className="flex-container-horizontal">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <ListItem
                              sx={{ display: "flex", flexDirection: "row" }}
                              key={index.toString()}
                              onClick={() => onOptionSelected(index)}
                              disablePadding
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={op?.isSelected}
                                  />
                                }
                              />
                              {op.isTextField ? (
                                <>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    value={optionText}
                                    onKeyPress={(e) => {
                                      if (e.nativeEvent.key === "Enter")
                                        onSubmitOption(index);
                                    }}
                                    fullWidth={
                                      props?.fromDashboard ==
                                      "dashboard-profile"
                                        ? true
                                        : false
                                    }
                                    // onBlur={() => {
                                    //   if (isClosing) {
                                    //       onSubmitOption(index);
                                    //   }
                                    //   // setIsClosing(false);
                                    // }}

                                    onSubmit={() => onSubmitOption(index)}
                                    onChange={(e) =>
                                      setOptionText(e.target.value)
                                    }
                                  />
                                  <IconButton
                                    onClick={(e) => {
                                      setTest(true);
                                      onCloseOption(e, index);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </>
                              ) : (
                                <ListItemText>
                                  {op?.optionName?.trim()}
                                </ListItemText>
                              )}
                            </ListItem>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {props?.question?.canAddResponse &&
                    !isAddTextFieldVisible && (
                      <div style={{ margin: "5px", marginTop: "0px" }}>
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          onClick={() => onAddOptionPressed()}
                        >
                          ADD OPTION
                        </Button>
                      </div>
                    )}
                </div>
              ) : props?.question?.quizType === "Multiline" ? (
                <div
                  style={{
                    width:
                      props?.fromDashboard == "dashboard-profile"
                        ? "100%"
                        : "50%",
                    margin: "5px",
                    marginTop: "0px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Answer"
                    multiline
                    sx={{ backgroundColor: "white" }}
                    fullWidth
                    value={props?.question?.answer}
                    onChange={(e) => setAnswer1(e.target.value)}
                    // size="small"
                  />
                </div>
              ) : props?.question?.quizType === "Address" ? (
                <div
                  style={{
                    width:
                      props?.fromDashboard == "dashboard-profile"
                        ? "100%"
                        : "50%",
                    margin: "5px",
                    marginTop: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label=" Search for the address"
                    fullWidth
                    value={
                      addressData?.address
                        ? addressData?.address + ","
                        : "" + addressData?.city
                        ? addressData?.city + ","
                        : "" + addressData?.country
                        ? addressData?.country + ","
                        : "" + addressData?.province
                        ? addressData?.province + ","
                        : "" + addressData?.postalCode
                    }
                    onClick={() => setShowChooseLocationModal(true)}
                    // size="small"
                  />
                </div>
              ) : null}

              {props.remainingQuestion !== "empty" ? (
                <div className="flex-container-horizontal earnBucks profile-flex">
                  <div
                    className="flex-container-left"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div style={{ margin: "10px 5px" }}>
                      <Button
                        color="primary"
                        size="medium"
                        variant={"outlined"}
                        onClick={() => handleAnswers(2)}
                      >
                        Don't Ask Again
                      </Button>
                    </div>

                    <div style={{ margin: "10px 5px" }}>
                      <Button
                        color="primary"
                        size="medium"
                        variant={"outlined"}
                        onClick={() => handleAnswers(3)}
                      >
                        Ask Me Later
                      </Button>
                    </div>
                  </div>
                  <div className="flex-container-horizontal">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 5px",
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={
                          props?.from == "map" && props?.selectedProject != ""
                            ? false
                            : props?.from == "map" &&
                              props?.selectedProject == ""
                            ? true
                            : answer1 === ""
                            ? true
                            : false
                        }
                        color="primary"
                        onClick={() => handleAnswers(1)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              <PromptModal
                isOpen={isSaveAlert}
                onClose={() => setIsSaveAlert(false)}
                title={`Save ${
                  props?.question &&
                  props?.question?.searchAnswerIn === "Products"
                    ? "Product"
                    : `${
                        props?.question &&
                        props?.question?.searchAnswerIn === "Roles"
                          ? "Role"
                          : "Trade"
                      }`
                }`}
                desc={`This is a new ${
                  props?.question &&
                  props?.question?.searchAnswerIn === "Products"
                } ? 'product' : ${
                  props?.question && props?.question?.searchAnswerIn === "Roles"
                } ? 'Role' : 'trade'!. Do you want to save it?`}
                blueButtonText="Ok"
                onBlueButtonClick={() => setIsSaveAlert(false)}
              />

              <PromptModal
                isOpen={isNewAlert}
                onClose={() => setIsNewAlert(false)}
                title={`New ${
                  props?.question &&
                  props?.question?.searchAnswerIn === "Companies"
                    ? "Company"
                    : "Site"
                }`}
                desc={`This ${
                  props?.question &&
                  props?.question?.searchAnswerIn === "Companies"
                    ? "company"
                    : "site"
                } is now added to your profile. You can add more information about this ${
                  props?.question &&
                  props?.question?.searchAnswerIn === "Companies"
                    ? "company"
                    : "site"
                } under your ${
                  props?.question &&
                  props?.question?.searchAnswerIn === "Companies"
                    ? "Experience section on the Profile page."
                    : "timeline from the Sites menu."
                }`}
                blueButtonText="Yes"
                redButtonText="No"
                onRedButtonClick={() => setIsNewAlert(false)}
              />
            </div>
          ) : (
            <p className="store-card-title" style={{ color: "black" }}>
              {props?.question}
            </p>
          )}
        </div>
      </div>
      {showChooseLocationModal && (
        <ChooseLocationModal
          open={showChooseLocationModal}
          setOpenChooseLocationModal={() => {
            setShowChooseLocationModal(false);
          }}
          setSelectedAddress={(address) => {
            setAddressData((pre) => ({ ...pre, ...address }));
            setAnswer1(address ?? "");
          }}
          addressData={addressData}
        />
      )}
    </>
  );
}

// selectProduct?.map((item, index) => (
//                             <Autocomplete
//                               freeSolo
//                               options={isSearching ? filterData : []}
//                               style={{ flex: 1, marginTop: "10px" }}
//                               renderInput={(params) => (
//                                 <TextField {...params} label={`Search for a ${props?.question?.response_EntityName == 'SiteCompanyRole' ? 'Company Role' : props?.question?.response_EntityName}`} variant={"outlined"} />
//                               )}
//                               onChange={(event, newValue) => onSelectchange(event, newValue, index)}
//                               onInputChange={(event, newInputValue) => onInputchange(event, newInputValue, index)}
//                               getOptionLabel={(option) => {
//                                 if (typeof option === 'string') {
//                                   return option;
//                                 }
//                                 if (option.inputValue) {
//                                   return option.inputValue;
//                                 }
//                                 return option.title;
//                               }}
//                               renderOption={(props, option) => <li key={option?.id} {...props}>{option.title}</li>}
//                               open={item?.flag}
//                             />
//                           ))
//                           }

//                           const onSelectchange = (event, newValue, index) => {
//                             let getData = [...selectProduct];
//                             getData[index].question = newValue;
//                             getData[index].flag = false;
//                             setAnswer1(newValue?.id);
//                             setMultipleProduct(getData);
//                           }

//                           const onInputchange = (event, newInputValue, index) => {
//                             let getData = [...selectProduct];
//                             getData[index].question = newInputValue;
//                             getData = getData.map((item, getIndex) => {
//                               if (getIndex === index) {
//                                 return {
//                                   ...item,
//                                   flag: true
//                                 }
//                               } else {
//                                 return {
//                                   ...item,
//                                   flag: false
//                                 }
//                               }
//                             })
//                             setMultipleProduct(getData);
//                             if (newInputValue !== '') {
//                               getFilterQuestionData(newInputValue);
//                             }

//                          props.selectData.map((item, index) => (
//                                                     <div className="input-container">
//                                                       <TextField
//                                                         variant="outlined"
//                                                         placeholder={`Search for a ${props?.question?.response_EntityName}`}
//                                                         // value={props?.question?.response_EntityName?.trim() == 'Site' ? props?.selectedSite?.name : props?.selectedProject?.projectName}
//                                                         value={props?.question?.response_EntityName6?.trim() == 'Site' ? item?.name : item?.projectName}
//                                                         onClick={props?.question?.response_EntityName?.trim() == 'Site' ? () => onChooseSitePressed(index) : () => onChooseProjectPressed(index)}
//                                                         fullWidth
//                                                       />
//                                                     </div>
//                                                   ))
//                          }
