import React, { useState, useEffect } from "react";
import { Modal, Button } from "@material-ui/core";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { getChatGroupDashboard } from "../../api/ChatApi";
import { BIBlue } from "../../assets/buildidColors";
import DefaultProfilePic from "../../assets/images/profile-picture-placeholder.png";
import { useHistory } from "react-router-dom";

function SendInChatModal(props) {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  let getUrl = window.location.pathname.split("/").pop();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getMembersList();
  }, []);

  const getMembersList = async () => {
    try {
      const chatGroupData = await getChatGroupDashboard(localStorage.userId);
      let getList = chatGroupData
        .filter(
          (item) =>
            item?.fromUserFirstName !== "Past" &&
            item?.fromUserLastName !== "Builder"
        )
        .map((item) => {
          return item;
        });

      let getChatGroupData = getList.sort((a, b) => {
        let dateA = new Date(a.dateSent),
          dateB = new Date(b.dateSent);
        return dateB - dateA;
      });

      getChatGroupData = getChatGroupData.filter(
        (item) => item.messageText !== ""
      );
      setMembers(getChatGroupData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error while getting the list of members:-", err);
    }
  };

  if (!props.isOpen) {
    return null;
  }

  const navigateToChat = (item) => {
    if (props?.from === "Site") {
      history.push({
        pathname: "/chat",
        state: {
          from: "Detail",
          chatGroupId: item?.chatGroupId,
          showInList: true,
          messageText: `${process.env.REACT_APP_BASE_URL}site/${getUrl}&name=${props.title}&id=${getUrl}&type=site&picture=${props.imageUrl}`,
        },
      });
    } else if (props?.from === "Project") {
      history.push({
        pathname: "/chat",
        state: {
          from: "Detail",
          chatGroupId: item?.chatGroupId,
          showInList: true,
          messageText: `${process.env.REACT_APP_BASE_URL}project/${getUrl}&name=${props.title}&id=${getUrl}&type=project&picture=${props.imageUrl}`,
        },
      });
    } else if (props?.from === "Company") {
      history.push({
        pathname: "/chat",
        state: {
          from: "Detail",
          chatGroupId: item?.chatGroupId,
          showInList: true,
          messageText: `${process.env.REACT_APP_BASE_URL}company/${getUrl}&name=${props.title}&id=${getUrl}&type=company&picture=`,
        },
      });
    } else if (props?.from === "Manufacturer") {
      history.push({
        pathname: "/chat",
        state: {
          from: "Detail",
          chatGroupId: item?.chatGroupId,
          showInList: true,
          messageText: `${process.env.REACT_APP_BASE_URL}manufacturer/${getUrl}&name=${props.title}&id=${getUrl}&type=manufacturer&picture=`,
        },
      });
    } else if (props?.from === "Product") {
      history.push({
        pathname: "/chat",
        state: {
          from: "Detail",
          chatGroupId: item?.chatGroupId,
          showInList: true,
          messageText: `${process.env.REACT_APP_BASE_URL}product/${getUrl}&name=${props.title}&id=${getUrl}&type=product&picture=`,
        },
      });
    } else if (props?.from === "Trade") {
      history.push({
        pathname: "/chat",
        state: {
          from: "Detail",
          chatGroupId: item?.chatGroupId,
          showInList: true,
          messageText: `${process.env.REACT_APP_BASE_URL}trade/${getUrl}&name=${props.title}&id=${getUrl}&type=trade&picture=`,
        },
      });
    }
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <div
        className="modal-center"
        style={{ width: "62%", marginLeft: "50px" }}
      >
        <div className="modal-card">
          <div className="modal-card-body">
            <div
              style={{
                paddingBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                color: BIBlue,
              }}
            >
              <div style={{ cursor: "pointer" }} onClick={props.onClose}>
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={props?.handleShowNewChatModal}
              >
                New Chat
              </div>
            </div>

            <Stack spacing={0} className="user-list">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 200,
                  }}
                >
                  <h3>Loading ...</h3>
                </div>
              ) : (
                <>
                  {members != 0 ? (
                    <>
                      {members
                        .sort(
                          (a, b) => new Date(b.dateSent) - new Date(a.dateSent)
                        )
                        .map((item, index) => {
                          return (
                            <div key={index.toString()}>
                              <ListItem
                                key={index.toString()}
                                disablePadding
                                className="list-item"
                                onClick={() => navigateToChat(item)}
                              >
                                <ListItemButton
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      {item?.nrOfUsersInGroup > 2 ? (
                                        <>
                                          <Avatar
                                            src={
                                              item?.groupUserPictureUrl1 ??
                                              DefaultProfilePic
                                            }
                                            style={{ zIndex: 1 }}
                                            sx={{ width: 30, height: 30 }}
                                          />
                                          <Avatar
                                            src={
                                              item?.groupUserPictureUrl2 ??
                                              DefaultProfilePic
                                            }
                                            style={{
                                              zIndex: 0,
                                              marginTop: "-10px",
                                              marginLeft: "-10px",
                                            }}
                                            sx={{ width: 30, height: 30 }}
                                          />
                                        </>
                                      ) : (
                                        <Avatar
                                          src={
                                            item?.groupUserPictureUrl1 ??
                                            DefaultProfilePic
                                          }
                                        />
                                      )}
                                    </Stack>
                                  </div>
                                  <ListItemText
                                    primary={
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          {item?.groupUserFirstName1.trim()}
                                          {item?.nrOfUsersInGroup == 2 &&
                                            ` ${item?.groupUserLastName1.trim()}`}
                                          {item?.nrOfUsersInGroup == 3 &&
                                            ` and ${item?.groupUserFirstName2.trim()}`}
                                          {item?.nrOfUsersInGroup > 3 &&
                                            `, ${item?.groupUserFirstName2.trim()} and ${
                                              item?.nrOfUsersInGroup - 3
                                            } others`}
                                        </div>
                                      </div>
                                    }
                                    secondary={item?.groupUserTradeName1}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 200,
                      }}
                    >
                      <h3>!!! NO DATA FOUND !!!</h3>
                    </div>
                  )}
                </>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SendInChatModal;
